<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="roleId ? roleTitle : ('setupCompetences.ADD_ROLE' | translate)"
            [description]="'setupCompetences.roles.SIDENAV_DESCR' | translate">
        </sidenav-informations>
    </div>


    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <div class="form-container">
                <ng-container *ngIf="isLoadingData">
                    <div class="loader spinner-big"></div>
                </ng-container>

                <ng-container *ngIf="!isLoadingData">
                    <!-- Titolo del ruolo  -->
                    <input-container [isObbligatory]="true" [isValued]="roleTitle && roleTitle.trim().length"
                        title="{{'setupCompetences.ROLE_TITLE' | translate}}" [isWithoutInfoIcon]="true">
                        <alloy-input [bindValue]="roleTitle" (onModelChanged)="titleChanged($event)"
                            placeholder="{{'setupCompetences.ROLE_TITLE_PLACEHOLDER' | translate}}">
                        </alloy-input>
                    </input-container>


                    <ng-container *ngFor="let object of behaviorOrCompetence;">
                        <ng-container *ngIf="object.list && object.list.length">
                            <div class="behavior-container">
                                <!-- Valore -->
                                <ng-container *ngIf="object.title">
                                    <div class="behavior-title">
                                        <p>{{ object.title + ' (' + object.list.length + ')'}}</p>
                                    </div>
                                </ng-container>

                                <ng-container *ngFor="let data of object.list; let last = last;">
                                    <div class="competence-row-container" [ngClass]="{'divisor': !last}">
                                        <div class="competence-data-wrapper">
                                            <ng-container *ngIf="isBehavior">
                                                <p class="title">{{ data.title }}</p>
                                                <p class="description">{{ data.description }}</p>
                                                <div class="dictionary-container">
                                                    <p class="label"
                                                        translate="setupCompetences.roles.ASSOCIATED_DICTIONARY">
                                                    </p>
                                                    <alloy-select (onSelectedItem)="changeDictionary($event, data)"
                                                        [selectedItem]="data.scale" [items]="dictionariesList"
                                                        [searchable]="true" bindLabel="title">
                                                    </alloy-select>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!isBehavior">
                                                <p class="title" translate="generic.TITLE"></p>
                                                <p class="description">{{ data.title }}</p>
                                            </ng-container>
                                        </div>
                                        <svg-icon src="assets/img/icons/trash-2.svg" [applyClass]="true"
                                            class="delete-icon" (click)="deleteCompetenceOrBehavior(data)">
                                        </svg-icon>
                                    </div>
                                </ng-container>

                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- aggiungi -->
                <div class="add-container">
                    <alloy-button
                        [label]="(isBehavior ? 'setupCompetences.ADD_BEHAVIOR' : 'setupCompetences.ADD_SKILL') | translate"
                        type="black-border" (onButtonClicked)="addNew()" [disabled]="isLoadingData" size="big">
                    </alloy-button>
                </div>
                <!-- Salva modifiche / cancella-->
                <div class="action-container">
                    <alloy-button [label]="'generic.CANCEL' | translate" type="success bordless"
                        (onButtonClicked)="onCancel()"></alloy-button>
                    <alloy-button [disabled]="false" [label]="'generic.SAVE_EDIT' | translate" type="success"
                        (onButtonClicked)="checkSave()">
                    </alloy-button>
                </div>
            </div>
        </div>
    </div>

</page-container>


<modal id="add-modal" (onClose)="closeModal()" [fullPage]="true" [confirmLabel]="'generic.ADD' | translate"
    (onConfirm)="closeModal(true)">
    <p class="modal-title center"
        [translate]="(isBehavior ? 'setupCompetences.modals.ADD_BEHAVIOR' : 'setupCompetences.modals.ADD_SKILL') | translate">
    </p>
    <div class="admin-table-container">

        <!-- Barra di ricerca -->
        <div class="search-filters-container">
            <div class="admin-search-container bottom-filters">
                <div class="search-wrapper">
                    <alloy-input (onIconClicked)="getModalData()" typeInput="text" [bindValue]="modalSearchedText"
                        (onModelChanged)="modalSearchChanged($event)" iconRight="assets/img/icons/search.svg"
                        [placeholder]="'setupCompetences.SEARCH_BY_NAME' | translate" [isSearchBar]="true">
                    </alloy-input>
                </div>
            </div>
        </div>


        <ng-container *ngIf="isLoadingModalData">
            <div class="spinner-container">
                <div class="loader spinner-big"></div>
            </div>
        </ng-container>

        <ng-container *ngIf="!isLoadingModalData">
            <ng-template #noData>
                <div class="no-result-container">
                    <p translate="generic.NO_RESULTS"></p>
                </div>
            </ng-template>
            <ng-container *ngIf="modalData.list && modalData.list.length; else noData">
                <div class="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <alloy-check (onCheck)="selectAll()"></alloy-check>
                                </th>
                                <th translate="{{ ('adminTable.TITLE' | translate)}}"></th>
                                <ng-container *ngIf="isBehavior">
                                    <th translate="{{ ('adminTable.VALUES' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.DESCRIPTION' | translate)}}"></th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of modalData.list 
                            | slice:0:modalData.numRecords 
                            | paginate: { id: 'dataList',
                                    itemsPerPage: modalData.numRecords,
                                    currentPage: modalData.page,
                                    totalItems: modalData.counter 
                                }">
                                <td>
                                    <alloy-check id="data.id" [checked]="data.isChecked" (click)="selectData(data)">
                                    </alloy-check>
                                </td>
                                <td>{{ data.title }}</td>
                                <ng-container *ngIf="isBehavior">
                                    <td>{{ data.value }}</td>
                                    <td>{{ (data.hasDescription ? 'generic.YES' : 'generic.NO') | translate}}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bottom-actions">
                    <table-pagination id="dataList" (pageChanged)="modalPageChanged($event)" [noPadding]="true">
                    </table-pagination>
                    <div class="per-page-selector">
                        <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                        <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                            [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                            bindLabel="id" [tablePagination]="true">
                        </alloy-select>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</modal>

<!-- Modale avviso salvataggio -->
<modal id="warning-no-data" (onClose)="closeWarningModal()" (onConfirm)="closeWarningModal()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content modalId="war001" [title]="'setupCompetences.modals.SAVE_EDIT' | translate"
        [subtitle]="'setupCompetences.modals.NO_SAVE' | translate"
        [text]="(isBehavior ? 'setupCompetences.modals.CANT_SAVE_NO_BEHAVIOR' : 'setupCompetences.modals.CANT_SAVE_NO_COMPETENCE') | translate">
    </modal-text-content>
</modal>

<!-- Modale avviso salvataggio -->
<modal id="confirm-save" (onClose)="closeConfirmModal()" (onConfirm)="closeConfirmModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" (onCancel)="closeConfirmModal()"
    [cancelLabel]="'generic.CANCEL' | translate">
    <modal-text-content modalId="cls001" [title]="'setupCompetences.modals.SAVE_EDIT' | translate"
        [text]="'setupCompetences.modals.CONFIRM_SAVE' | translate"></modal-text-content>
</modal>