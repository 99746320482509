<div class="modal" [ngClass]="{'message-modal-index': isMessageModal}">
  <div #modalEl class="modal-body" [ngClass]="{'full-page': fullPage}">
    <div class="close-row">
      <div (click)="emitOnClose()" class="close-container">
        <svg-icon class="small-size" src="/assets/img/icons/x.svg" [applyClass]="true"></svg-icon>
        <p translate="generic.CLOSE"></p>
      </div>
    </div>
    <ng-content></ng-content>
    <div class="action-container"
      [ngClass]="{'single-button': (confirmLabel && !cancelLabel) || (!confirmLabel && cancelLabel) || (confirmLabel && noConfirmButton)}">
      <alloy-button *ngIf="cancelLabel" [label]="cancelLabel" type="black-border"
        (onButtonClicked)="emitOnCancelClicked()"></alloy-button>
      <alloy-button id="confirmBtn" [disabled]="isDisabled" *ngIf="confirmLabel && !noConfirmButton"
        [label]="confirmLabel" type="success" (onButtonClicked)="emitOnConfirmClicked()">
      </alloy-button>
    </div>
  </div>
</div>