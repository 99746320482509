<div class="page-content-wrapper">

  <div id="snackbar">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingYearList && !isLoadingPersonDetails && personDetails">
    <header-dossier (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [user]="personDetails"
      [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
      (onNewInterview)="createPrivateNote()">
    </header-dossier>

    <div class="main-content-wrapper">
      <!-- dati semestre H1 H2-->
      <ng-container *ngIf="!isNoteSection">
        <!-- Contenitore dati caricati -->
        <ng-container *ngIf="!isLoadingTab">
          <child-content>
            <stepper id="stepper" [statuses]="stepList" [dots]="5" [currentStatus]="currentStep"
              (stepClicked)="onStepClicked($event)"></stepper>

            <!-- 1 - Valutazione obiettivi di sviluppo -->
            <ng-container *ngIf="currentStep.id == 'objectiveEvaluation'">
              <!-- Placeholder caricamento-->
              <ng-container *ngIf="isLoadingToEvaluateGoals">
                <div class="page-header">
                  <div class="loader spinner-medium"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isLoadingToEvaluateGoals">
                <!-- Se non ho obiettivi mostro placeholder -->
                <ng-container *ngIf="!toEvaluateList || !toEvaluateList.length">
                  <no-data [title]="('generic.NO_OBJECTIVE_TO_EVALUATE' | translate)"
                    [description]="('generic.NO_OBJECTIVE_TO_EVALUATE_DESCR' | translate)" [hideIcon]="true">
                  </no-data>
                </ng-container>
                <!-- se ho obiettivi -->
                <ng-container *ngIf="toEvaluateList && toEvaluateList.length">
                  <div class="page-header">
                    <div id="firstStepPageHeader">
                      <div class="title-container">
                        <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                        <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                          (click)="openHelpModal(currentStep.id)">
                        </svg-icon>
                        <svg-icon *ngIf="!isLoadingToEvaluateGoals" src="/assets/img/icons/flag.svg" class="title-icon"
                          [applyClass]="true" (click)="openPersonalObjectiveEvaluationTutorial()">
                        </svg-icon>
                      </div>
                      <p class="description" [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR_SELF' | translate"></p>
                    </div>
                  </div>

                  <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                  <ng-container *ngFor="let objective of toEvaluateList; let toEvaluateItemIndex = index;">
                    <text-goal [title]="objective.text" [evaluation]="objective.evaluation" [isEvaluation]="true"
                      (evaluationChanged)="onEvaluationChanged($event, objective)" [showMenu]="false"
                      [disabled]="isScheduleShared || isFinalStepCompleted"
                      [id]="'toEvaluateItemIndex' + toEvaluateItemIndex">
                    </text-goal>
                  </ng-container>

                  <div id="saveGoalToEvaluateBtn" class="button-container"
                    *ngIf="!isScheduleShared && !isFinalStepCompleted">
                    <alloy-button type="success default" size="large" label="{{'generic.SAVE' | translate}}"
                      (onButtonClicked)="saveObjectiveEvaluation()"
                      [disabled]="isLoadingToEvaluateGoals || !anyEvaluationChangesToSave()"></alloy-button>
                  </div>
                </ng-container>
              </ng-container>

            </ng-container>

            <!-- 2 - Valutazione dei comportamenti organizzativi -->
            <ng-container *ngIf="currentStep.id == 'behaviourEvaluation'">
              <div class="page-header" id="secondStepPageHeader">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                  <svg-icon *ngIf="!isLoadingBehaviours" src="/assets/img/icons/flag.svg" class="title-icon"
                    [applyClass]="true" (click)="openOrganizationalBehaviorsTutorial()">
                  </svg-icon>
                </div>
                <p class="description" [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR' | translate"></p>
              </div>
              <ng-container *ngIf="!isLoadingBehaviours">
                <!-- Esempio competenze -->
                <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                  <competence-evaluation [competenceList]="competence" [id]="'competenceIndex' + competenceIndex"
                    (infoClicked)="openCompetenceDictionaryModal($event)"
                    (ratingChanged)="behaviourRatingChanged($event)" [ratingScale]="competence.ratingScale"
                    [disabled]="isScheduleShared || isFinalStepCompleted">
                  </competence-evaluation>
                </ng-container>

                <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                  [isValued]="manageBehaviorComments && manageBehaviorComments.trim().length" [isObbligatory]="false"
                  title="{{'generic.NOTES_COMMENTS' | translate}}" [moreActions]="textAreaActions"
                  (emitAction)="manageBehaviorEvaluationAction($event, manageBehaviorComments, false)"
                  [disabled]="isScheduleShared || isFinalStepCompleted" id="competenceEvaluationNotes">
                  <alloy-text-area [bindValue]="manageBehaviorComments" [maxlength]="300"
                    (onModelChanged)="onManageBehaviorCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}"
                    [readonly]="isScheduleShared || isFinalStepCompleted">
                  </alloy-text-area>
                </input-container>
              </ng-container>

              <ng-container *ngIf="isLoadingBehaviours">
                <div class="loader spinner-medium"></div>
              </ng-container>

            </ng-container>

            <!-- 3 - Valutazione competenze tecniche -->
            <ng-container *ngIf="currentStep.id == 'techEvaluation'">
              <div class="page-header" id="thirdStepPageHeader">
                <div class="title-container">
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="title"
                    translate="stepper.TECH_SKILL_EVALUATION"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="title" translate="stepper.TECH_OBJECTIVES"></p>

                  <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                    (click)="openHelpModal(currentStep.id)">
                  </svg-icon>
                  <svg-icon *ngIf="!isLoadingTechSkills" src="/assets/img/icons/flag.svg" class="title-icon"
                    [applyClass]="true" (click)="openTechSkillEvaluationTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR' | translate"></p>
                <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR' | translate"></p>
              </div>

              <!-- techSkill -->
              <ng-container *ngIf="!isLoadingTechSkills">
                <competence-evaluation [competenceList]="techSkills" (ratingChanged)="techSkillRatingChanged($event)"
                  [ratingScale]="techSkillRatingScale" [disabled]="isScheduleShared || isFinalStepCompleted">
                </competence-evaluation>

                <input-container id="techEvaluationNotes" [numberCharsTextArea]="300" [isFromTextArea]="true"
                  [isWithoutInfoIcon]="true" [isValued]="techSkillComment && techSkillComment.trim().length"
                  [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                  [moreActions]="textAreaActions"
                  (emitAction)="manageCompetenceEvaluationAction($event, techSkillComment, false)"
                  [disabled]="isScheduleShared || isFinalStepCompleted">
                  <alloy-text-area [bindValue]="techSkillComment" [maxlength]="300"
                    (onModelChanged)="onTechSkillCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}"
                    [readonly]="isScheduleShared || isFinalStepCompleted">
                  </alloy-text-area>
                </input-container>
              </ng-container>
              <!-- loader competenze tecniche -->
              <ng-container *ngIf="isLoadingTechSkills">
                <div class="loader spinner-medium"></div>
              </ng-container>
            </ng-container>

            <!-- 4 - Definisci obiettivi -->
            <ng-container *ngIf="currentStep.id == 'defineObjectives'">
              <div class="page-header white-bg">
                <div class="title-container" id="fourthStepPageHeader">
                  <p class="title" translate="stepper.DEFINE_NEW_OBJECTIVES"></p>
                  <svg-icon *ngIf="!isLoadingDefinedObjectives && !isLoadingUserGoalToDefine"
                    src="/assets/img/icons/flag.svg" class="title-icon" [applyClass]="true"
                    (click)="openSetGoalsTutorial()">
                  </svg-icon>
                </div>
                <p class="description" [innerHTML]="'stepper.DEFINE_OBJECTIVES_DESCR' | translate"></p>

                <!-- Lista obiettivi -->
                <ng-container *ngIf="!isLoadingDefinedObjectives">
                  <ng-container *ngFor="let objective of newObjectiveList; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal [title]="objective.title" [showMenu]="!isScheduleShared && !isFinalStepCompleted"
                        [canEdit]="!isScheduleShared && !isFinalStepCompleted"
                        (menuOptionClicked)="onMenuOptionClicked($event, objective)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isCreateObjective]="true" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate) + ' ' + (i + 1)}}"
                        (deleteObjective)="onMenuOptionClicked('delete', objective)" [disabled]="isScheduleShared">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'defineObjectives.INSERT_OBJECTIVE' | translate}}"
                          [readonly]="isScheduleShared">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <!-- Aggiungi obiettivo -->
                  <ng-container *ngIf="!isScheduleShared && !isFinalStepCompleted">
                    <alloy-button id="addGoalBtn" type="add-objective link" size="large"
                      label="{{'defineObjectives.ADD_OBJECTIVE' | translate}}" iconRight="assets/img/icons/plus.svg"
                      (onButtonClicked)="addNewObjective()"></alloy-button>
                  </ng-container>

                </ng-container>

                <!-- Loader -->
                <ng-container *ngIf="isLoadingDefinedObjectives">
                  <div class="loader spinner-small"></div>
                </ng-container>
              </div>

              <!-- Salva nuovi obiettivi da definire -->
              <ng-container *ngIf="!isScheduleShared && !isFinalStepCompleted">
                <div class="button-container">
                  <alloy-button id="saveGoalBtn" type="success default" size="large"
                    label="{{'generic.SAVE' | translate}}" (onButtonClicked)="saveDefinedObjective()"
                    [disabled]="isLoadingDefinedObjectives || !canSaveDefinedObjectives()"></alloy-button>
                </div>
              </ng-container>
            </ng-container>

            <!-- 5 - Valutazione finale -->
            <ng-container *ngIf="currentStep.id == 'finalEvaluation'">
              <ng-container *ngIf="!isLoadingFinalEvaluationData">

                <ng-container *ngIf="checkNewObjectives()">
                  <div class="notice-new-objective-container">
                    <div class="notice-container">
                      <div class="dot"></div>
                      <p>{{ newObjectiveMessage }}</p>
                    </div>
                    <p class="go-to-link" translate="finalEvaluation.NOTICE_ACTION" (click)="scrollToNewObjective()">
                    </p>
                  </div>
                </ng-container>

                <!-- con la valutazione e con la modale con le informazioni -->
                <ng-container *ngIf="finalEvaluation.pasPerformer.value">
                  <performer-evaluator [performerValue]="finalEvaluation.pasPerformer.value"
                    [enablePerformerInfo]="true" (openPerformerModal)="openPerformerModal($event)"
                    [sentToHr]="isScheduleShared" class="white-box-with-shadow"
                    [hrRemarks]="finalEvaluation.adminComment" [enableHrRemarks]="isPostCalibration"
                    [isHrRemarksVisible]="true">
                  </performer-evaluator>
                </ng-container>
                <!-- valutazione ancora da impostare e con la modale con le informazioni -->
                <ng-container *ngIf="!finalEvaluation.pasPerformer.value">
                  <performer-evaluator [hasToSetVal]="true" [enablePerformerInfo]="true"
                    (openPerformerModal)="openPerformerModal($event)" class="white-box-with-shadow">
                  </performer-evaluator>
                </ng-container>
              </ng-container>

              <!-- Recap fasi precedenti -->
              <!-- Placeholder obiettivi valutati -->
              <ng-container *ngIf="isLoadingFinalEvaluationData">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                  </div>
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </div>
              </ng-container>

              <!-- Recap valutazione obiettivi -->
              <ng-container *ngIf="!isLoadingFinalEvaluationData">
                <ng-container *ngIf="finalEvaluation.evaluatedData && finalEvaluation.evaluatedData.length">
                  <!-- Recap valutazione obiettivi di sviluppo -->
                  <div class="page-header white-bg">
                    <div class="title-container">
                      <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                    </div>

                    <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                    <div class="objective-tag-container">
                      <!-- Placeholder nessun obiettivo -->
                      <ng-container *ngIf="!selectedObjective || finalEvaluation.emptyEvaluatedObjective">
                        <p class="placeholder margin" translate="defineObjectives.OBJECTIVE_NOT_EVALUATED"></p>
                      </ng-container>

                      <!-- recap obiettivi -->
                      <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                        <ng-container *ngFor="let objective of finalEvaluation.evaluatedData; let i = index">
                          <div class="objective-tag" (click)="selectObjectiveFinalEvaluation(objective)"
                            [ngClass]="{ 'selected': selectedObjective && selectedObjective.goalId && selectedObjective.goalId == objective.goalId }">
                            <p>{{ ('finalEvaluation.OBJECTIVE' | translate) + ' ' + ( i + 1 )}}</p>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>

                    <!-- Recap obiettivo selezionato -->
                    <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                      <div class="objective-evaluation-content">
                        <!-- Obiettivo -->
                        <text-goal [title]="selectedObjective.text" [showMenu]="false"></text-goal>
                        <!-- Valutazione obiettivo people appraisal -->
                        <ng-container *ngIf="loggedUser && !selectedObjective.editObjectiveEvaluation">
                          <evaluation-rating [user]="loggedUser"
                            [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                            [evaluationText]="selectedObjective.evaluation || ('generic.EVALUATION_INCOMPLETE' | translate)"
                            [canEdit]="!isFinalStepCompleted" [isEditDisabled]="isScheduleShared"
                            (onEdit)="editCurrentObjective(selectedObjective)">
                          </evaluation-rating>
                        </ng-container>

                        <ng-container *ngIf="selectedObjective.editObjectiveEvaluation">
                          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                            [isWithoutInfoIcon]="true"
                            [isValued]="selectedObjective.evaluation && selectedObjective.evaluation.trim().length"
                            [isObbligatory]="false" title="{{'textGoal.INSERT_LABEL' | translate}}"
                            [moreActions]="textAreaActions" (emitAction)="manageObjectiveEvaluationAction($event)">
                            <alloy-text-area [bindValue]="selectedObjective.evaluation" [maxlength]="300"
                              (onModelChanged)="onObjectiveEvaluationEdited($event)"
                              placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                            </alloy-text-area>
                          </input-container>
                        </ng-container>

                        <!-- Valutazione obiettivo self-assessment-->
                        <ng-container
                          *ngIf="(isPostCalibration || isFinalStepCompleted) && selectedObjective.selfEvaluation">
                          <evaluation-rating [user]="personDetails"
                            [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                            [evaluationText]="selectedObjective.selfEvaluation">
                          </evaluation-rating>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Recap valutazione comportamenti -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                </div>
                <p class="description" [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR_FE' | translate"></p>

                <ng-container *ngIf="!isLoadingFinalEvaluationData">
                  <!-- placeholder valutazione incompleta -->
                  <ng-container *ngIf="!finalEvaluation.behaviorData || !finalEvaluation.behaviorData.rating ||
                    !finalEvaluation.behaviorData.ratingValue">
                    <star-rating [disabled]="true" [forceRating]="0"
                      [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                  </ng-container>

                  <ng-container
                    *ngIf="finalEvaluation.behaviorData && finalEvaluation.behaviorData.rating && finalEvaluation.behaviorData.ratingValue">
                    <ng-container *ngIf="editBehaviorComment">
                      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="finalEvaluation.behaviorData.comment && finalEvaluation.behaviorData.comment.trim().length"
                        [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                        [moreActions]="textAreaActions"
                        (emitAction)="manageBehaviorEvaluationAction($event, finalEvaluation.behaviorData.comment, true)">
                        <alloy-text-area [bindValue]="finalEvaluation.behaviorData.comment" [maxlength]="300"
                          (onModelChanged)="onBehaviorCommentEdited($event)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>

                    <!-- Rating comportamenti people appraisal -->
                    <ng-container *ngIf="loggedUser && !editBehaviorComment">
                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                        [evaluationText]="finalEvaluation.behaviorData.comment"
                        [rating]="finalEvaluation.behaviorData.rating"
                        [ratingValue]="finalEvaluation.behaviorData.ratingValue" [canEdit]="!isFinalStepCompleted"
                        [isEditDisabled]="isScheduleShared || isFinalStepCompleted" (onEdit)="onEditBehaviourComment()">
                      </evaluation-rating>
                    </ng-container>

                    <!-- Rating comportamenti self-assessment -->
                    <ng-container
                      *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.behaviorData.selfRating && finalEvaluation.behaviorData.selfRatingValue">
                      <evaluation-rating [user]="personDetails"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                        [evaluationText]="finalEvaluation.behaviorData.selfComment"
                        [rating]="finalEvaluation.behaviorData.selfRating"
                        [ratingValue]="finalEvaluation.behaviorData.selfRatingValue" [isSelf]="true">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </ng-container>
              </div>

              <!-- Recap valutazione competenze tecniche -->
              <ng-container *ngIf="!isManagement">
                <!-- Se non è un utente del Management-->
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="title"
                      translate="stepper.TECH_SKILL_EVALUATION"></p>
                    <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="title" translate="stepper.TECH_OBJECTIVES">
                    </p>
                  </div>
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="description"
                    [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR_FE' | translate"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="description"
                    [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR_FE' | translate"></p>

                  <ng-container *ngIf="!isLoadingFinalEvaluationData">
                    <!-- placeholder valutazione incompleta -->
                    <ng-container
                      *ngIf="!finalEvaluation.competenceData || !finalEvaluation.competenceData.rating || !finalEvaluation.competenceData.ratingValue">
                      <star-rating [disabled]="true" [forceRating]="0"
                        [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                    </ng-container>

                    <ng-container
                      *ngIf="finalEvaluation.competenceData && finalEvaluation.competenceData.rating && finalEvaluation.competenceData.ratingValue">

                      <!-- Rating competenze tecniche people appraisal -->
                      <ng-container *ngIf="loggedUser && !editCompetenceComment">
                        <evaluation-rating [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                          [evaluationText]="finalEvaluation.competenceData.comment"
                          [rating]="finalEvaluation.competenceData.rating"
                          [ratingValue]="finalEvaluation.competenceData.ratingValue" [canEdit]="!isFinalStepCompleted"
                          [isEditDisabled]="isScheduleShared || isFinalStepCompleted"
                          (onEdit)="onEditCompetenceComment($event)">
                        </evaluation-rating>
                      </ng-container>

                      <!-- Edit commento competenze -->
                      <ng-container *ngIf="editCompetenceComment">
                        <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                          [isValued]="finalEvaluation.competenceData.comment && finalEvaluation.competenceData.comment.trim().length"
                          [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                          [moreActions]="textAreaActions"
                          (emitAction)="manageCompetenceEvaluationAction($event, finalEvaluation.competenceData.comment, true)">
                          <alloy-text-area [bindValue]="finalEvaluation.competenceData.comment" [maxlength]="300"
                            (onModelChanged)="onCompetenceCommentEdited($event)"
                            placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                          </alloy-text-area>
                        </input-container>
                      </ng-container>

                      <!-- Rating competenze tecniche self-assessment -->
                      <ng-container
                        *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.competenceData.selfRating && finalEvaluation.competenceData.selfRatingValue">
                        <evaluation-rating [user]="personDetails"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                          [evaluationText]="finalEvaluation.competenceData.selfComment"
                          [ratingValue]="finalEvaluation.competenceData.selfRatingValue"
                          [rating]="finalEvaluation.competenceData.selfRating" [isSelf]="true">
                        </evaluation-rating>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="isLoadingFinalEvaluationData">
                    <div class="objective-tag-container">
                      <div class="loader spinner-medium"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>


              <!-- Recap obiettivi definiti -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title"> {{ ('finalEvaluation.OBJECTIVE_DEVELOPMENT' | translate) +
                    ('headerDossier.CARD_MIN' |
                    translate) + getYearName()}}</p>
                </div>
                <p class="description" [innerHTML]="recapDevelopmentDescription"></p>

                <!-- Lista obiettivi per il semestre successivo -->
                <ng-container *ngIf="!isLoadingFinalEvaluationData">

                  <ng-container *ngFor="let objective of finalEvaluation.definedObjectives; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal [title]="objective.title" [showMenu]="!isScheduleShared && !isFinalStepCompleted"
                        [canEdit]="!isScheduleShared && !isFinalStepCompleted"
                        (menuOptionClicked)="onEditMenuOptionClicked($event, objective)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate) + ' ' + (i + 1)}}"
                        [moreActions]="textAreaActions" (emitAction)="manageDefineObjectiveActions($event, objective)">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!finalEvaluation.definedObjectives || !finalEvaluation.definedObjectives.length">
                    <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE"></p>
                  </ng-container>
                </ng-container>

                <!-- Obiettivi definiti dall'utente -->
                <ng-container
                  *ngIf="isPostCalibration && finalEvaluation.selfDefinedObjectives && finalEvaluation.selfDefinedObjectives.length">

                  <div #newObjectiveContainer style="margin-top: 55px;" class="description-container">
                    <p class="description" [innerHTML]="recapSubordinateDevelopmentDescription"></p>
                    <p class="text underline" translate="defineObjectives.NOTICE"></p>
                  </div>

                  <ng-container *ngFor="let objective of finalEvaluation.selfDefinedObjectives; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal [title]="objective.title" [showMenu]="!isScheduleShared && !isFinalStepCompleted"
                        [canEdit]="!isScheduleShared && !isFinalStepCompleted"
                        [isToApprove]="objective.goalToBeApproved"
                        (menuOptionClicked)="onSubordinateEditMenuOptionClicked($event, objective)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate) + ' ' + (i + 1)}}"
                        [moreActions]="textAreaActions"
                        (emitAction)="manageSubordinateDefineObjectiveActions($event, objective)">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="!finalEvaluation.selfDefinedObjectives || !finalEvaluation.selfDefinedObjectives.length">
                    <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE"></p>
                  </ng-container>

                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>


              <!-- Recap valutazione finale -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                </div>

                <ng-container *ngIf="!isLoadingFinalEvaluationData">
                  <ng-container
                    *ngIf="(!isScheduleShared && !isPostCalibration && !isFinalStepCompleted) || editFinalEvaluationComment">
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                      [isValued]="finalEvaluation.comment && finalEvaluation.comment.trim().length"
                      [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                      [moreActions]="textAreaActions" (emitAction)="manageFinalEvaluationAction($event)">
                      <alloy-text-area [bindValue]="finalEvaluation.comment" [maxlength]="300"
                        (onModelChanged)="onFinalEvaluationCommentChanged($event)"
                        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                      </alloy-text-area>
                    </input-container>
                  </ng-container>


                  <ng-container *ngIf="isScheduleShared || isPostCalibration || isFinalStepCompleted">
                    <!-- Rating competenze tecniche people appraisal -->
                    <ng-container *ngIf="loggedUser && !editFinalEvaluationComment">
                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + loggedUser.forename"
                        [evaluationText]="finalEvaluation.comment" [canEdit]="!isFinalStepCompleted"
                        [isEditDisabled]="isScheduleShared || isFinalStepCompleted"
                        (onEdit)="onEditFinalEvaluationComment()">
                      </evaluation-rating>
                    </ng-container>

                    <!-- Rating competenze tecniche self-assessment -->
                    <ng-container *ngIf="finalEvaluation.selfComment">
                      <evaluation-rating [user]="personDetails"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + personDetails.forename"
                        [evaluationText]="finalEvaluation.selfComment" [isSelf]="true">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>

              <!-- Proponi valutazione -->
              <ng-container *ngIf="!isScheduleShared && !isPostCalibration && !isFinalStepCompleted">
                <div class="button-container">
                  <alloy-button type="success default" size="large" label="{{'finalEvaluation.SEND_EVAL' | translate}}"
                    [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()"
                    (onButtonClicked)="openSendFinalEvaluation()">
                  </alloy-button>
                </div>
              </ng-container>

              <!-- Condivisione finale -->
              <ng-container *ngIf="isPostCalibration && !isFinalStepCompleted">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title"> {{ 'finalEvaluation.FINAL_SHARE' | translate}}</p>
                  </div>
                  <p class="description" [innerHTML]="finalShareDescripton"></p>

                  <!-- Checkbox colloquio di feedback-->
                  <input-container [isWithoutInfoIcon]="true" [isValued]="feedbackCheck" [isObbligatory]="false"
                    [title]="'finalEvaluation.CONFIRM_FEEDBACK' | translate"
                    [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()">
                    <alloy-check [label]="'finalEvaluation.CONFIRM_FEEDBACK_LABEL' | translate"
                      [checked]="feedbackCheck" (onCheck)="onFeedbackCheck()">
                    </alloy-check>
                  </input-container>


                  <div class="button-container">
                    <alloy-button type="success default" size="large"
                      label="{{'finalEvaluation.CONFIRM_SHARE' | translate}}" (onButtonClicked)="openFinalShareModal()">
                    </alloy-button>
                  </div>
                </div>
              </ng-container>
            </ng-container>

          </child-content>

        </ng-container>
        <!-- Caricamento tab child content -->
        <ng-container *ngIf="isLoadingTab">
          <div class="loader spinner-big"></div>
        </ng-container>

      </ng-container>

      <!-- I miei appunti -->
      <ng-container *ngIf="isNoteSection">
        <child-content>
          <div class="page-header">
            <ng-container *ngIf="privateNotesList && privateNotesList.length">
              <div class="title-container">
                <p class="title" translate="headerDossier.MY_NOTES"></p>
                <!--<svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                (click)="openHelpModal('notes')">
              </svg-icon>-->
              </div>
              <p class="description" [innerHTML]="'headerDossier.MY_NOTES_DESCR' | translate"></p>
            </ng-container>

            <ng-container *ngIf="!isLoadingPrivateNotes">
              <div class="card-list-container">
                <!-- Lista appunti privati -->
                <ng-container *ngIf="loggedUser && privateNotesList && privateNotesList.length">
                  <card-notes *ngFor="let note of privateNotesList" [note]="note" [loggedUser]="loggedUser"
                    (onDelete)="openDeleteNoteModal($event)" (onEdit)="goToEditPrivateNote($event)"></card-notes>
                </ng-container>

                <!-- Placeholder se non ho appunti privati -->
                <ng-container *ngIf="!privateNotesList || !privateNotesList.length">
                  <no-data [title]="('privateNotes.TITLE' | translate)"
                    [description]="('privateNotes.PLACEHOLDER' | translate)" [hideIcon]="true">
                  </no-data>
                </ng-container>
              </div>
              <!-- Pulsanti -->
              <div class="button-container development">
                <alloy-button type="success" [label]="('privateNotes.INSERT_NOTE' | translate)"
                  (onButtonClicked)="createPrivateNote()"></alloy-button>
              </div>
            </ng-container>

            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPrivateNotes">
              <div class="loader spinner-medium"></div>
            </ng-container>

          </div>
        </child-content>
      </ng-container>


    </div>
  </ng-container>
  <!-- Loader caricamento dati -->
  <ng-container *ngIf="isLoadingPersonDetails || isLoadingYearList">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale di aiuto per le competenze -->
<modal id="competence-dictionary" (onClose)="closeCompetenceDictionaryModal()"
  (onConfirm)="closeCompetenceDictionaryModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <p class="modal-title black center" *ngIf="competenceForModal">
    {{ (competenceForModal.parentBehavior ? 'manageBehaviour.DICTIONARY_TITLE' : 'manageBehaviour.COMPETENCE_TITLE')
    |translate}}
  </p>
  <ng-container *ngIf="competenceForModal">
    <div class="competence-dictionary-container">
      <ng-container *ngIf="competenceForModal.parentBehavior">
        <div class="competence-description">
          <p class="title">{{ competenceForModal.parentBehavior.text}}</p>
          <p class="subtitle">{{ competenceForModal.title}}</p>
          <p class="descr">{{ competenceForModal.text }}</p>
        </div>
      </ng-container>
      <div class="competence-ratings">
        <ng-container *ngFor="let rating of competenceForModal.evaluationScale">
          <div class="rating-section">
            <star-rating [forceRating]="rating.scale"></star-rating>
            <p class="descr"> {{ rating.text }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</modal>

<!-- Conferma eliminazione obiettivo -->
<modal id="confirm-delete-saved-objective" (onClose)="closeDeleteSavedObjective()"
  (onConfirm)="closeDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale che mostra le informazioni del Performer -->
<modal id="performerEvaluatorInfoModal" (onClose)="closePerformerModal()" [confirmLabel]="('generic.CLOSE' | translate)"
  (onConfirm)="closePerformerModal()">
  <performer-evaluator-info-modal-content [selectedInfoTabPerformerKey]="selectedInfoTabPerformerKey">
  </performer-evaluator-info-modal-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
  (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
    [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveObjectivesModal(false,true)" (onClose)="closeSaveObjectivesModal(true, true)"
  (onConfirm)="closeSaveObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi -->
<modal id="save-defined-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveDefinedObjectivesModal(false,true)" (onClose)="closeSaveDefinedObjectivesModal(true, true)"
  (onConfirm)="closeSaveDefinedObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Conferma eliminazione obiettivo -->
<modal id="confirm-delete-subordinate-saved-objective" (onClose)="closeSubordinateDeleteSavedObjective()"
  (onConfirm)="closeSubordinateDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale conferma invio HR -->
<modal id="send-hr-schedule" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeSendFinalEvaluation()"
  (onClose)="closeSendFinalEvaluation()" (onConfirm)="closeSendFinalEvaluation(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="oe001" [title]="'finalEvaluation.SHARE_SCHEDULE' | translate"
    [text]="'finalEvaluation.SHARE_SCHEDULE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale conferma invio HR -->
<modal id="final-share-modal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeFinalShareModal()"
  (onClose)="closeFinalShareModal()" (onConfirm)="closeFinalShareModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="fs001" [title]="'finalEvaluation.CONFIRM_SHARE_TITLE' | translate"
    [subtitle]="'finalEvaluation.CONFIRM_SHARE_SUBTITLE' | translate" [text]="finalShareModalDescription">
  </modal-text-content>
</modal>

<!-- Modale blocco condivisione finale -->
<modal id="cannot-share-modal" [confirmLabel]="('generic.CLOSE' | translate)" (onConfirm)="closeCannotShareModal()"
  (onClose)="closeCannotShareModal()">
  <modal-text-content modalId="fs002" [title]="'finalEvaluation.CANNOT_SHARE_TITLE' | translate"
    [text]="'finalEvaluation.CANNOT_SHARE_DESCR' | translate">
  </modal-text-content>
</modal>