import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GetSubordinatesWithPhaseStatusesForManagerResponse, JwtPayload } from '../../../commonclasses';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from "../../ngrx/app.reducers";
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';
import * as moment from 'moment';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as AuthActions from "../../auth/ngrx/auth.actions";

@Component({
  selector: 'app-selfAssessment-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class SelfAssessmentHomeComponent implements OnInit, AfterViewInit, OnDestroy {
  runningYear$: Subscription;
  runningYear: any;
  subordinatesWithPhaseStatuses$: Subscription | null = null;
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponse[] = [];
  loggedUser: any;
  combinedSelected$: Subscription;
  phaseName: string = "";
  bannerImage: string = '/assets/img/head/bg-head-demo.png';
  updateUserAck$: any;
  userAcknowledges: any;

  feedbackDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  idpDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  applauseDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  requestDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  interviewDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  isLoadingPeopleAppraisalInfo: boolean = false;
  isChief: boolean = false;
  userAuths: any;
  isOperation: boolean = false;

  intro!: GuidedTour;
  steps?: TourStep[];
  isLoadingCurrentYear: boolean = true;
  getRunningPhase$: Subscription = new Subscription();
  runningPhaseDate: any;

  isUpdatingAcks: boolean = false;
  isImpersonate: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private selfAssessmentService: SelfAssessmentService,
    private analyticsService: AnalyticsService,
    private guidedTour: GuidedTourService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Home - Self Assessment);
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear && this.runningYear.yearCode) {
        this.phaseName = this.runningYear.yearCode;
        this.bannerImage = this.getBannerImage(this.phaseName.toUpperCase());
        this.isLoadingCurrentYear = false;
      }
    });
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.store.select(fromApp.getUserAcknowledges);
    const runningPhase$: Observable<any> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, userAck$, runningPhase$])
      .subscribe(
        ([loggedUser, userAck, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.userAcknowledges = userAck;
            this.runningPhaseDate = runningPhase;
          }
        });
  }

  ngOnInit() {
    this.feedbackDisabledModal = {
      modalId: "148",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.idpDisabledModal = {
      modalId: "149",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.applauseDisabledModal = {
      modalId: "150",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.requestDisabledModal = {
      modalId: "151",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.interviewDisabledModal = {
      modalId: "152",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
  }

  ngAfterViewInit() {
    if (!this.isImpersonate) {
      const forceOpenTutorial = sessionStorage.getItem('openHomeTutorial');

      if (forceOpenTutorial) {
        this.openTutorial();
      }
    }
  }

  // Vai agli obiettivi del selfAssessmente
  goToPersonDetails() {
    this.redirectService.goToPersonDetailsSelfAssessment((this.loggedUser && this.loggedUser.userId), this.runningYear);
  }

  goToSendApplause() {
    this.redirectService.goToSendClapPageSelfAssessment();
  }

  goToFeedback() {
    this.redirectService.goToPersonDetailsFeedbackSelfAssessment(this.loggedUser.userId, this.runningYear);
  }

  goToInterview() {
    this.redirectService.goToFeedbackInterview(this.loggedUser.userId);
  }

  goToRequestFeedback() {
    this.redirectService.goToCreatePeerFeedback('', '');
  }

  goToTakeNote() {
    this.redirectService.goToCreatePrivateNote();
  }


  // Vai al piano di sviluppo del selfAssessmente
  goToDevelopmentPlanPersonDetails() {
    this.redirectService.goToPersonDetailsDevelopmentPlanSelfAssessment((this.loggedUser && this.loggedUser.userId), this.runningYear);
  }

  getBannerImage(phaseName: string) {
    return 'assets/img/head/bg-head-self.png';
  }

  // Gestisce la chiusura del tutorial della home
  onHomeTutorialClosed() {
    sessionStorage.removeItem('openHomeTutorial');

    const updateOnboardingUserAck = sessionStorage.getItem('updateOnboardingUserAck');


    // Se richiesto, aggiorno le user ack dell'utente (significa che era la prima volta che l'utente entrava nell'applicazione e si è aperto da solo il tutorial)
    if (updateOnboardingUserAck) {

      this.isUpdatingAcks = true;
      if (this.updateUserAck$) {
        this.updateUserAck$.unsubscribe();
      }
      let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
      acks.data['ALLOY_PERFORMANCE_ONBOARDING'] = moment().toISOString();

      /* if (this.showBadge) {
         this.updateUserAck$ = this.authService.updateUserAcknowledges(acks)
           .subscribe((data: any) => {
             if (data && data.error) {
               const messageObj: ApplicationModalMessage = {
                 modalId: "133",
                 text: this.translate.instant("errors." + data.error),
                 title: this.translate.instant("generic.WARNING")
               }
               this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
             } else {
               this.store.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
               this.isUpdatingAcks = false;
               this.redirectService.goToHome();
             }
           })
       } else {*/
      this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
        subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "133",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.store.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
            this.isUpdatingAcks = false;
            sessionStorage.removeItem('updateOnboardingUserAck');
          }
        })
      //}
    }
  }

  // Apre il tutorial
  openTutorial() {
    this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
      this.subordinatesWithPhaseStatuses = subordinatesWithPhaseStatuses;
      this.translate.get(
        [
          'tutorials.home.1.TITLE',
          'tutorials.home.1.DESC',
          'tutorials.home.2.TITLE',
          'tutorials.home.2.DESC',
          'tutorials.home.3.TITLE',
          'tutorials.home.3.DESC',
          'tutorials.home.4.TITLE',
          'tutorials.home.4.DESC',
          'tutorials.home.5.TITLE',
          'tutorials.home.5.DESC',
          'tutorials.home.7.TITLE',
          'tutorials.home.7.DESC',
          'tutorials.home.8.TITLE',
          'tutorials.home.8.DESC',
          'tutorials.home.9.TITLE',
          'tutorials.home.9.DESC',
        ])
        .subscribe(translations => {
          this.steps = [
            /*{
            /** Selettore html */
            /*   selector: '#myTeamTag',
               /** Titolo 
               title: translations['tutorials.home.1.TITLE'],
               /** Tour step text 
               content: translations['tutorials.home.1.DESC'],
               orientation: Orientation.Right,
               // /** Action that happens when the step is opened 
               // action?: () => void;
               // /** Action that happens when the step is closed 
               closeAction: () => {
                 this.onHomeTutorialClosed();
               }
               // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
            // skipStep?: boolean;
            // /** Adds some padding for things like sticky headers when scrolling to an element */
            // scrollAdjustment?: number;
            // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
            // useHighlightPadding?: boolean;
            // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
            // highlightPadding?: number;
            //},
            {
              /** Selettore html */
              selector: '#supportTagIcon',
              closeAction: () => {
                this.onHomeTutorialClosed();
              },
              /** Titolo */
              title: translations['tutorials.home.2.TITLE'],
              /** Tour step text */
              content: translations['tutorials.home.2.DESC'],
              orientation: Orientation.Bottom,
              highlightPadding: 15
            }, {
              /** Selettore html */
              selector: '#notifications',
              /** Titolo */
              title: translations['tutorials.home.3.TITLE'],
              /** Tour step text */
              content: translations['tutorials.home.3.DESC'],
              orientation: Orientation.Bottom,
              highlightPadding: 15
            }, {
              // Informazioni utente (avatar, nome, ecc.)
              /** Selettore html */
              selector: '#userDataHeaderContainer',
              /** Titolo */
              title: translations['tutorials.home.9.TITLE'],
              /** Tour step text */
              content: translations['tutorials.home.9.DESC'],
              orientation: Orientation.Bottom,
              highlightPadding: 35
            }, {
              /** Selettore html */
              selector: '#bigBannerTag',
              /** Titolo */
              title: translations['tutorials.home.4.TITLE'],
              /** Tour step text */
              content: translations['tutorials.home.4.DESC'],
              orientation: Orientation.Bottom,
            }, {
              /** Selettore html */
              selector: '#privateBoxTag',
              /** Titolo */
              title: translations['tutorials.home.5.TITLE'],
              /** Tour step text */
              content: translations['tutorials.home.5.DESC'],
              orientation: Orientation.Top,
              highlightPadding: 15
            }]

          // Colloqui
          if (this.showSeeInterviewBox()) {
            this.steps.push({
              /** Selettore html */
              selector: '#interviewsTag',
              /** Titolo */
              title: translations['tutorials.home.8.TITLE'],
              /** Tour step text */
              content: translations['tutorials.home.8.DESC'],
              orientation: Orientation.Top,
              highlightPadding: 15
            })
          }

          // Schede di PAS
          if (this.subordinatesWithPhaseStatuses && this.subordinatesWithPhaseStatuses.length) {
            this.steps.push({
              /** Selettore html */
              selector: '#pasUserTag0',
              /** Titolo */
              title: translations['tutorials.home.7.TITLE'],
              /** Tour step text */
              content: translations['tutorials.home.7.DESC'],
              orientation: Orientation.Right
            });
          }

          const intro = {
            /** Identifier for tour */
            tourId: 'HOME TOUR',
            /** Use orb to start tour */
            useOrb: false,
            /** Steps for the tour */
            steps: this.steps,
            /** Function will be called when tour is skipped */
            skipCallback: (stepSkippedOn: number) => {
              this.onHomeTutorialClosed();
            }
            // /** Function will be called when tour is completed */
            // completeCallback?: () => void;
            // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
            // minimumScreenSize?: number;
            // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
            // resizeDialog?: {
            //   /** Resize dialog title text */
            //   title?: string;
            //   /** Resize dialog text */
            //   content: string;
            // }
          }

          setTimeout(() => {
            this.guidedTour.startTour(intro);
          }, 50);
        });
    });
  }

  // Verifica se mostrre il box dei colloqui
  showSeeInterviewBox() {
    return false;
  }

  ngOnDestroy() {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.updateUserAck$) {
      this.updateUserAck$.unsubscribe();
    }
    const forceOpenTutorial = sessionStorage.getItem('openHomeTutorial');

    if (forceOpenTutorial) {
      sessionStorage.removeItem('openHomeTutorial');
    }

    const updateOnboardingUserAck = sessionStorage.getItem('updateOnboardingUserAck');

    if (updateOnboardingUserAck) {
      sessionStorage.removeItem('updateOnboardingUserAck');
    }
  }
}