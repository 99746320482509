<div class="competence-evaluation-container" id="{{ id + '-container' }}">
  <!-- Titolo categoria -->
  <ng-container *ngIf="competenceList.category">
    <p class="competence-category">{{ competenceList?.category || '' }}</p>
  </ng-container>
  <!-- Lista competenze per categoria-->
  <ng-container *ngFor="let tag of competenceList.competences; let last = last; let competenceIndex = index;">
    <div class="competence-data-container" id="{{ 'competenceIndex' + competenceIndex + '-container' }}">
      <!-- Lato sinistro con titolo e descrizione -->
      <div class="data-content">
        <ng-container *ngIf="tag.title">
          <div class="title-container">
            <p class="title">{{ tag.title }}</p>

            <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/info.svg"
              (click)="onInfoClicked(tag, competenceList.category)">
            </svg-icon>
          </div>
        </ng-container>
        <p class="description">{{tag.text}}</p>
      </div>
      <div class="divisor-vertical"></div>
      <!-- Rating competenza -->
      <ng-container *ngIf="!tag.selfRating">
        <star-rating id="{{ (id || ('competenceIndex' + competenceIndex)) + '-stars' }}" [rating]="tag.rating"
          (onStarClicked)="onRatingChanged(tag)" [scale]="ratingScale" [disabled]="disabled">
        </star-rating>
      </ng-container>

      <!-- Rating competenza (con recap)-->
      <ng-container *ngIf="tag.selfRating">
        <div class="rating-star-content">
          <star-rating [rating]="tag.selfRating" customContainerClass="small-star-size" [grayColor]="true"
            [scale]="ratingScale" [disabled]="true">
          </star-rating>

          <div class="divisor-vertical"></div>
          <star-rating [rating]="tag.rating" customContainerClass="small-star-size" [scale]="ratingScale"
            (onStarClicked)="onRatingChanged(tag)" [disabled]="disabled"></star-rating>
        </div>
      </ng-container>
    </div>
    <!-- Divisore orizzontale se ho più competenze -->
    <ng-container *ngIf="!last">
      <div class="divisor-horizontal"></div>
    </ng-container>

  </ng-container>
</div>