import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { UserAcknowledges } from '../../../commonclasses';
import { RedirectService } from './redirect.service';
import { Console } from 'console';

@Injectable()
export class OnBoardingGuard implements CanActivate {

    userAck: UserAcknowledges = {
        userId: '',
        data: null
    };
    constructor(private store: Store<fromApp.AppState>,
        private redirectService: RedirectService) {
    }

    // Verifica se è presente il check sull'onboarding
    onboardingChecked(userAcknowledges: UserAcknowledges): boolean {
        return userAcknowledges && userAcknowledges.data &&
            userAcknowledges.data['ALLOY_PERFORMANCE_ONBOARDING'] && userAcknowledges.data['ALLOY_PERFORMANCE_ONBOARDING'].length
    }

    // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.getUserAcknowledges)
            .pipe(
                filter((userAck) => {
                    if (!!userAck) {
                        this.userAck = userAck;
                        return true;
                    } else {
                        return false;
                    }
                }),
                map(() => {
                    const updateOnboardingUserAck = sessionStorage.getItem('updateOnboardingUserAck');
                    if (updateOnboardingUserAck) {
                        // Se sono qui, significa che l'utente sta entrando in home page a visualizzare il tutorial
                        return true;
                    }
                    if (this.userAck && this.userAck.data) {
                        if (!this.onboardingChecked(this.userAck)) {
                            this.redirectService.openHomePageTutorial(true);
                            return false;
                        }
                        return true;
                    }
                    this.redirectService.openHomePageTutorial(true);
                    return false;
                }),
            );
    }
}
