import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'item-list-team-status',
  templateUrl: 'item-list-team-status.component.html',
  styleUrls: ['./item-list-team-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemListTeamStatusComponent implements OnInit {
  @Input() message: string = '';
  @Input() type: string = ''; // completed, inProgress, pending, toStart, disabled
  @Input() styleItem: string = ''; // vuoto, border, bordless
  @Input() showPoint: boolean = false;
  @Input() customContainerClass: string = '';

  constructor() {
  }

  ngOnInit() {
    if (this.styleItem != 'border') {
      this.showPoint = true;
    }
  }
}