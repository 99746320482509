import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'competence-evaluation',
  templateUrl: 'competence-evaluation.component.html',
  styleUrls: ['./competence-evaluation.component.scss']
})
export class CompetenceEvaluationComponent implements OnInit {
  @Input() competenceList: any;
  @Input() id?: any;
  // {
  //   category: string,
  //   competences: {
  //     id?: string
  //     title: string,
  //     descr?: string,
  //     rating: any
  //   }[]
  // };
  @Input() ratingScale: number = 5;
  @Input() disabled: boolean = false;
  @Output() ratingChanged = new EventEmitter();
  @Output() infoClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onRatingChanged(rating: any) {
    this.ratingChanged.emit(rating);
  }

  onInfoClicked(competence: any, category: string) {
    competence.category = category;
    this.infoClicked.emit(competence);
  }
}