import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable } from "rxjs";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { CountUsersForImpersonateResponse, ListUsersForImpersonateResponse, SenecaResponse } from "../../../commonclasses";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';
import { stringify } from "querystring";

@Injectable()
export class AdminService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  countPerformanceProcesses() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-performance-assessments-for-admin/')
  }


  listPerformanceProcesses(fromRecord: number, numRecords: number, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-performance-assessments-for-admin/', {
      params: httpParams
    });
  }

  processStructureDetail(perfYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/monitoring-structures-for-admin/', {
      params: httpParams
    });
  }

  exportStructureReport(perfYear: string, preSharedKey?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-monitoring-structures-for-admin-report/', {
      params: httpParams
    });
  }

  countUserImpersonate(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    return this.http.get<SenecaResponse<CountUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-users-for-impersonate/', {
      params: httpParams
    })
  }


  listUserImpersonate(fromRecord: number, numRecords: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    return this.http.get<SenecaResponse<ListUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-users-for-impersonate/', {
      params: httpParams
    });

  }

  impersonateUserForAdmin(userIdToImpersonate: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userIdToImpersonate', userIdToImpersonate);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/impersonate-user-for-admin/', {
      params: httpParams
    });
  }

  // contatori utenti calibration
  calibrationUsersCounter(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-calibration-users-count-for-admin', {
      params: httpParams
    });
  }

  processUserCounter(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-process-participants-stats-for-admin', {
      params: httpParams
    });
  }

  countProcessUsers(perfYear: number, perfYearCode: string, searchedText: string, filters?: any, userIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText);

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-process-participants-for-admin', {
      params: httpParams
    });
  }


  // Lista utenti calibration
  listProcessUsers(perfYear: number, perfYearCode: string, searchedText: string, fromRecord: number, numRecords: number, userIds?: string[], filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-process-participants-for-admin', {
      params: httpParams
    });
  }

  // Ritorna range payout
  getPayoutRange() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-payout-range-by-final-evaluation', {});
  }

  // recap di tutti i dati necessari per definire la calibration
  getUserCalibrationData(perfYear: number | string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-calibration-data-for-admin', {
      params: httpParams
    });
  }

  setCalibration(perfYear: number, perfCareUserCalibrationToUpdate: any) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-calibration-for-admin', {
      perfYear: perfYear,
      perfCareUserCalibrationToUpdate: perfCareUserCalibrationToUpdate
    });
  }

  massiveConfirmCalibration(perfYear: number, userIdsToConfirmCalibration: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/massive-confirm-calibration-for-admin', {
      perfYear: perfYear,
      userIdsToConfirmCalibration: userIdsToConfirmCalibration
    });
  }

  getFinalEvaluationFormDataForAdmin(perfYear: number, perfYearCode: string, targetUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('targetUserId', targetUserId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-final-evaluation-form-data-for-admin', {
      params: httpParams
    });
  }

  // Lista usenti paginata e ricerca per appunti privati
  countFilterUsers(perfYear: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('searchedText', searchUserText);

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-company-users-for-admin/', {
      params: httpParams
    });
  }

  downloadDashboardReport(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());

    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-report-for-admin', {
      params: httpParams
    })
  }

  listFilterUsers(fromRecord: number, numRecords: number, perfYear: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('searchedText', searchUserText);

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-company-users-for-admin/', {
      params: httpParams
    });
  }

  downloadProcessParticipantsReportForAdmin(perfYear: number, perfYearCode: string, allData?: boolean, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('allData', allData ? 'true' : 'false');

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-process-participants-report-for-admin', {
      params: httpParams
    });
  }


  downloadMatrixReport(perfYear: number, perfYearCode: string, userIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-monitoring-matrix-users-for-admin', {
      params: httpParams
    });
  }

  savePostCalibrationFormForAdmin(targetUserIds: string[], comment: string | null, shareForm: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/save-post-calibration-form-for-admin', {
      comment: comment,
      targetUserIds: targetUserIds,
      shareForm: shareForm
    });
  }


  getMonitoringMatrixUsersForAdmin(perfYear: number, perfYearCode: string, userIds?: string[], teamsOfUserIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    } else if (teamsOfUserIds && teamsOfUserIds.length) {
      for (let i = 0; i < teamsOfUserIds.length; i++) {
        httpParams = httpParams.append('teamsOfUserIds', teamsOfUserIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-monitoring-matrix-users-for-admin', {
      params: httpParams
    });
  }

  downloadSimulatedMatrixReportForAdmin(perfYear: number, perfYearCode: string, simulatedData: { userId: string; pasProfile: string; }[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-simulated-matrix-report-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      simulatedData: simulatedData
    });
  }


  countBehavior(perfYear: number, perfYearCode: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-behavior-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listBehavior(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchedText: string, tagIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (tagIds && tagIds.length) {
      for (let i = 0; i < tagIds.length; i++) {
        httpParams = httpParams.append('tagIds', tagIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-behavior-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  listMacroBehaviorForAssessment(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-macro-behavior-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createBehaviorAssessment(perfYear: number, perfYearCode: string, behaviorTagToCreate: { title: string, macroBehaviorTagId: string, description: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-behavior-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      behaviorTagToCreate: behaviorTagToCreate,
    });
  }

  updateBehaviorAssessment(perfYear: number, perfYearCode: string, behaviorTagToUpdate: { tagId?: string, title: string, macroBehaviorTagId: string, description: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-behavior-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      behaviorTagToUpdate: behaviorTagToUpdate,
    });
  }

  deleteBehaviorAssessment(perfYear: number, perfYearCode: string, behaviorTagIdToDelete: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-behavior-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      behaviorTagIdToDelete: behaviorTagIdToDelete,
    });
  }


  importBehaviorInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);
    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-behaviors-in-process-by-year', formData, options);
    return this.http.request(req);

  }

  importCompetenceInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-competences-in-process-by-year', formData, options);
    return this.http.request(req);

  }

  countCompetences(perfYear: number, perfYearCode: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-competence-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listCompetences(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchedText: string, tagIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');

    if (tagIds && tagIds.length) {
      for (let i = 0; i < tagIds.length; i++) {
        httpParams = httpParams.append('tagIds', tagIds[i]);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-competence-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createCompetenceAssessment(perfYear: number, perfYearCode: string, competenceTagToCreate: { title: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-competence-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      competenceTagToCreate: competenceTagToCreate,
    });
  }

  updateCompetenceAssessment(perfYear: number, perfYearCode: string, competenceTagToUpdate: { tagId?: string, title: string }) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-competence-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      competenceTagToUpdate: competenceTagToUpdate,
    });
  }

  deleteCompetenceAssessment(perfYear: number, perfYearCode: string, competenceTagIdToDelete: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-competence-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      competenceTagIdToDelete: competenceTagIdToDelete,
    });
  }

  getDashboardSelfAssessment(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-self-assessment-for-admin/', {
      params: httpParams
    });
  }

  getDashboardPeopleAppraisal(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-people-appraisal-for-admin/', {
      params: httpParams
    });
  }

  getDashboardCalibration(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-calibration-for-admin/', {
      params: httpParams
    });
  }

  getDashboardPas(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-restituzione-pas-for-admin/', {
      params: httpParams
    });
  }

  getDashboardBehaviorCompetence(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-behaviour-and-competences-for-admin/', {
      params: httpParams
    });
  }

  getDashboardOverview(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      }
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-final-overview-for-admin/', {
      params: httpParams
    });
  }

  countRoles(perfYear: number, perfYearCode: string, retrieveBehaviorRole: boolean, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (retrieveBehaviorRole) {
      httpParams = httpParams.append('retrieveBehaviorRole', 'true');
    } else {
      httpParams = httpParams.append('retrieveCompetenceRole', 'true');
    }
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-roles-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listRoles(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, retrieveBehaviorRole: boolean, searchedText: string, tagIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (retrieveBehaviorRole) {
      httpParams = httpParams.append('retrieveBehaviorRole', 'true');
    } else {
      httpParams = httpParams.append('retrieveCompetenceRole', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-roles-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  getRole(perfYear: number, perfYearCode: string, roleId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('tagId', roleId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-role-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createRole(perfYear: number, perfYearCode: string, roleTagToCreate: { title: string, evaluateSpecialisticGoal?: boolean; competenceTags?: { tagId: string }[], behaviorTags?: { tagId: string, evaluationScaleTagId: string }[] }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-role-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      roleTagToCreate: roleTagToCreate
    });
  }

  updateRole(perfYear: number, perfYearCode: string, roleTagToUpdate: { tagId?: string, title: string, evaluateSpecialisticGoal?: boolean; competenceTags?: { tagId: string }[], behaviorTags?: { tagId: string, evaluationScaleTagId: string }[] }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-role-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      roleTagToUpdate: roleTagToUpdate
    });
  }

  deleteRole(perfYear: number, perfYearCode: string, roleTagIdToDelete: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-role-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      roleTagIdToDelete: roleTagIdToDelete
    });
  }

  countDictionaries(perfYear: number, perfYearCode: string, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-evaluation-scales-for-assessment-for-admin/', {
      params: httpParams
    })
  }


  listDictionaries(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchedText: string, tagIds?: string[], allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (tagIds && tagIds.length) {
      for (let i = 0; i < tagIds.length; i++) {
        httpParams = httpParams.append('tagIds', tagIds[i]);
      }
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-evaluation-scales-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  getDictionary(perfYear: number, perfYearCode: string, tagId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('tagId', tagId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-evaluation-scale-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  createDictionary(perfYear: number, perfYearCode: string, evaluationScaleToCreate: { title: string, description: string, evaluation: { evaluationPoint: number, evaluationDescription: string }[]; }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-evaluation-scale-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      evaluationScaleToCreate: evaluationScaleToCreate
    });
  }

  deleteDictionary(perfYear: number, perfYearCode: string, evaluationScaleToDelete: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-evaluation-scale-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      evaluationScaleToDelete: evaluationScaleToDelete
    });
  }

  updateDictionary(perfYear: number, perfYearCode: string, evaluationScaleToUpdate: { scaleTagId: string, title: string, description: string, evaluation: { evaluationPoint: number, evaluationDescription: string }[]; }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-evaluation-scale-for-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      evaluationScaleToUpdate: evaluationScaleToUpdate
    });
  }

  downloadCompetencesReport(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/download-competences-for-assessment-for-admin/', {
      params: httpParams
    });
  }

  // riapre scheda self assessment
  reopenSelfAssessmentSchedule(userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/reopen-self-assessment-final-evaluation-form-for-admin', {
      userId: userId,
    });
  }

  // riapre scheda appraisal
  reopenPeopleAppraisalSchedule(userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/reopen-people-appraisal-final-evaluation-form-for-admin', {
      userId: userId,
    });
  }

  // Import dei dizionari
  importDictionaryInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);
    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-behavior-scale-in-process-by-year', formData, options);
    return this.http.request(req);
  }

  // Import dei dizionari
  importRolesInProcessByYear(perfYear: number, perfYearCode: string, file: any, simulate?: boolean) {
    let formData = new FormData();
    formData.append('perfYear', perfYear.toString());
    formData.append('perfYearCode', perfYearCode);
    formData.append('file', file);

    if (simulate) {
      formData.append('simulate', 'true');
    }

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/import-roles-in-process-by-year', formData, options);
    return this.http.request(req);
  }

  getProcess(assessmentId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-for-admin', {
      assessmentId: assessmentId
    });
  }

  createProcess(perfYear: number, perfYearCode: string, processTitle: string, processNotes: string, processStatus: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-performance-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      processTitle: processTitle,
      processNotes: processNotes,
      processStatus: processStatus
    });
  }

  // Aggiorna il processo
  updateProcess(perfYear: number, perfYearCode: string, assessmentId: string, processTitle: string, processNotes: string, processStatus: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-performance-assessment-for-admin', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      assessmentId: assessmentId,
      processTitle: processTitle,
      processNotes: processNotes,
      processStatus: processStatus
    });
  }

  // aggiorna solo le date delle fasi
  updateProcessPhase(perfYear: number, perfYearCode: string, assessmentId: string, processTitle: string, processNotes: string, processStatus: string, peopleAppraisalStartDate?: Date, peopleAppraisalEndDate?: Date, selfAssessmentStartDate?: Date, selfAssessmentEndDate?: Date) {
    if (peopleAppraisalStartDate && peopleAppraisalEndDate) {
      return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-performance-assessment-for-admin', {
        perfYear: perfYear,
        perfYearCode: perfYearCode,
        assessmentId: assessmentId,
        processTitle: processTitle,
        processNotes: processNotes,
        processStatus: processStatus,
        peopleAppraisalStartDate: peopleAppraisalStartDate,
        peopleAppraisalEndDate: peopleAppraisalEndDate,
      });
    } else {
      return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-performance-assessment-for-admin', {
        perfYear: perfYear,
        perfYearCode: perfYearCode,
        assessmentId: assessmentId,
        processTitle: processTitle,
        processNotes: processNotes,
        processStatus: processStatus,
        selfAssessmentStartDate: selfAssessmentStartDate,
        selfAssessmentEndDate: selfAssessmentEndDate,
      });
    }
  }

  countAnagUser(perfYear: number, perfYearCode: string, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-users-count-in-process-for-admin/', {
      params: httpParams
    });
  }

  listAnagUser(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (filters) {
      if (filters.subcompany && filters.subcompany.value) {
        httpParams = httpParams.append('socDistacco', filters.subcompany.value);
      }
      if (filters.cdcfinance && filters.cdcfinance.value) {
        httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
      }
      if (filters.areaReference && filters.areaReference.value) {
        httpParams = httpParams.append('ramo', filters.areaReference.value);
      } 0
      if (filters.department && filters.department.value) {
        httpParams = httpParams.append('defTpDip', filters.department.value);
      }
      if (filters.team && filters.team.value) {
        httpParams = httpParams.append('team', filters.team.value);
      }
      if (filters.subTeam && filters.subTeam.value) {
        httpParams = httpParams.append('subTeam', filters.subTeam.value);
      }
      if (filters.position && filters.position.value) {
        httpParams = httpParams.append('chief', filters.position.value);
      }
      if (filters.supervisor && filters.supervisor.value) {
        httpParams = httpParams.append('supervisor', filters.supervisor.value);
      }
      if (filters.contractual && filters.contractual.value) {
        httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
      }
      if (filters.office && filters.office.value) {
        httpParams = httpParams.append('sedeLavoro', filters.office.value);
      }
      if (filters.selfStatus && filters.selfStatus.value) {
        httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
      }
      if (filters.peopleStatus && filters.peopleStatus.value) {
        httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
      }
      if (filters.calibrationStatus && filters.calibrationStatus.value) {
        for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
          httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
        }
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-users-data-in-process-for-admin/', {
      params: httpParams
    });
  }


  getAnagCountersData(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-users-analytics-count-for-admin/', {
      params: httpParams
    });
  }

  getUserAnagraphicById(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-anagraphic-user-data-by-id-for-admin/', {
      params: httpParams
    });
  }

  setPersonAsSync(userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-person-as-sync', {
      userId: userId
    });
  }

  setPersonAsAsync(userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-person-as-async', {
      userId: userId
    });
  }

  getValidUsersForSetManager(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (filters) {
      if (filters.searchedText) {
        httpParams = httpParams.append('searchedText', filters.searchedText);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-valid-users-for-set-as-manager-for-admin/', {
      params: httpParams
    });
  }


  setUserManagerByUserId(userId: string, newManagerUserId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-user-performance-manager-by-userId', {
      userId: userId,
      newManagerUserId: newManagerUserId
    });
  }
}