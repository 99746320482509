import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { catchError, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'admin-all-competences',
  templateUrl: './allCompetences.component.html',
  styleUrls: ['./allCompetences.component.scss']
})
export class AllCompetencesComponent implements OnDestroy {
  @Input() processYear: {
    id: number,
    code: string
  } = { id: 0, code: '' }
  runningYear: any;
  loggedUser: any;

  competenceTypeList: { id: string; title: string; isDisabled: boolean; }[] = [];

  // filters
  isFilterOpened: boolean = false;
  searchedText: string = '';
  isLoadingFilters: boolean = true;
  getFiltersData$: Subscription = new Subscription;
  filtersObject: any = {};
  allChecked: boolean = false;
  selectedFilters: any = {};
  genderFilterTooltipModal: ApplicationModalMessage = {
    modalId: 'cal021',
    title: '',
    text: ''
  }
  selectedFiltersNumber: number = 0;

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    }
  ]
  selectedRows: { id: number, title: number };
  translations: any;
  menuOptions: { id: string; title: any; icon: string; disabled: boolean; }[] = [];

  isBehaviorList: boolean = false;
  isLoadingData: boolean = false;
  dataObject: {
    fromRecord: number,
    numRecords: number,
    page: number,
    list: any[],
    counter: number
  } = {
      fromRecord: 0,
      numRecords: 10,
      page: 1,
      list: [],
      counter: 0
    };
  selectedData: any[] = [];
  modalDataToDelete: any;
  getBehaviorList$: any;
  importFile: any;
  resetIncludeUpload: boolean = false;
  importNotice: string = '';
  isLoadingImport: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {

    this.isLoadingData = true;

    this.selectedRows = this.rowPerPageOptions[0];

  }

  ngOnInit() {
    this.translate.get([
      'setupCompetences.BEHAVIOR',
      'setupCompetences.TECH_SKILL',
      'generic.EDIT',
      'generic.DELETE',
      'setupCompetences.modals.FILE_CONTAINS',
      'setupCompetences.modals.BEHAVIOR',
      'setupCompetences.modals.SKILL',
    ]).subscribe(translations => {
      this.translations = translations;
      this.competenceTypeList = [
        {
          id: 'behavior',
          title: this.translations['setupCompetences.BEHAVIOR'],
          isDisabled: false
        },
        {
          id: 'tech',
          title: this.translations['setupCompetences.TECH_SKILL'],
          isDisabled: true
        }
      ]
      if (window.location.href.includes('behaviors')) {
        this.isBehaviorList = true;
        this.competenceTypeList[0].isDisabled = false;
        this.competenceTypeList[1].isDisabled = true;
      } else {
        this.isBehaviorList = false;
        this.competenceTypeList[0].isDisabled = true;
        this.competenceTypeList[1].isDisabled = false;
      }
      this.menuOptions = [
        {
          id: 'edit',
          title: translations['generic.EDIT'],
          icon: '/assets/img/icons/edit.svg',
          disabled: false
        },
        {
          id: 'delete',
          title: translations['generic.DELETE'],
          icon: '/assets/img/icons/trash-2.svg',
          disabled: false
        }
      ]
      this.getTabCounters();
    });
  }


  changeCurrentCompetenceType(tab: any) {
    this.isBehaviorList = tab.id == 'behavior';
    let url = window.location.href.split('/#/')[1];
    url = url.split('/all/')[0];
    if (this.isBehaviorList) {
      url += '/all/behaviors'
    } else {
      url += '/all/techSkills'
    }
    this.router.navigate([url]);
  }

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }


  // ricerca tutte le competenze
  searchedTextChanged(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.dataObject.list = [];
    this.dataObject.counter = 0;
    this.dataObject.fromRecord = 0;
    this.dataObject.page = 1;
    this.getData();
  }

  clearAllCompetenceFilters() {
    // this.selectedFilters = {
    //   chiaveSesso: this.filtersObject.chiaveSesso[0],
    //   denominazioneSO3: this.filtersObject.denominazioneSO3[0],
    //   denominazioneSO5: this.filtersObject.denominazioneSO5[0],
    //   area: this.filtersObject.area[0],
    //   funzione: this.filtersObject.funzione[0],
    //   mansione: this.filtersObject.mansione[0],
    //   structures: this.filtersObject.structures[0],
    //   sti: this.filtersObject.sti[0],
    //   calibrationStatusTypes: this.filtersObject.calibrationStatusTypes[0]
    // }
    this.selectedFiltersNumber = 0;
    this.searchedText = ''
  }

  // Vai alla pagina di creazione del comportamento
  goToNewBehavior(isBehavior?: boolean) {
    if (isBehavior) {
      this.router.navigate(['admin/createUpdateBehavior', this.processYear.id, this.processYear.code])
    } else {
      this.router.navigate(['admin/createUpdateCompetence', this.processYear.id, this.processYear.code])
    }
  }

  // Scarica il tracciato di tutte le competenze
  downloadAllCompetenceReport() {

  }


  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;

    this.selectedData = [];
    if (this.dataObject.list && this.dataObject.list.length > 0) {
      for (let i = 0; i < this.dataObject.list.length; i++) {
        this.dataObject.list[i].isChecked = this.allChecked;
        this.addRemoveData(this.dataObject.list[i]);
      }
    }
  }

  pageChanged(page: number) {
    this.dataObject.page = page;
    this.dataObject.list = [];
    this.getData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.dataObject.numRecords = item.id;
    this.dataObject.list = [];
    this.dataObject.fromRecord = 0;
    this.getData();
  }


  goToProcessDetail(year: string) {
  }

  openCloseMenu(data: any) {
    let currentPresent = this.selectedData.find((x: any) => x.id == data.id);
    if (!currentPresent) {
      this.addRemoveData(data);
    }
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid(this.menuOptions[i], data)
    }
    data.isMenuOpen = !data.isMenuOpen;
  }

  isMenuOptionValid(option: any, user: any) {
    if (option.id == 'edit') {
      if (this.selectedData && this.selectedData.length > 1) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  // Associato alla direttiva clickOutside
  closeMenu(data: any) {
    data.isMenuOpen = false;
  }

  addRemoveData(newObject: any) {
    if (this.selectedData && this.selectedData.length) {
      if (!newObject.isChecked) {
        this.selectedData = this.selectedData.filter((tmp: any) => tmp.id != newObject.id);
      } else {
        this.selectedData.push(newObject);
      }
    } else {
      this.selectedData = [newObject];
    }
  }

  getTabCounters() {
    let promiseArray = [
      this.adminService.countBehavior(this.processYear.id, this.processYear.code, this.searchedText).toPromise(),
      this.adminService.countCompetences(this.processYear.id, this.processYear.code, this.searchedText).toPromise()
    ];

    Promise.all(promiseArray).then((results) => {
      this.competenceTypeList[0].title = this.translations['setupCompetences.BEHAVIOR'] + ' (' + (results[0] && results[0].response || 0) + ')';
      this.competenceTypeList[1].title = this.translations['setupCompetences.TECH_SKILL'] + ' (' + (results[1] && results[1].response || 0) + ')';
    }).then(() => {
      this.getData();
    });
  }


  getData() {
    if (this.isBehaviorList) {
      this.getBehaviorList();
    } else {
      this.getCompetenceList();
    }
  }


  getBehaviorList() {
    this.isLoadingData = true;
    if (this.getBehaviorList$) {
      this.getBehaviorList$.unsubscribe();
    }

    this.getBehaviorList$ = this.adminService.countBehavior(this.processYear.id, this.processYear.code, this.searchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.dataObject.counter = counter.response;

              // Calcolo la paginazione
              this.dataObject.fromRecord = (this.dataObject.page - 1) * this.dataObject.numRecords;

              if (this.dataObject.counter) {
                return this.adminService.listBehavior(this.processYear.id, this.processYear.code, this.dataObject.fromRecord, this.dataObject.numRecords, this.searchedText,);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.response && data.response.length) {
              for (let i = 0; i < data.response.length; i++) {
                data.response[i].id = data.response[i].behaviorId;
                data.response[i].value = data.response[i].parentBehavior?.text;
                data.response[i].hasDescription = data.response[i].text && data.response[i].text.length;
              }
              this.dataObject.list = data.response;
            } else {

              this.dataObject.list = [];
            }
          }
          this.isLoadingData = false;
        }
        , (err: any) => {
          this.isLoadingData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }


  getCompetenceList() {
    this.isLoadingData = true;

    if (this.getBehaviorList$) {
      this.getBehaviorList$.unsubscribe();
    }

    this.getBehaviorList$ = this.adminService.countCompetences(this.processYear.id, this.processYear.code, this.searchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.dataObject.counter = counter.response;

              // Calcolo la paginazione
              this.dataObject.fromRecord = (this.dataObject.page - 1) * this.dataObject.numRecords;

              if (this.dataObject.counter) {
                return this.adminService.listCompetences(this.processYear.id, this.processYear.code, this.dataObject.fromRecord, this.dataObject.numRecords, this.searchedText,);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.response && data.response.length) {
              for (let i = 0; i < data.response.length; i++) {
                data.response[i].id = data.response[i].competenceId;
                data.response[i].value = data.response[i].parentBehavior?.text;
                data.response[i].hasDescription = data.response[i].text && data.response[i].text.length;
              }
              this.dataObject.list = data.response;
            } else {
              this.dataObject.list = [];
            }
          }
          this.isLoadingData = false;
        }
        , (err: any) => {
          this.isLoadingData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }


  onMenuOptionsClicked(options: any, data: any) {
    if (!options.disabled) {
      if (this.isBehaviorList && options.id == 'edit') {
        this.router.navigate(['admin/createUpdateBehavior/', this.processYear.id, this.processYear.code, data.id]);
      } else if (!this.isBehaviorList && options.id == 'edit') {
        this.router.navigate(['admin/createUpdateCompetence/', this.processYear.id, this.processYear.code, data.id]);
      } else {
        this.modalDataToDelete = data;
        console.log("data delete", data);
        this.modalService.open('delete-data');
      }
    }
  }

  // Check singolo comportamento / skill
  selectData(competence: any) {
    competence.isChecked = !competence.isChecked;
    this.addRemoveData(competence);
  }


  closeDeleteDataModal(confirm?: boolean) {
    this.modalService.close('delete-data');
    if (confirm) {
      this.isLoadingData = true;

      let idList = this.selectedData.map((x: any) => x.id);
      console.log(idList);
      let deleteService = this.isBehaviorList ? this.adminService.deleteBehaviorAssessment(this.processYear.id, this.processYear.code, idList) : this.adminService.deleteCompetenceAssessment(this.processYear.id, this.processYear.code, idList);

      deleteService.subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          if (data.error == 'CANNOT_DELETE_TAG_BECAUSE_ALREADY_EVALUATED') {
            const messageObj: ApplicationModalMessage = {
              modalId: "delbe001",
              text: this.isBehaviorList ? this.translate.instant("setupCompetences.notice.CANNOT_DELETE_TAG_BECAUSE_ALREADY_EVALUATED_BEHAVIOR") : this.translate.instant("setupCompetences.notice.CANNOT_DELETE_TAG_BECAUSE_ALREADY_EVALUATED_SKILL"),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "delbe001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.selectedData = [];
          this.modalDataToDelete = null;
          this.isLoadingData = false;
        } else {
          let x: any = document.getElementById("snackbar-delete");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
        }
        this.dataObject.list = [];
        this.dataObject.fromRecord = 0;
        this.dataObject.page = 1;
        this.getData();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "delbe002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.redirectService.goBackBrowser();
      });
    }
  }

  openImportModal() {
    this.resetIncludeUpload = true;
    this.importFile = null;
    this.modalService.open('import-modal');
  }


  closeImportModal(confirm?: boolean) {
    this.modalService.close('import-modal');
    if (confirm) {
      if (this.isBehaviorList) {
        this.isLoadingData = true;
        this.adminService.importBehaviorInProcessByYear(this.processYear.id, this.processYear.code, this.importFile)
          .subscribe((data: any) => {
            if (data && data.body && data.body.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "da001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingData = false;
            } else {
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
              this.importFile = null;
              this.getData();
            }
          },
            (err: any) => {
              const messageObj: ApplicationModalMessage = {
                modalId: "da002",
                text: this.translate.instant("errors." + err?.message || err),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingData = false;
            });
      } else {
        this.isLoadingData = true;
        this.adminService.importCompetenceInProcessByYear(this.processYear.id, this.processYear.code, this.importFile)
          .subscribe((data: any) => {
            if (data && data.body && data.body.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "da001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingData = false;
            } else {
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
              this.importFile = null;
              this.getData();
            }
          },
            (err: any) => {
              const messageObj: ApplicationModalMessage = {
                modalId: "da002",
                text: this.translate.instant("errors." + err?.message || err),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingData = false;
            });
      }
    }
  }


  assignFile(file: any) {
    this.importFile = file;

    if (this.isBehaviorList) {
      this.isLoadingImport = true;
      this.adminService.importBehaviorInProcessByYear(this.processYear.id, this.processYear.code, this.importFile, true)
        .subscribe((data: any) => {
          console.log("data", data);
          if (data && data.body && data.body.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "da001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingImport = false;
          } else {
            this.importNotice = this.translations['setupCompetences.modals.FILE_CONTAINS'] + ' <b>' + data.body?.response?.behaviorsTagsToCreateCount || 0 + '</b> ';
            this.importNotice += this.translations['setupCompetences.modals.BEHAVIOR'];
            this.isLoadingImport = false;
          }
        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "da002",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingImport = false;
          });
    } else {
      this.isLoadingImport = true;
      this.adminService.importCompetenceInProcessByYear(this.processYear.id, this.processYear.code, this.importFile)
        .subscribe((data: any) => {
          if (data && data.body && data.body.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "da001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingImport = false;
          } else {
            this.importNotice = this.translations['setupCompetences.modals.FILE_CONTAINS'] + ' <b>' + data.body?.response?.competenceTagsToCreateCount || 0 + '</b> ';
            this.importNotice += this.translations['setupCompetences.modals.SKILL'];
            this.isLoadingImport = false;
          }

        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "da002",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingImport = false;
          });
    }
  }

  downloadTrack() {
    let filePath = '';
    if (this.isBehaviorList) {
      filePath = '/assets/docs/ModelloComportamenti.xlsx';
    } else {
      filePath = '/assets/docs/ModelloCompetenze.xlsx';
    }
    let link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();

  }

  ngOnDestroy(): void {
    if (this.getBehaviorList$) {
      this.getBehaviorList$.unsubscribe();
    }
  }
}