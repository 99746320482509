<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.gt-xs="16px">

  <banner-home *ngIf="runningPhaseDate && runningPhaseDate.length" [phaseName]="phaseName" [endDate]="runningPhaseDate"
    [runningYear]="runningYear.year" [selfAssessment]="true" [phaseEndDate]="runningPhaseDate"></banner-home>

  <ng-container *ngIf="!isLoadingCurrentYear">
    <!-- Homepage self assessment-->
    <div class="box-collection-container">
      <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.gt-xs="row wrap"
        fxLayoutGap.gt-xs="16px grid" fxLayoutAlign="start center">
        <!-- Scheda utente -->
        <box-home fxFlex="50" (onButtonClicked)="goToPersonDetails()"
          imgPath="../../../../assets/img/icons/homeIcons/ico-goals-img.png"
          title="{{('homeCards.selfAssessment.objectives.TITLE' | translate) + ' ' + phaseName + ' ' + (runningYear && runningYear.year)}}"
          description="homeCards.selfAssessment.objectives.DESCRIPTION">
        </box-home>
        <!-- Appunti privati -->
        <box-home id="privateBoxTag" fxFlex="50" (onButtonClicked)="goToTakeNote()"
          imgPath="../../../../assets/img/icons/homeIcons/ico-notes-img.png"
          title="{{'homeCards.peopleAppraisal.notes.TITLE' | translate}}"
          description="homeCards.peopleAppraisal.notes.DESCRIPTION">
        </box-home>
      </div>
    </div>
  </ng-container>
</div>