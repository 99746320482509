<div class="user-card-wrapper" *ngIf="user" (click)="emitOnCheck()" [ngClass]="{'active': user.isChecked}">
  <avatar-img [initials]="user.initials" [src]="user.avatar" size="medium"></avatar-img>
  <div class="name-wrapper" [ngClass]="{'row': isFeedbackCard}">
    <p class="text-default">{{ user.surname }}</p>
    <p class="text-default">{{ user.name }}</p>
  </div>
  <div class="check-wrapper">
    <svg-icon *ngIf="user.isChecked" src="assets/img/icons/check.svg" class="checkmark" [applyClass]="true">
    </svg-icon>
  </div>
</div>