<div *ngIf="runningYear" class="wrapper-layout-peopleAppraisal">
  <!-- Sidebar con il menù principale -->
  <!-- [ngClass]="{'open-on-mobile': isTeamButtonClicked}" -->
  <team-sidebar class="team-sidebar" *ngIf="runningPhase && showSidebar()" [users]="subordinatesWithPhaseStatuses"
    [activeUserId]="activeUserId" [currentPhaseId]="runningPhase.phaseId" (userClicked)="onUserClicked($event)"
    [isLoadingUsers]="isLoadingSubordinates" [hasImpersonateBanner]="showImpersonateBanner ">
  </team-sidebar>

  

  <div class="router-outlet-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>