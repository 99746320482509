import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, RequiredAuth } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
  selector: 'app-admin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class AdminHomeComponent {
  runningYear$: Subscription;
  runningYear: any;
  loggedUser: any;
  combinedSelected$: Subscription;
  phaseName: string = "GOAL_SETTING";
  bannerImage: string = '/assets/img/head/bg-head-demo.png';
  updateUserAck$: any;
  userAcknowledges: any;
  runningPhase: any;
  isLoadingCurrentPhase: boolean = true;
  currentYear = new Date().getFullYear();

  boxDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  canAccessCalibration: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private analyticsService: AnalyticsService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Home - Admin");

    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear && this.runningYear.yearCode) {
        this.phaseName = this.runningYear.yearCode;
        this.bannerImage = this.getBannerImage(this.phaseName.toUpperCase());
      }
    });
    sessionStorage.setItem('savedFilters', '');
    sessionStorage.setItem('savedFiltersNumber', '0');
    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    const getRunningPhase$: Observable<any> = this.appStore.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, userAck$, getRunningPhase$])
      .subscribe(
        ([loggedUser, userAck, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            if (loggedUser.auths && loggedUser.auths.find((x: any) => x == RequiredAuth.PERFORMANCECARE_CALIBRATION_ACCESS)) {
              this.canAccessCalibration = true;
            }
            this.runningPhase = runningPhase;
            this.userAcknowledges = userAck;
            this.bannerImage = this.getBannerImage(this.phaseName);
          }
        });
  }

  ngOnInit() {
    this.boxDisabledModal = {
      modalId: "a001",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
  }

  goToMonitoring() {
    this.router.navigate(['/admin/monitoring']);
  }

  goToSetupCompetences() {
    this.router.navigate(['/admin/setupCompetences']);
  }

  goToProcessSetup() {
    this.router.navigate(['/admin/processSetup']);
  }

  goToPeopleAnag() {
    this.router.navigate(['/admin/peopleAnagraphics']);
  }

  goToStructureAnag() {
    this.router.navigate(['/admin/structureAnag']);
  }

  goToImpersonate() {
    this.router.navigate(['/admin/impersonate']);
  }

  goToDashboard() {
    this.router.navigate(['/admin/dashboard']);
  }

  getBannerImage(phaseName: string) {
    return 'assets/img/head/bg-head-demo.png';
  }
}
