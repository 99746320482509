<page-container>

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
      [title]="'support.PAGE_TITLE' | translate" [description]="'support.PAGE_DESCRIPTION' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <div class="page-container">
        <!-- Riferimenti -->
        <div class="section-title">
          <p translate="support.REF"></p>
          <svg-icon [applyClass]="true" src="/assets/img/icons/contact.svg">
          </svg-icon>
        </div>

        <div class="tutorial-list-item">
          <p translate="support.EMAIL"></p>
          <a href="mailto:hr.performance@Alloy">
            <p class="action" translate="support.SEND_MAIL"></p>
          </a>
        </div>

        <!-- Tutorial guidati -->
        <div class="section-title margin-top10">
          <p translate="support.TUTORIALS"></p>
          <svg-icon [applyClass]="true" src="/assets/img/icons/flag.svg">
          </svg-icon>
        </div>

        <div class="tutorial-list-item">
          <p translate="support.HOME_PAGE_TUTORIAL"></p>
          <p (click)="openHomePageTutorial()" class="action" translate="support.START"></p>
        </div>

        <div class="tutorial-list-item">
          <p translate="support.PAS_SCHEDULE_TUTORIAL"></p>
          <p (click)="openPasStructure()" class="action" translate="support.START"></p>
        </div>

        <div class="tutorial-list-item padding-left18">
          <p>{{ ('support.pasStep.STEP1' | translate) + ' ' + currentYear }}</p>
          <p (click)="openPasStep1()" class="action" translate="support.START"></p>
        </div>

        <div class="tutorial-list-item padding-left18">
          <p>{{ ('support.pasStep.STEP2' | translate) + ' ' + currentYear }}</p>
          <p (click)="openPasStep2()" class="action" translate="support.START"></p>
        </div>

        <div *ngIf="showCompetenceStep" class="tutorial-list-item padding-left18">
          <p>{{ ('support.pasStep.STEP3' | translate) + ' ' + currentYear
            }}</p>
          <p (click)="openPasStep3()" class="action" translate="support.START"></p>
        </div>

        <div *ngIf="showTechStep" class="tutorial-list-item padding-left18">
          <p>{{ ('support.pasStep.STEP3_TECH' | translate) + ' ' + currentYear }}</p>
          <p (click)="openPasStep3(true)" class="action" translate="support.START"></p>
        </div>

        <div class="tutorial-list-item padding-left18">
          <p *ngIf="!has3Steps">{{ ('support.pasStep.STEP4' | translate) + ' ' + currentYear }}</p>
          <p *ngIf="has3Steps">{{ ('support.pasStep.STEP4_BIS' | translate) + ' ' + currentYear }}</p>
          <p (click)="openPasStep4()" class="action" translate="support.START"></p>
        </div>

        <!--<div class="tutorial-list-item padding-left18">
          <p>{{ ('support.pasStep.STEP5' | translate) }}</p>
          <p class="action" translate="support.START"></p>
        </div> -->

        <!-- Materiali formativi -->
        <div class="section-title margin-top10">
          <p translate="support.MATERIALS"></p>
          <svg-icon [applyClass]="true" src="/assets/img/icons/play-circle.svg">
          </svg-icon>
        </div>

        <div (click)="showMaterial(1)" class="tutorial-list-item material">
          <p [translate]="'support.materials.METHOD_DEFINITION' | translate"></p>
          <p class="action" translate="support.START"></p>
        </div>
        <div (click)="showMaterial(2)" class="tutorial-list-item material">
          <p *ngIf="!isSelfAssessment" [translate]="'support.materials.POSSIBLE_SCENARIOS' | translate"></p>
          <p *ngIf="isSelfAssessment" [translate]="'support.materials.SELF_ASS' | translate"></p>
          <p class="action" translate="support.START"></p>
        </div>
        <div (click)="showMaterial(3)" class="tutorial-list-item material">
          <p *ngIf="!isSelfAssessment" [translate]="'support.materials.EVALUATION_RISKS' | translate"></p>
          <p *ngIf="isSelfAssessment" [translate]="'support.materials.SELF_RISKS' | translate"></p>
          <p class="action" translate="support.START"></p>
        </div>
        <div (click)="showMaterial(4)" class="tutorial-list-item material">
          <p *ngIf="!isSelfAssessment" [translate]="'support.materials.EFFECTIVE_FEEDBACK' | translate"></p>
          <p *ngIf="isSelfAssessment" [translate]="'support.materials.SELF_FEEDBACK' | translate"></p>
          <p class="action" translate="support.START"></p>
        </div>
        <div (click)="showMaterial(5)" class="tutorial-list-item material">
          <p *ngIf="!isSelfAssessment" [translate]="'support.materials.PREP_ORG' | translate"></p>
          <p *ngIf="isSelfAssessment" [translate]="'support.materials.CONSTRUCTIVE_FEEDBACK' | translate"></p>
          <p class="action" translate="support.START"></p>
        </div>
        <ng-container *ngIf="!isSelfAssessment">
          <div (click)="showMaterial(6)" class="tutorial-list-item material">
            <p [translate]="'support.materials.COMMUNICATION_TECH' | translate"></p>
            <p class="action" translate="support.START"></p>
          </div>
        </ng-container>

        <!--div class="tutorial-list-item">
          <p>Nome materiale</p>
          <p class="action disabled" translate="support.DOWNLOAD"></p>
        </div-->
      </div>
    </div>
  </div>
</page-container>

<modal id="material-modal" (onClose)="closeMaterialModal()" [cancelLabel]="'generic.CLOSE' | translate"
  (onCancel)="closeMaterialModal()">
  <ng-container *ngIf="materialData && materialData.src">
    <p class="modal-title center">{{ materialData.title }}</p>
    <div class="video-container">
      <azure-player *ngIf="materialData.src" [playerVisible]="true" [objectUrl]="materialData.src">
      </azure-player>
    </div>
  </ng-container>

</modal>