import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { DeletePrivateNoteFeedbackForManagerResponse, SenecaResponse } from 'src/commonclasses';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { Subordinateservice } from '../services/subordinates.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as PeopleAppraisalActions from "../ngrx/peopleAppraisal.actions";
import { Subscription } from 'rxjs';
import { TabDossier } from 'src/app/utils/classes.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';
import { PerfAlloyAssessmentPhaseKeys, PerfAlloyUserGoal, PerfAlloyYearCodes, RentStatusTypes } from 'atfcore-commonclasses';
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-peopleAppraisal-operations-personDetails',
  templateUrl: './personDetails.component.html',
  styleUrls: ['./personDetails.component.scss']
})
export class PeopleAppraisalPersonDetailsComponent implements OnInit, OnDestroy {
  getPersonDetails$: any;
  personDetails$!: Subscription;
  personDetails: any;
  isLoadingPersonDetails = false;
  routeParams$!: Subscription;
  userId: string = '';
  loggedUser$: any;
  runningYear$: any;
  runningYear: any = {};

  getUserProfileTab$!: Subscription;
  isLoadingTab: boolean = true;
  contentTabList!: TabDossier[];
  currentContentTab!: TabDossier;
  competenceFeedbackChars: number = 600;
  maxTextAreaChars: number = 600;

  getRunningPhase$: Subscription = new Subscription();
  runningPhase: any;

  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;

  // Variabili gestione stepper
  stepList: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean }[] = [];
  currentStep: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean } = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };

  toEvaluateList: any[] = []
  isLoadingToEvaluateGoals: boolean = false;
  toEvaluateListCopy: any;

  stars: any[] = [];
  manageBehaviorComments: string = '';
  manageBehaviorCommentsCopy: string = '';


  techSkills: any = {};
  isLoadingTechSkills: boolean = false;
  techSkillComment: string = '';
  techSkillCommentCopy: string = '';

  newObjectiveList: { id: string, title: string, isEdit?: boolean, isSelected?: boolean, tmpId?: string }[] = [];
  isLoadingDefinedObjectives: boolean = false;
  objectiveToDelete: any;
  selectedObjective: any;
  recapDevelopmentDescription: any;
  isNoteSection: boolean = false;
  translations: any;
  loggedUser: any;
  competenceForModal: any;

  selectedInfoTabPerformerKey: string | undefined = '';
  hrRemarks: string = 'Sed scelerisque nisl laoreet ex aliquam, sed scelerisque lorem rutrum. Donec bibendum neque a rhoncus tristique. Vestibulum in felis risus. Pellentesque euismod lectus ac sem faucibus rutrum. Proin tincidunt ipsum risus, vitae porttitor odio gravida ut. Maecenas ut purus dolor.';
  noteIdToDelete: string = '';
  isLoadingPrivateNotes: boolean = false;
  privateNotesList: any;
  getPrivateNotesList$: Subscription = new Subscription();
  getUserGoalToEvaluate$: Subscription = new Subscription();
  getUserGoalToDefine$: Subscription = new Subscription();
  createOrUpdatePerformanceAssessmentGoalsForManager$: Subscription = new Subscription();
  deletePerformanceAssessmentGoalsForManager$: Subscription = new Subscription();
  isLoadingBehaviours: boolean = false;
  parentBehaviours: any;
  parentBehaviourIds: any;
  competenceArray: any;

  intro!: GuidedTour;
  steps?: TourStep[];

  createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$: any;
  starsModel: any = {
    5: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      },
      {
        id: 3,
        isClicked: false
      },
      {
        id: 4,
        isClicked: false
      }
    ],
    3: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      }
    ]
  }
  techSkillRatingScale: any;
  getCompetenceToEvaluateForManager$: Subscription = new Subscription;
  skipObjectiveCheck: boolean = false;
  isLoadingFinalEvaluationData: boolean = false;
  getFinalEvaluationData$: Subscription = new Subscription;
  finalEvaluation: any = {};
  tempStep: any;
  newObjectiveListCopy: any;
  isLoadingUserGoalToDefine: any;

  textAreaActions: any[] = [];
  isScheduleShared: boolean = false;
  editFinalEvaluationComment: boolean = false;
  editBehaviorComment: boolean = false;
  editCompetenceComment: boolean = false;
  objectiveToDeleteFromEdit: boolean | undefined;

  createOrUpdatePerformanceAssessmentGalEvaluationForManager$: any;
  getBehavioursToEvaluateForManager$: any;
  recapSubordinateDevelopmentDescription: any;
  isPostCalibration: boolean = false;
  finalShareDescripton: string = '';
  feedbackCheck: boolean = false;
  finalShareModalDescription: any;
  finalShare$: Subscription = new Subscription;
  isFinalStepCompleted: boolean = false;
  isManagement: boolean = false;
  yearsWithSpecialisticGoalsToEvaluate: boolean = false;
  isLoadingMySubordinateUserData: boolean = false;
  subordinatesWithPhaseStatuses$: any;
  getMySubordinateUserData$: any;
  newObjectiveMessage: string = '';
  @ViewChild('newObjectiveContainer') newObjectiveContainer: any;
  forcePasStructureTutorial: string | null;
  forcePasTutorialStep: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean } = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private peopleAppraisalService: PeopleAppraisalService,
    private router: Router,
    private modalService: ModalService,
    private guidedTour: GuidedTourService,
    public redirectService: RedirectService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "PeopleAppraisal - Scheda utente");
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    this.forcePasStructureTutorial = openPasStructureTutorial || openSetGoalsTutorial || openTechSkillEvaluationTutorial || openOrganizationalBehaviorsTutorial;
  }

  ngOnInit() {
    this.translate.get(
      [
        'accordion.CHALLENGE',
        "modals.CHALLENGE.TITLE",
        "modals.CHALLENGE.SUBTITLE",
        "modals.CHALLENGE.TEXT",
        "modals.ROLE.TITLE",
        "modals.ROLE.TEXT",
        "peopleAppraisalOperations.CARE_TITLE",
        "peopleAppraisalOperations.SHARED_COMPETENCE",
        'peopleAppraisalOperations.SHARED_COMPETENCE',
        "modals.OPERATIONS.TITLE",
        "modals.OPERATIONS.SUBTITLE",
        "modals.OPERATIONS.TEXT",
        'accordion.GUIDELINES_STI',
        "performance.ACCORDION_TITLE",
        "performance.ACCORDION_TOOLTIP",
        "performance.statuses.TO_DEFINE",
        "performance.statuses.TO_CONFIRM",
        "performance.statuses.COMPLETED",
        "calibration.statuses.TO_CONFIRM",
        'finalEvaluation.ASSIGNED_LIST_1',
        'finalEvaluation.ASSIGNED_LIST_2',
        "stepper.OBJECTIVE_EVALUATION",
        "stepper.BEHAVIOUR_EVALUATION",
        "stepper.TECH_SKILL_EVALUATION",
        "stepper.DEFINE_OBJECTIVES",
        "stepper.FINAL_EVALUATION",
        "objectiveEvaluation.MODAL_TITLE",
        "objectiveEvaluation.MODAL_SUB",
        "objectiveEvaluation.MODAL_TEXT",
        "generic.NO_COMMENT_INSERTED",
        "finalEvaluation.FINAL_SHARE_DESCR_1",
        "finalEvaluation.FINAL_SHARE_DESCR_2",
        "stepper.TECH_OBJECTIVES",
        'finalEvaluation.SELF_ASSIGNED_LIST_1',
        'finalEvaluation.SELF_ASSIGNED_LIST_2',
      ])
      .subscribe(translations => {
        this.translations = translations;

        this.textAreaActions = [
          {
            id: 'delete',
            label: "generic.DELETE",
            class: 'secondary'
          },
          {
            id: 'save',
            label: "generic.SAVE",
            class: 'primary'
          }
        ];

        // this.selectedObjective = this.toEvaluateList && this.toEvaluateList.length && this.toEvaluateList[0] || null;

        this.contentTabList = [];

        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear)
          .subscribe((runningYear) => {
            this.runningYear = runningYear;
            if (this.runningYear) {
              this.routeParams$ = this.route.params
                .subscribe(
                  (params: Params) => {
                    // Se ho cambiato utente (dalla sidebar) il componente non entrerà nell'onDestroy; quindi devo eliminare qui ogni referenza all'apertura automatica del tutorial dal session storage
                    if (this.userId && params.userId && this.userId !== params.userId) {
                      this.removeTutorialsRefFromSessionStorage();
                    }
                    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                      if (loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        if (!this.isLoadingMySubordinateUserData) {
                          this.isLoadingMySubordinateUserData = true;
                        }
                      }
                    })

                    this.userId = params.userId;

                    if (params.perfYear) {
                      this.selectedYear = {
                        id: params.perfYear,
                        code: params.perfYearCode,
                        name: params.perfYearCode + ' ' + params.perfYear
                      }
                    } else {
                      this.selectedYear = {
                        id: 2021,
                        name: 'H1 ' + 2021
                      }
                    }

                    this.isNoteSection = window.location.href.indexOf('myNotes') > -1;

                    this.store.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: this.userId }));
                    this.getPerformanceAssessmentYears();
                  }
                )
            }
          });
      })
  }

  getMySubordinateUserData() {
    this.isLoadingMySubordinateUserData = true;
    this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
      subordinatesWithPhaseStatuses;

      if (subordinatesWithPhaseStatuses && subordinatesWithPhaseStatuses.length) {
        if (this.getMySubordinateUserData$) {
          this.getMySubordinateUserData$.unsubscribe();
        }

        this.getMySubordinateUserData$ = this.sharedService.getMySubordinateUserData(subordinatesWithPhaseStatuses[0].userId, this.runningYear.year, this.runningYear.yearCode)
          .subscribe((res: any) => {
            if (res && res.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pn017",
                text: this.translate.instant("errors." + res.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const techYears = res.response && res.response.yearsWithSpecialisticGoalsToEvaluate && res.response.yearsWithSpecialisticGoalsToEvaluate;
              this.isThisSpecialTechYear(techYears);
            }
            this.isLoadingMySubordinateUserData = false;
          }, (err?: any) => {
            this.isLoadingMySubordinateUserData = false;
            const messageObj: ApplicationModalMessage = {
              modalId: "109",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        this.isLoadingMySubordinateUserData = false;
      }
    })
  }

  isThisSpecialTechYear(techYears: any) {
    if (techYears && techYears.length) {
      for (let i = 0, yearsLength = techYears.length; i < yearsLength; i++) {
        const currentSpecialYear = techYears[i];

        if (currentSpecialYear.year && currentSpecialYear.year === this.selectedYear.id.toString() && currentSpecialYear.yearCode === this.selectedYear.code) {
          this.yearsWithSpecialisticGoalsToEvaluate = true;
          break;
        }
      }
    }
  }

  getPersonDetails() {
    this.isLoadingPersonDetails = true;
    this.isLoadingTab = true;

    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.sharedService.getMySubordinateUserData(this.userId, this.selectedYear.id, this.selectedYear.code)
      .subscribe((personDetailsData: any) => {
        if (personDetailsData && personDetailsData.error) {
          this.isLoadingPersonDetails = false;
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.personDetails = personDetailsData.response;
          this.recapDevelopmentDescription = this.translate.instant('finalEvaluation.ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.ASSIGNED_LIST_2');
          this.recapSubordinateDevelopmentDescription = this.translate.instant('finalEvaluation.SELF_ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.SELF_ASSIGNED_LIST_2');
          this.finalShareDescripton = this.translate.instant('finalEvaluation.FINAL_SHARE_DESCR_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.FINAL_SHARE_DESCR_2');
          this.finalShareModalDescription = this.translate.instant('finalEvaluation.CONFIRM_SHARE_DESCR_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.CONFIRM_SHARE_DESCR_2');
          this.newObjectiveMessage = this.translate.instant('finalEvaluation.NOTICE_MESSAGE_1') + this.personDetails.forename + ' ' + this.personDetails.surname + this.translate.instant('finalEvaluation.NOTICE_MESSAGE_2');
          this.isManagement = false;

          this.yearsWithSpecialisticGoalsToEvaluate = false;
          const techYears = personDetailsData.response && personDetailsData.response.yearsWithSpecialisticGoalsToEvaluate && personDetailsData.response.yearsWithSpecialisticGoalsToEvaluate;
          this.isThisSpecialTechYear(techYears);


          if (this.personDetails && this.personDetails.userId) {
            this.recapDevelopmentDescription = this.translate.instant('finalEvaluation.ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.ASSIGNED_LIST_2');
            if (!this.isNoteSection) {
              this.getUserProfileTab();
            } else {
              this.isLoadingTab = false;
              this.getPrivateNotesList();
            }
          } else {
            this.isLoadingTab = false;
          }
          this.isLoadingPersonDetails = false;
        }
      }, (err?: any) => {
        this.isLoadingPersonDetails = false;
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.peopleAppraisalService.getPerformanceAssessmentYears(this.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          for (let i = (data.response.length - 1); i >= 0; i--) {
            this.yearList.push({
              id: data.response[i].assessmentYear,
              code: data.response[i].assessmentYearCode,
              name: data.response[i].assessmentYearCode + ' ' + data.response[i].assessmentYear,
              disabled: !data.response[i].isInProcess
            });
          }
          this.isLoadingYearList = false;

          this.currentStep = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };
          if (this.selectedYear.id != this.runningYear) {
            this.getPersonDetails();
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split(('/' + previous))[0];
    this.router.navigate([url, this.selectedYear.id, this.selectedYear.code])
  }

  // Ritorna le tab con numero massimo obiettivi e peso
  getUserProfileTab(updateOnly?: boolean) {
    if (!updateOnly) {
      this.isLoadingTab = true;
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    this.isScheduleShared = false;
    this.isPostCalibration = false;
    this.getUserProfileTab$ = this.peopleAppraisalService.getUserPhaseDataForManager(this.selectedYear.id, this.selectedYear.code, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && (data.error || !data.response)) {
          this.getUserProfileTab$.unsubscribe();
          if (window.location.href.indexOf('personDetails') > -1) {
            this.redirectService.goToErrorPage(data && data.error);
          }
        } else {
          this.contentTabList = []
          let stepNumber = 1;
          this.stepList = [];
          let tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_EVALUATION)
          if (tempStep) {
            this.stepList.push(
              {
                id: 'objectiveEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 1,
                title: this.translations["stepper.OBJECTIVE_EVALUATION"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED || tempStep.statusType == "SYSTEM_COMPLETED"
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.BEHAVIORS_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'behaviourEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 2,
                title: this.translations["stepper.BEHAVIOUR_EVALUATION"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.COMPETENCES_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            if (this.yearsWithSpecialisticGoalsToEvaluate) {
              this.stepList.push(
                {
                  id: 'techEvaluation',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: 3,
                  title: this.translations["stepper.TECH_OBJECTIVES"],
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            } else {
              this.stepList.push(
                {
                  id: 'techEvaluation',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: 3,
                  title: this.translations["stepper.TECH_SKILL_EVALUATION"],
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            }
          } else {
            this.isManagement = true;
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_DEFINITION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'defineObjectives',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 4,
                title: this.translations["stepper.DEFINE_OBJECTIVES"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.FINAL_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'finalEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 5,
                title: this.translations["stepper.FINAL_EVALUATION"],
                isWaiting: tempStep.statusType == RentStatusTypes.SHARED_FINAL_FORM,
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
            this.isScheduleShared = tempStep.statusType == RentStatusTypes.SHARED_FINAL_FORM;
            this.isPostCalibration = tempStep.statusType == 'RECEIVED_FINAL_FORM'; //TODO aggiorna con common class appena c'è
            this.isFinalStepCompleted = tempStep.statusType == RentStatusTypes.COMPLETED;
          }
          this.hasToOpenTutorial();
          if (!updateOnly && !this.forcePasStructureTutorial) {
            for (let i = 0; i < this.stepList.length; i++) {
              if (!this.stepList[i].isComplete) {
                this.currentStep = this.stepList[i];
                break;
              }
            }
            if (!this.currentStep.stepId) {
              this.currentStep = this.stepList[4];
            }
          } else if (this.forcePasStructureTutorial) {
            // Nel caso del tutorial della valutazione obiettivi bisogna prima caricare i dati, quindi passo direttamente lo step anche se non è stato preselezionato
            if (!this.forcePasTutorialStep.id) {
              this.currentStep = this.stepList[0];
            } else {
              this.currentStep = this.forcePasTutorialStep;
            }
          }
        }
        this.cdr.detectChanges();
        this.isLoadingTab = false;
        this.isLoadingPersonDetails = false;

        if (!updateOnly && !this.forcePasStructureTutorial) {
          this.onStepClicked(this.currentStep, this.forcePasStructureTutorial === "true");
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "112",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
        this.isLoadingPersonDetails = false;
      });
  }

  // Cambio scheda header dossier
  changeButtonHeaderDossier(id: string) {
    switch (id) {
      case 'pas':
        this.router.navigate(['/peopleAppraisal/personDetails/', this.userId, this.selectedYear.id, this.selectedYear.code])
        break;
      case 'notes':
        this.router.navigate(['/peopleAppraisal/personDetails/myNotes/', this.userId, this.selectedYear.id, this.selectedYear.code])
        break;
    }
  }

  // Cambio tab
  onContentTabChanged(tab: TabDossier) {
    this.currentContentTab = tab;
  }

  // Cambio step
  onStepClicked(step: any, skipCheck?: boolean) {
    this.tempStep = step;
    if (!skipCheck && this.currentStep.stepId == 1 && this.anyEvaluationChangesToSave()) {
      this.modalService.open('save-objective-modal');
    } else if (!skipCheck && this.currentStep.stepId == 4 && this.anyDefineObjectiveChangesToSave()) {
      this.modalService.open('save-defined-objective-modal');
    } else {
      this.currentStep = step;
      if (this.currentStep.stepId == 1) {
        this.getUserGoalListToEvaluate();
      } else if (this.currentStep.stepId == 2) {
        this.getBehavioursToEvaluateForManager();
      } else if (this.currentStep.stepId == 3) {
        this.getCompetenceToEvaluateForManager();
      } else if (this.currentStep.stepId == 4) {
        this.getUserGoalListToDefine();
      } else if (this.currentStep.stepId == 5) {
        this.getFinalEvaluationData();
      }
    }
  }

  closeSaveObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveObjectiveEvaluation();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 1);
      }
    }
  }

  openHelpModal(stepId: string) {
    switch (stepId) {
      case 'objectiveEvaluation':
        const messageObjEva: ApplicationModalMessage = {
          modalId: "infoObjEv",
          title: this.translations["objectiveEvaluation.MODAL_TITLE"],
          subtitle: this.translations["objectiveEvaluation.MODAL_SUB"],
          text: this.translations["objectiveEvaluation.MODAL_TEXT"],
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObjEva }));
        break;
      case 'techEvaluation':
        this.modalService.open('competence-dictionary');
        break;
      case 'notes':
        break;
      default:
        break;
    }
  }

  // Da aggioranre passando l'oggetto dell'obiettivo
  onEvaluationChanged(text: string, objective: any) {
    objective.evaluation = text;
  }

  // Salva la valutazione degli obiettivi 
  saveObjectiveEvaluation(isUpdate?: boolean) {
    this.isLoadingToEvaluateGoals = true;
    let evaluationForService: { goalId: string, evaluationText: string }[] = [];
    if (isUpdate) {
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation.trim()
        })
      }
    } else {
      if (this.toEvaluateList && this.toEvaluateList.length) {
        for (let i = 0; i < this.toEvaluateList.length; i++) {
          evaluationForService.push({
            goalId: this.toEvaluateList[i].goalId,
            evaluationText: this.toEvaluateList[i].evaluation?.trim()
          })
        }
      }
    }
    this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
      .subscribe((data: SenecaResponse<boolean>) => {
        this.isLoadingToEvaluateGoals = false;
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab();
        }
      }, (err?: any) => {
        this.isLoadingToEvaluateGoals = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  openCompetenceDictionaryModal(competence: any) {
    this.competenceForModal = competence;
    this.modalService.open("competence-dictionary");
  }

  closeCompetenceDictionaryModal() {
    this.modalService.close("competence-dictionary");
  }

  // generica per i commenti nelle textarea, prende testo e variabile dove salvarlo
  onTextAreaChanged(text: string, object: string) {
    object = text;
  }

  // Definizione obiettivi
  // Aggiungi nuovo obiettivo
  addNewObjective() {
    this.newObjectiveList.push({
      tmpId: this.newObjectiveList.length.toString(),
      id: '',
      title: '',
      isEdit: false
    })
  }

  // salva gli obiettivi aggiunti
  saveDefinedObjective() {
    this.isLoadingDefinedObjectives = true;
    /* for (let i = 0; i < this.newObjectiveList.length; i++) {
       this.newObjectiveList[i].id = 'saved' + (this.newObjectiveList.length + i);
       this.newObjectiveList[i].isEdit = false;
     }*/

    let goals = [];
    for (let i = 0; i < this.newObjectiveList.length; i++) {
      goals.push({
        goalId: this.newObjectiveList[i].id,
        text: this.newObjectiveList[i].title.trim()
      });
    }

    if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
      this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGoalsForManager(this.userId, goals)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (this.newObjectiveList && this.newObjectiveList.length) {
            this.newObjectiveList.length = 0;
          }
          this.updateUserPhaseStatus();
          this.getUserGoalListToDefine();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          // const messageObj = {
          //   modalId: "mt005",
          //   title: this.translate.instant("stepper.DEFINE_OBJECTIVES"),
          //   text: this.translate.instant("stepper.OBJECTIVES_SAVED")
          // }
          // this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingDefinedObjectives = false;
      }, (err?: any) => {
        this.isLoadingDefinedObjectives = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  anyDefineObjectiveChangesToSave() {
    if (!this.isLoadingDefinedObjectives && this.newObjectiveList && this.newObjectiveListCopy) {
      if (this.newObjectiveList.length != this.newObjectiveListCopy.length) {
        return true;
      } else {
        for (let i = 0; i < this.newObjectiveList.length; i++) {
          if (this.newObjectiveList[i].title != this.newObjectiveListCopy[i].title) {
            return true;
          }
        }
      }
    }
    return false;
  }

  closeSaveDefinedObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-defined-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveDefinedObjective();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 4);
      }
    }
  }

  deleteObjective(objective: any) {
    // momentaneo per testare
    if (!objective.id && objective.tmpId) {
      // Se non ha un id, significa che non è ancora stato salvato, quindi mi baso sul tmpId
      this.newObjectiveList = this.newObjectiveList.filter((x: any) => x.tmpId != objective.tmpId);
    } else {
      // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
      if (this.deletePerformanceAssessmentGoalsForManager$) {
        this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
      }
      this.deletePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.deletePerformanceAssessmentGoalsForManager(this.userId, [objective.id])
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (this.newObjectiveList && this.newObjectiveList.length) {
              this.newObjectiveList.length = 0;
            }
            this.showSaveSuccessfulToaster();
            this.getUserProfileTab(true);
            if (this.currentStep.stepId == 5) {
              this.getFinalEvaluationData();
            } else {
              this.getUserGoalListToDefine();
            }
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm004",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  onObjectiveTitleChanged(text: string, objective: any) {
    objective.title = text
  }

  onMenuOptionClicked(option: string, objective: any) {
    if (option == 'edit') {
      objective.isEdit = true;
    } else {
      if (!objective.id && !objective.isEdit) {
        this.deleteObjective(objective);
      } else {
        this.openDeleteSavedObjective(objective);
      }
    }
  }

  openDeleteSavedObjective(objective: any, fromEdit?: boolean) {
    this.objectiveToDelete = objective;
    this.objectiveToDeleteFromEdit = fromEdit;
    this.modalService.open('confirm-delete-saved-objective');
  }

  closeDeleteSavedObjective(confirm?: boolean) {
    this.modalService.close('confirm-delete-saved-objective');
    if (confirm) {
      this.isLoadingDefinedObjectives = true;
      this.deleteObjective(this.objectiveToDelete);
      this.objectiveToDelete = null;
      setTimeout(() => { this.isLoadingDefinedObjectives = false; }, 2000);
    }
  }

  // Apre la modale con la spiegazione dei performer
  openPerformerModal(performerKey?: string) {
    this.selectedInfoTabPerformerKey = performerKey;
    this.modalService.open("performerEvaluatorInfoModal");
  }

  // Chiude la modale con la spiegazione dei performer
  closePerformerModal() {
    this.modalService.close("performerEvaluatorInfoModal");
    this.selectedInfoTabPerformerKey = '';
  }

  selectObjectiveFinalEvaluation(objective: any) {
    this.selectedObjective = objective;
  }


  getPrivateNotesList() {
    this.isLoadingPrivateNotes = true;
    this.privateNotesList = [];

    if (this.getPrivateNotesList$) {
      this.getPrivateNotesList$.unsubscribe();
    }

    this.getPrivateNotesList$ = this.peopleAppraisalService.getPrivateNotesList(this.runningYear.year, this.runningYear.yearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          for (let i = 0; i < data.response.length; i++) {
            this.privateNotesList.push({
              noteId: data.response[i].itemId,
              comment: data.response[i].comment,
              motivation: data.response[i].motivation,
              formattedCreationDate: moment(data.response[i].creationDate).fromNow()
            })
          }
        }
        this.isLoadingPrivateNotes = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPrivateNotes = false;
        })
  }

  openDeleteNoteModal(id: string) {
    this.noteIdToDelete = id;
    this.modalService.open('deleteNoteModal');
  }

  closeDeleteNoteModal(confirmDelete?: boolean) {
    this.modalService.close('deleteNoteModal');
    if (confirmDelete) {
      this.isLoadingPrivateNotes = true;
      this.peopleAppraisalService.deletePrivateNote(this.runningYear.year, this.runningYear.yearCode, this.noteIdToDelete, this.userId)
        .subscribe((data: SenecaResponse<DeletePrivateNoteFeedbackForManagerResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn023",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn026",
              title: this.translate.instant("privateNotes.modals.NOTE_DELETED"),
              text: this.translate.instant("privateNotes.modals.NOTE_DELETED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getPrivateNotesList();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn024",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  goToEditPrivateNote(noteId: string) {
    this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, noteId);
  }


  createPrivateNote() {
    this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, '');
  }

  getEvaluationYear() {
    if (this.selectedYear.code == PerfAlloyYearCodes.H1) {
      return {
        year: this.selectedYear.id - 1,
        code: PerfAlloyYearCodes.H2
      }
    } else {
      return {
        year: this.selectedYear.id,
        code: PerfAlloyYearCodes.H1
      }
    }
  }

  getUserGoalListToEvaluate() {
    this.isLoadingToEvaluateGoals = true;
    if (this.getUserGoalToEvaluate$) {
      this.getUserGoalToEvaluate$.unsubscribe();
    }
    let serviceYear = this.getEvaluationYear();
    this.getUserGoalToEvaluate$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId])
      .subscribe((data: SenecaResponse<PerfAlloyUserGoal[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        } else {
          this.toEvaluateList = [];

          for (let i = 0; i < data.response.length; i++) {
            this.toEvaluateList.push({
              ...data.response[i],
              isSelected: false
            })
          }
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          if (this.currentStep.isComplete == true) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          }
          this.isLoadingToEvaluateGoals = false;
        }
        // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
        this.hasToOpenTutorial(true);
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  anyEvaluationChangesToSave() {
    if (this.toEvaluateList && this.toEvaluateList.length && this.toEvaluateListCopy.length) {
      for (let i = 0; i < this.toEvaluateList.length; i++) {
        if (this.toEvaluateList[i].evaluation != this.toEvaluateListCopy[i].evaluation) {
          return true;
        }
      }
    }
    return false;
  }

  // Recupera gli obiettivi da definire
  getUserGoalListToDefine() {
    this.isLoadingUserGoalToDefine = true;

    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }

    this.newObjectiveList = [];

    this.getUserGoalToDefine$ = this.peopleAppraisalService.getUserGoalList(this.selectedYear.id, this.selectedYear.code, [this.userId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          for (let m = 0, resLength = data.response.length; m < resLength; m++) {
            const currentRes = data.response[m];
            this.newObjectiveList.push({
              id: currentRes.goalId,
              title: currentRes.text
            });
          }
        }
        this.hasToOpenTutorial();
        this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
        if (this.currentStep.isComplete == true) {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
        this.isLoadingUserGoalToDefine = false;
      }, (err?: any) => {
        this.isLoadingUserGoalToDefine = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm008",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  getBehavioursToEvaluateForManager() {
    this.isLoadingBehaviours = true;
    this.getBehavioursToEvaluateForManager$ = this.peopleAppraisalService.getBehavioursToEvaluateForManager(this.selectedYear.id, this.selectedYear.code, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let parentBehaviours = data.response.behaviors.map((x: any) => {
            return {
              behaviorId: x.parentBehavior.behaviorId,
              title: x.parentBehavior.text
            }
          })
          this.parentBehaviourIds = parentBehaviours.map((item: any) => item.behaviorId)
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
          let tempBehaviour: any = {};
          for (let i = 0; i < this.parentBehaviourIds.length; i++) {
            for (let j = 0; j < data.response.behaviors.length; j++) {
              let behavior = data.response.behaviors[j];
              if (behavior.parentBehavior.behaviorId == this.parentBehaviourIds[i]) {
                if (tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && !tempBehaviour[this.parentBehaviourIds[i]].includes(behavior)) {
                  tempBehaviour[this.parentBehaviourIds[i]].push(behavior);
                } else if (!tempBehaviour[this.parentBehaviourIds[i]] || !tempBehaviour[this.parentBehaviourIds[i]].length) {
                  tempBehaviour[this.parentBehaviourIds[i]] = [behavior];
                }
              }
            }
          }
          this.competenceArray = [];
          for (let i = 0; i < this.parentBehaviourIds.length; i++) {
            let parent = tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && tempBehaviour[this.parentBehaviourIds[i]][0].parentBehavior;
            let tempCompetence = [];
            if (this.isPostCalibration || this.isFinalStepCompleted) {
              for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
                tempCompetence.push({
                  ...tempBehaviour[this.parentBehaviourIds[i]][j],
                  rating: this.getBehaviourRating(tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length),
                  selfRating: this.getBehaviourRating(tempBehaviour[this.parentBehaviourIds[i]][j].colleagueEvaluationRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length)
                })
              }
            } else {
              for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
                tempCompetence.push({
                  ...tempBehaviour[this.parentBehaviourIds[i]][j],
                  rating: this.getBehaviourRating(tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length),
                })
              }
            }
            this.competenceArray.push({
              category: parent.text,
              competences: tempCompetence,
              ratingScale: tempCompetence[0].evaluationScale.length || 5
            })
          }
          this.manageBehaviorComments = data.response && data.response.overallComment || '';
          if (this.manageBehaviorComments) {
            this.manageBehaviorCommentsCopy = JSON.parse(JSON.stringify(this.manageBehaviorComments));
          }
        }
        this.isLoadingBehaviours = false;

        // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
        this.hasToOpenTutorial();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  // Controlla se aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
  hasToOpenTutorial(checkPersonalObjectiveEvaluationTutorial?: boolean) {
    this.checkPassStructureTutorial();
    if (checkPersonalObjectiveEvaluationTutorial) {
      this.checkPersonalObjectiveEvaluationTutorial();
    }
    this.checkOrganizationalBehaviorsTutorial();
    this.checkTechSkillEvaluationTutorial();
    this.checkSetGoalsTutorial();
  }

  // inizializza il rating dei comportamenti 
  getBehaviourRating(rating: number, ratingScale: number) {
    let starRating = JSON.parse(JSON.stringify(this.starsModel[ratingScale]));
    for (let i = 0; i < rating; i++) {
      starRating[i].isClicked = true;
    }
    return starRating;
  }

  // Aggiornamento valutazione comportamento
  behaviourRatingChanged(competence: any) {
    let behaviorEvaluation: { behaviorId: string, evaluationScore: string };
    let score = 0;
    for (let i = 0; i < competence.rating.length; i++) {
      if (competence.rating[i].isClicked) {
        score = competence.rating[i].id + 1;
      }
    }
    behaviorEvaluation = { behaviorId: competence.behaviorId, evaluationScore: score.toString() };
    let comment;
    if (this.manageBehaviorComments != this.manageBehaviorCommentsCopy) {
      comment = this.manageBehaviorComments;
    }

    this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, behaviorEvaluation, comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  onManageBehaviorCommentChanged(text: string) {
    this.manageBehaviorComments = text
  }

  getCompetenceToEvaluateForManager() {
    this.isLoadingTechSkills = true;

    if (this.getCompetenceToEvaluateForManager$) {
      this.getCompetenceToEvaluateForManager$.unsubscribe();
    }

    this.getCompetenceToEvaluateForManager$ = this.peopleAppraisalService.getCompetenceToEvaluateForManager(this.selectedYear.id, this.selectedYear.code, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.techSkills.competences = []
          if (this.isPostCalibration || this.isFinalStepCompleted) {
            for (let j = 0; j < data.response.competences.length; j++) {
              this.techSkills.competences.push({
                ...data.response.competences[j],
                rating: this.getBehaviourRating(data.response.competences[j].evaluationRating, data.response.competences[j].evaluationScale.length),
                selfRating: this.getBehaviourRating(data.response.competences[j].colleagueEvaluationRating, data.response.competences[j].evaluationScale.length)
              })
            }
          } else {
            for (let j = 0; j < data.response.competences.length; j++) {
              this.techSkills.competences.push({
                ...data.response.competences[j],
                rating: this.getBehaviourRating(data.response.competences[j].evaluationRating, data.response.competences[j].evaluationScale.length),
              })
            }
          }
          this.competenceForModal = {};
          this.competenceForModal.evaluationScale = data.response.competences[0].evaluationScale;
          this.techSkillRatingScale = (this.techSkills.competences && this.techSkills.competences[0] && this.techSkills.competences[0].evaluationScale.length) || 5;
          this.techSkillComment = data.response.overallComment || '';
          if (this.isFinalStepCompleted && !this.techSkillComment) {
            this.techSkillComment = this.translations['generic.NO_COMMENT_INSERTED']
          }
          this.techSkillCommentCopy = JSON.parse(JSON.stringify(this.techSkillComment));
        }
        this.isLoadingTechSkills = false;
        this.hasToOpenTutorial();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTechSkills = false;
      });
  }

  onTechSkillCommentChanged(text: string) {
    this.techSkillComment = text
  }

  // Aggiornamento valutazione comportamento
  techSkillRatingChanged(competence: any) {
    let competenceEvaluation: { competenceId: string, evaluationScore: number };
    let score = 0;
    for (let i = 0; i < competence.rating.length; i++) {
      if (competence.rating[i].isClicked) {
        score = competence.rating[i].id + 1;
      }
    }
    competenceEvaluation = { competenceId: competence.competenceId, evaluationScore: score };
    let comment;
    if (this.techSkillComment != this.techSkillCommentCopy) {
      comment = this.techSkillComment;
    }

    this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, [competenceEvaluation], comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  showSaveSuccessfulToaster() {
    let x: any = document.getElementById("snackbar");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }

  checkPassStructureTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    if (forcePasStructureTutorial) {
      this.forcePasTutorialStep = this.stepList[0];
      this.openPasStructureTutorial();
    }
  }

  checkPersonalObjectiveEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');
    if (forcePasStructureTutorial) {
      if (!this.currentStep.id && this.stepList && this.stepList[0]) {
        this.forcePasTutorialStep = this.stepList[0];
        this.onStepClicked(this.stepList[0], true);
      } else {
        this.openPersonalObjectiveEvaluationTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openPersonalObjectiveEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.personObjectiveEvaluation.1.TITLE',
        'tutorials.personObjectiveEvaluation.1.DESC',
        'tutorials.personObjectiveEvaluation.2.TITLE',
        'tutorials.personObjectiveEvaluation.2.DESC',
        'tutorials.personObjectiveEvaluation.3.TITLE',
        'tutorials.personObjectiveEvaluation.3.DESC',
        'tutorials.personObjectiveEvaluation.4.TITLE',
        'tutorials.personObjectiveEvaluation.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#firstStepPageHeader',
          /** Titolo */
          title: translations['tutorials.personObjectiveEvaluation.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.personObjectiveEvaluation.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.toEvaluateList && this.toEvaluateList.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-title',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.2.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-description',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#saveGoalToEvaluateBtn',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'PERSONAL OBJECTIVE EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        // aggiunto il timeout altrimenti partiva prima che terminasse il loader dei dati
        setTimeout(() => this.guidedTour.startTour(intro), 500);
      });
  }

  checkOrganizationalBehaviorsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 2 && this.stepList && this.stepList[1]) {
        this.forcePasTutorialStep = this.stepList[1];
        this.onStepClicked(this.stepList[1], forcePasStructureTutorial === "true");
      } else {
        this.openOrganizationalBehaviorsTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openOrganizationalBehaviorsTutorial() {
    this.translate.get(
      [
        'tutorials.organizationalBehaviors.1.TITLE',
        'tutorials.organizationalBehaviors.1.DESC',
        'tutorials.organizationalBehaviors.2.TITLE',
        'tutorials.organizationalBehaviors.2.DESC',
        'tutorials.organizationalBehaviors.3.TITLE',
        'tutorials.organizationalBehaviors.3.DESC',
        'tutorials.organizationalBehaviors.4.TITLE',
        'tutorials.organizationalBehaviors.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#secondStepPageHeader',
          /** Titolo */
          title: translations['tutorials.organizationalBehaviors.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.organizationalBehaviors.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.competenceArray && this.competenceArray.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.2.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceEvaluationNotes',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'ORGANIZATIONAL BEHAVIOURS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  checkTechSkillEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 3 && this.stepList && this.stepList[2]) {
        this.forcePasTutorialStep = this.stepList[2];
        this.onStepClicked(this.stepList[2], forcePasStructureTutorial === "true");
      } else {
        this.openTechSkillEvaluationTutorial();
      }
    }
  }

  checkSetGoalsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 4 && this.stepList && (this.stepList.length > 4 ? this.stepList[3] : this.stepList[2])) {
        if (this.stepList.length > 4) {
          this.forcePasTutorialStep = this.stepList[3];
        } else {
          this.forcePasTutorialStep = this.stepList[2];
        }
        this.onStepClicked(this.forcePasTutorialStep, forcePasStructureTutorial === "true");
      } else {
        this.openSetGoalsTutorial();
      }
    }
  }

  // Apre il tutorial delle competenze tecniche
  openSetGoalsTutorial() {
    this.translate.get(
      [
        'tutorials.setGoals.1.TITLE',
        'tutorials.setGoals.1.DESC',
        'tutorials.setGoals.2.TITLE',
        'tutorials.setGoals.2.DESC',
        'tutorials.setGoals.3.TITLE',
        'tutorials.setGoals.3.DESC',
        'tutorials.setGoals.4.TITLE',
        'tutorials.setGoals.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#fourthStepPageHeader',
          /** Titolo */
          title: translations['tutorials.setGoals.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#addGoalBtn',
          /** Titolo */
          title: translations['tutorials.setGoals.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.2.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#saveGoalBtn',
          /** Titolo */
          title: translations['tutorials.setGoals.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.3.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'SET GOALS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial delle competenze tecniche
  openTechSkillEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.techSkillEvaluation.1.TITLE',
        'tutorials.techSkillEvaluation.1.DESC',
        'tutorials.techSkillEvaluation.2.TITLE',
        'tutorials.techSkillEvaluation.2.DESC',
        'tutorials.techSkillEvaluation.3.TITLE',
        'tutorials.techSkillEvaluation.3.DESC',
        'tutorials.techSkillEvaluation.4.TITLE',
        'tutorials.techSkillEvaluation.4.DESC',
        'tutorials.otsEvaluation.1.TITLE',
        'tutorials.otsEvaluation.1.DESC',
        'tutorials.otsEvaluation.2.TITLE',
        'tutorials.otsEvaluation.2.DESC',
        'tutorials.otsEvaluation.3.TITLE',
        'tutorials.otsEvaluation.3.DESC',
        'tutorials.otsEvaluation.4.TITLE',
        'tutorials.otsEvaluation.4.DESC',
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#thirdStepPageHeader',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.TITLE'] : translations['tutorials.techSkillEvaluation.1.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.DESC'] : translations['tutorials.techSkillEvaluation.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.techSkills && this.techSkills.competences && this.techSkills.competences.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.TITLE'] : translations['tutorials.techSkillEvaluation.2.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.DESC'] : translations['tutorials.techSkillEvaluation.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.TITLE'] : translations['tutorials.techSkillEvaluation.3.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.DESC'] : translations['tutorials.techSkillEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#techEvaluationNotes',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.TITLE'] : translations['tutorials.techSkillEvaluation.4.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.DESC'] : translations['tutorials.techSkillEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'TECH SKILL EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial della struttura PAS
  openPasStructureTutorial() {
    this.translate.get(
      [
        'tutorials.pasStructure.1.TITLE',
        'tutorials.pasStructure.1.DESC',
        'tutorials.pasStructure.2.TITLE',
        'tutorials.pasStructure.2.DESC',
        'tutorials.pasStructure.3.TITLE',
        'tutorials.pasStructure.3.DESC',
        'tutorials.pasStructure.4.TITLE',
        'tutorials.pasStructure.4.DESC',
        'tutorials.pasStructure.5.TITLE',
        'tutorials.pasStructure.5.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#userAvatar',
          /** Titolo */
          title: translations['tutorials.pasStructure.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPasStructureTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#takeNoteBtn',
          /** Titolo */
          title: translations['tutorials.pasStructure.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.2.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#pas',
          /** Titolo */
          title: translations['tutorials.pasStructure.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.3.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#notes',
          /** Titolo */
          title: translations['tutorials.pasStructure.4.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.4.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#stepper',
          /** Titolo */
          title: translations['tutorials.pasStructure.5.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.5.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 25
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'PAS STRUCTURE TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPasStructureTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }


  anyEmptyEvaluation() {
    if (this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        if (!this.finalEvaluation.evaluatedData[i].evaluation || !this.finalEvaluation.evaluatedData[i].evaluation.length) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  getFinalEvaluationData() {
    this.isLoadingFinalEvaluationData = true;

    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe();
    }

    this.getFinalEvaluationData$ = this.peopleAppraisalService.getPerformanceAssessmentFinalEvaluationDataForManager(this.selectedYear.id, this.selectedYear.code, [this.userId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        } else {
          this.finalEvaluation = {};
          this.finalEvaluation.evaluatedData = [];
          // valutazione obiettivi
          if (data.response.evaluatedManagerUserGoals && data.response.evaluatedManagerUserGoals.length) {
            const evaluated = data.response.evaluatedManagerUserGoals;
            const subordinateEvaluated = data.response.evaluatedSubordinateUserGoals;
            for (let i = 0; i < evaluated.length; i++) {
              let subordinateEvaluation = null;
              if (subordinateEvaluated && subordinateEvaluated.length) {
                const subordinateObjective = subordinateEvaluated.find((goal: any) => goal.goalId == evaluated[i].goalId);
                subordinateEvaluation = subordinateObjective.evaluation || this.translations['generic.NO_COMMENT_INSERTED'];
              }
              this.finalEvaluation.evaluatedData.push({
                ...evaluated[i],
                selfEvaluation: subordinateEvaluation,
                isSelected: false
              })
            }
            if (this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
              this.selectedObjective = this.finalEvaluation.evaluatedData[0];
            }
          }
          this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();

          // valutazione comporamenti
          this.finalEvaluation.behaviorData = {};
          if (data.response.managerBehaviorEvaluation) {
            this.finalEvaluation.behaviorData.comment = data.response.managerBehaviorEvaluation.overallComment;
            this.finalEvaluation.behaviorData.rating = data.response.managerBehaviorEvaluation.stars;
            this.finalEvaluation.behaviorData.ratingValue = (Math.round(data.response.managerBehaviorEvaluation.averageBehaviorManagerEvaluation * 100) / 100).toFixed(2);;
          }
          if (data.response.subordinateBehaviorEvaluation) {
            this.finalEvaluation.behaviorData.selfComment = data.response.subordinateBehaviorEvaluation.overallComment;
            this.finalEvaluation.behaviorData.selfRating = data.response.subordinateBehaviorEvaluation.stars;
            this.finalEvaluation.behaviorData.selfRatingValue = (Math.round(data.response.subordinateBehaviorEvaluation.averageBehaviorManagerEvaluation * 100) / 100).toFixed(2);
          }
          // valutazione competenze
          this.finalEvaluation.competenceData = {};
          if (data.response.managerCompetenceEvaluation) {
            this.finalEvaluation.competenceData.comment = data.response.managerCompetenceEvaluation.overallComment;
            this.finalEvaluation.competenceData.rating = data.response.managerCompetenceEvaluation.stars;
            this.finalEvaluation.competenceData.ratingValue = (Math.round(data.response.managerCompetenceEvaluation.averageCompetenceManagerEvaluation * 100) / 100).toFixed(2);
          }
          if (data.response.subordinateCompetenceEvaluation) {
            this.finalEvaluation.competenceData.selfComment = data.response.subordinateCompetenceEvaluation.overallComment;
            this.finalEvaluation.competenceData.selfRating = data.response.subordinateCompetenceEvaluation.stars;
            this.finalEvaluation.competenceData.selfRatingValue = (Math.round(data.response.subordinateCompetenceEvaluation.averageCompetenceManagerEvaluation * 100) / 100).toFixed(2);
          }
          // Commento finale
          this.finalEvaluation.comment = data.response.finalEvaluationManagerComment || '';
          if (this.isPostCalibration || this.isFinalStepCompleted) {
            this.finalEvaluation.selfComment = data.response.finalEvaluationSubordinateComment || this.translations['generic.NO_COMMENT_INSERTED'];
            this.finalEvaluation.adminComment = data.response.finalEvaluationAdminComment;
          }

          if ((this.isScheduleShared || this.isFinalStepCompleted) && !this.finalEvaluation.comment) {
            this.finalEvaluation.comment = this.translations['generic.NO_COMMENT_INSERTED'];
          }
          if ((this.isScheduleShared || this.isFinalStepCompleted) && !this.finalEvaluation.comment) {
            this.finalEvaluation.selfComment = this.translations['generic.NO_COMMENT_INSERTED'];
          }

          // definizione obiettivi
          this.finalEvaluation.definedObjectives = [];
          if (data.response.definedManagerUserGoals && data.response.definedManagerUserGoals.length) {
            let defined = data.response.definedManagerUserGoals;
            for (let m = 0, resLength = defined.length; m < resLength; m++) {
              const currentRes = defined[m];
              this.finalEvaluation.definedObjectives.push({
                id: currentRes.goalId,
                title: currentRes.text
              });
            }
          }
          // obiettivi definiti dal collaboratore 
          if (!this.isFinalStepCompleted) {
            this.finalEvaluation.selfDefinedObjectives = [];
            if (data.response.definedSubordinateUserGoals && data.response.definedSubordinateUserGoals.length) {
              let defined = data.response.definedSubordinateUserGoals;
              for (let m = 0, resLength = defined.length; m < resLength; m++) {
                const currentRes = defined[m];
                this.finalEvaluation.selfDefinedObjectives.push({
                  id: currentRes.goalId,
                  title: currentRes.text,
                  goalToBeApproved: currentRes.goalToBeApproved
                });
              }
            }
          }
          // Se il processo del semestre è stato completato raggruppo tutti gli obiettivi definiti
          if (this.isFinalStepCompleted) {
            if (data.response.definedSubordinateUserGoals && data.response.definedSubordinateUserGoals.length) {
              let defined = data.response.definedSubordinateUserGoals;
              for (let m = 0, resLength = defined.length; m < resLength; m++) {
                const currentRes = defined[m];
                this.finalEvaluation.definedObjectives.push({
                  id: currentRes.goalId,
                  title: currentRes.text
                });
              }
            }
          }

          // Pas performer
          this.finalEvaluation.pasPerformer = {};
          if (data.response.pasAvgEvaluation) {
            this.finalEvaluation.pasPerformer.value = (Math.round(data.response.pasAvgEvaluation * 100) / 100).toFixed(2);
          } else {
            this.finalEvaluation.pasPerformer.value = 0;
          }
        }
        this.isLoadingFinalEvaluationData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  onFinalEvaluationCommentChanged(text: string) {
    this.finalEvaluation.comment = text
  }


  sendFinalEvaluation() {
    this.isLoadingFinalEvaluationData = true;
    this.peopleAppraisalService.sendFinalEvaluationFormForManager(this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "fe001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab();
        }
      })
  }

  updateUserPhaseStatus() {
    this.subordinatesService.getSubordinatesForManager(this.selectedYear.id, this.selectedYear.code, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.store.dispatch(PeopleAppraisalActions.UpdateSelfAssessmentUserStatusType({
            payload: {
              userId: this.userId,
              userStatus: data.response[0].status
            }
          }));
        }
      })
  }

  manageFinalEvaluationAction(id: string) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    } else if (id == 'delete') {
      this.finalEvaluation.comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, null)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em002",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    }
  }

  onEditFinalEvaluationComment() {
    this.editFinalEvaluationComment = !this.editFinalEvaluationComment;
  }

  onEditBehaviourComment() {
    this.editBehaviorComment = !this.editBehaviorComment;
  }

  onBehaviorCommentEdited(text: string) {
    this.finalEvaluation.behaviorData.comment = text;
  }


  onEditCompetenceComment(text: string) {
    this.editCompetenceComment = !this.editCompetenceComment;
  }

  onCompetenceCommentEdited(text: string) {
    this.finalEvaluation.competenceData.comment = text;
  }


  manageBehaviorEvaluationAction(id: string, comment?: string, isFinal?: boolean) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            this.showSaveSuccessfulToaster();
          }
          this.editBehaviorComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.behaviorData.comment = '';
      } else {
        this.manageBehaviorComments = '';
      }
      comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, null)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editBehaviorComment = false;
        });
    }
  }

  manageCompetenceEvaluationAction(id: string, comment?: string, isFinal?: boolean) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            this.showSaveSuccessfulToaster();
          }
          this.editCompetenceComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.competenceData.comment = '';
      } else {
        this.techSkillComment = '';
      }
      comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, null)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em006",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editCompetenceComment = false;
        })
    }
  }


  editCurrentObjective(objective: any) {
    objective.editObjectiveEvaluation = !objective.editObjectiveEvaluation;
  }

  onObjectiveEvaluationEdited(text: string) {
    this.selectedObjective.evaluation = text;
  }

  manageObjectiveEvaluationAction(id: string) {
    if (id == 'save') {
      let evaluationForService: { goalId: string, evaluationText: string }[] = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
        })
      }
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em007",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    } else if (id == 'delete') {
      this.selectedObjective.evaluation = null;
      let evaluationForService: { goalId: string, evaluationText: string }[] = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
        })
      }
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em008",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.selectedObjective.evaluation = '';
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    }
  }

  onEditMenuOptionClicked(option: string, objective: any) {
    if (option == 'edit') {
      objective.isEdit = true;
    } else {
      this.openDeleteSavedObjective(objective);
    }
  }

  // aggiorna obiettivi da valutazione finale 
  manageDefineObjectiveActions(id: string, objective?: any) {
    if (id == 'save') {
      let goals = [];
      for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
        goals.push({
          goalId: this.finalEvaluation.definedObjectives[i].id,
          text: this.finalEvaluation.definedObjectives[i].title
        });
      }

      if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
        this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
      }
      this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGoalsForManager(this.userId, goals)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
            this.getFinalEvaluationData();
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    } else if (id == 'delete') {
      this.openDeleteSavedObjective(objective, true);
    }
  }

  // Elimina ogni riferimento al tutorial dal session storage
  removeTutorialsRefFromSessionStorage() {
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');

    if (openPasStructureTutorial) {
      sessionStorage.removeItem('openPasStructureTutorial');
    }

    const openPersonalObjectiveEvaluationTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');

    if (openPersonalObjectiveEvaluationTutorial) {
      sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
    }

    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');

    if (openOrganizationalBehaviorsTutorial) {
      sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
    }

    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');

    if (openTechSkillEvaluationTutorial) {
      sessionStorage.removeItem('openTechSkillEvaluationTutorial');
    }

    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');

    if (openSetGoalsTutorial) {
      sessionStorage.removeItem('openSetGoalsTutorial');
    }
  }

  allStepCompleted() {
    if (this.isManagement) {
      for (let i = 0; i < 3; i++) {
        if (!this.stepList[i].isComplete) {
          return false;
        }
      }
      return true;
    } else {
      for (let i = 0; i < 4; i++) {
        if (!this.stepList[i].isComplete) {
          return false;
        }
      }
      return true;
    }
  }

  canSaveDefinedObjectives() {
    if (this.newObjectiveList && this.newObjectiveList.length) {
      for (let i = 0; i < this.newObjectiveList.length; i++) {
        if (!this.newObjectiveList[i].title || !this.newObjectiveList[i].title.trim().length) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  openSendFinalEvaluation() {
    this.modalService.open('send-hr-schedule')
  }

  closeSendFinalEvaluation(confirm?: boolean) {
    this.modalService.close('send-hr-schedule');
    if (confirm) {
      this.isLoadingFinalEvaluationData = true;
      if (this.finalEvaluation.comment && this.finalEvaluation.comment.length) {
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              this.sendFinalEvaluation();
            }
            this.editFinalEvaluationComment = false;
          })
      } else {
        this.sendFinalEvaluation();
      }
    }
  }

  getYearName() {
    let yearName = '';
    if (this.selectedYear.code == 'H1') {
      yearName = 'H2 ' + this.selectedYear.id
    } else {
      yearName = 'H1 ' + (parseInt(this.selectedYear.id) + 1);
    }
    return yearName;
  }

  openFinalShareModal() {
    if (this.isLoadingFinalEvaluationData || !this.feedbackCheck || !this.allStepCompleted() || this.checkNewObjectives()) {
      this.modalService.open('cannot-share-modal');
    } else {
      this.modalService.open('final-share-modal');
    }
  }

  closeFinalShareModal(confirm?: boolean) {
    this.modalService.close('final-share-modal');
    if (confirm) {
      this.finalShare();
    }
  }

  closeCannotShareModal() {
    this.modalService.close('cannot-share-modal');
  }

  onFeedbackCheck() {
    this.feedbackCheck = !this.feedbackCheck;
  }


  // salva le modifiche agli obiettivi aggiunti self assessment
  saveSubordinateDefinedObjective() {
    this.isLoadingDefinedObjectives = true;
    /* for (let i = 0; i < this.newObjectiveList.length; i++) {
       this.newObjectiveList[i].id = 'saved' + (this.newObjectiveList.length + i);
       this.newObjectiveList[i].isEdit = false;
     }*/

    let goals = [];
    for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
      goals.push({
        goalId: this.finalEvaluation.selfDefinedObjectives[i].id,
        text: this.finalEvaluation.selfDefinedObjectives[i].title
      });
    }

    if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
      this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.updatePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goals)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab();
        }
        this.isLoadingDefinedObjectives = false;
      }, (err?: any) => {
        this.isLoadingDefinedObjectives = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  onSubordinateEditMenuOptionClicked(option: string, objective: any) {
    if (option == 'approve') {
      this.manageSubordinateDefineObjectiveActions('save', objective);
    } else if (option == 'edit') {
      objective.isEdit = true;
    } else {
      this.openSubordinateDeleteSavedObjective(objective);
    }
  }

  // aggiorna obiettivi da valutazione finale 
  manageSubordinateDefineObjectiveActions(id: string, objective: any) {
    if (id == 'save') {
      let goals = [
        {
          goalId: objective.id,
          text: objective.title
        }
      ];
      // for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
      //   goals.push({
      //     goalId: this.finalEvaluation.selfDefinedObjectives[i].id,
      //     text: this.finalEvaluation.selfDefinedObjectives[i].title
      //   });
      // }

      if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
        this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
      }
      this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.updatePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goals)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
            this.getFinalEvaluationData();
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    } else if (id == 'delete') {
      this.openSubordinateDeleteSavedObjective(objective);
    }
  }

  openSubordinateDeleteSavedObjective(objective: any, fromEdit?: boolean) {
    this.objectiveToDelete = objective;
    this.objectiveToDeleteFromEdit = fromEdit;
    this.modalService.open('confirm-delete-subordinate-saved-objective');
  }

  closeSubordinateDeleteSavedObjective(confirm?: boolean) {
    this.modalService.close('confirm-delete-subordinate-saved-objective');
    if (confirm) {
      this.deleteSubordinateObjective(this.objectiveToDelete);
      this.objectiveToDelete = null;
    }
  }


  deleteSubordinateObjective(objective: any) {
    // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    this.deletePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.deletePerformanceAssessmentGoalsBySubordinateForManager(this.userId, [objective.id])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.getFinalEvaluationData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm004",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }


  finalShare() {
    this.isLoadingFinalEvaluationData = true;
    if (this.finalShare$) {
      this.finalShare$.unsubscribe();
    }

    this.finalShare$ = this.peopleAppraisalService.sharePostCalibrationForManager(this.userId, this.feedbackCheck)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "fs001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFinalEvaluationData = false;
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.getFinalEvaluationData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "fs003",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFinalEvaluationData = false;
      });
  }

  // Controllo messaggio obiettivi
  checkNewObjectives() {
    if (this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length) {
      let anyNew = this.finalEvaluation.selfDefinedObjectives.find((x: any) => x.goalToBeApproved);
      if (anyNew && anyNew.id) {
        return true;
      }
    }
    return false;
  }

  scrollToNewObjective() {
    this.newObjectiveContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  ngOnDestroy(): void {
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.personDetails$) {
      this.personDetails$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }
    if (this.getPrivateNotesList$) {
      this.getPrivateNotesList$.unsubscribe();
    }
    if (this.getUserGoalToEvaluate$) {
      this.getUserGoalToEvaluate$.unsubscribe();
    }
    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
      this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$) {
      this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$.unsubscribe();
    }
    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$) {
      this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$.unsubscribe();
    }
    if (this.getBehavioursToEvaluateForManager$) {
      this.getBehavioursToEvaluateForManager$.unsubscribe();
    }
    if (this.finalShare$) {
      this.finalShare$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.getMySubordinateUserData$) {
      this.getMySubordinateUserData$.unsubscribe();
    }

    this.removeTutorialsRefFromSessionStorage();
  }
}