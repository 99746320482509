<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container
    *ngIf="!isLoadingYear && (!processYearSelected || (processYearSelected && tabList && tabList.length > 0 && currentContentTab && currentContentTab.id))">
    <header-dossier [isAdminHeader]="true" [hasTabs]="false" [adminPageTitle]="'setupCompetences.TITLE' | translate"
      [adminPageSubtitle]="processYear && processYear.id ? (('setupCompetences.PAS' | translate) + processYear.code + ' ' + processYear.id) : ('setupCompetences.PROCESS_LIST' | translate)"
      [adminBack]="true" [hasTabs]="processYearSelected" [menuButtons]="tabList" [customTabList]="true"
      (onMenuButtonClicked)="onContentTabChanged($event)" [currentMenuButton]="currentContentTab"
      [adminActionLabel]="processYear && processYear.id ? ('dashboard.DOWNLOAD_REPORT' | translate) : ''"
      (onAdminAction)="downloadReport()" adminActionLabelType="success" [adminActionDisabled]="isDownloadingReport">
    </header-dossier>
  </ng-container>

  <!-- Home lista processi -->
  <ng-container *ngIf="!processYearSelected">
    <div class="main-content-wrapper admin">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingProcessData">
        <div class="admin-table-container">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.SEMESTER' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                  <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of processData.list | 
                              slice:0:processData.numRecords | 
                              paginate: { id: 'processList',
                                          itemsPerPage: processData.numRecords,
                                          currentPage: processData.page,
                                          totalItems: processData.counter }">
                  <td>{{ process.processYearCode + ' ' + process.processYear }}</td>
                  <td>{{ process.name }}</td>
                  <td>
                    {{ process.totalParticipants || 0 }}
                  </td>
                  <td>
                    <item-list-team-status type="inProgress"></item-list-team-status>
                  </td>
                  <td class="link" (click)="goToProcessDetails(process)">
                    {{ 'adminTable.ACCESS' | translate }}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Se processo selezionato -->
  <ng-container *ngIf="processYearSelected">
    <div class="main-content-wrapper" *ngIf="currentContentTab && currentContentTab.id">
      <!-- Tutte le competenze -->
      <ng-container *ngIf="currentContentTab.id == 'all'">
        <admin-all-competences [processYear]="processYear"></admin-all-competences>
      </ng-container>
      <ng-container *ngIf="currentContentTab.id == 'role-competence'">
        <admin-roles-and-competences></admin-roles-and-competences>
      </ng-container>
      <ng-container *ngIf="currentContentTab.id == 'dictionary'">
        <admin-dictionaries></admin-dictionaries>
      </ng-container>
    </div>
  </ng-container>
</div>