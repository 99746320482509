import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserCard } from 'src/app/utils/classes.utils';

@Component({
  selector: 'user-card',
  templateUrl: 'user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardComponent implements OnInit {
  @Input() user?: UserCard;
  @Input() isChecked: boolean = false;
  @Input() isFeedbackCard: boolean = false;
  @Input() selectedUserList?: UserCard[];
  @Output() onCheck: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit() {
    if (this.selectedUserList && this.selectedUserList.length && this.user) {
      for (let i = 0; i < this.selectedUserList.length; i++) {
        let selectedUserId = this.selectedUserList[i].id;
        if (this.user.id == selectedUserId) {
          this.user.isChecked = true;
          break;
        } else {
          this.user.isChecked = false;
        }
      }
    }
  }


  emitOnCheck() {
    if (this.user) {
      this.user.isChecked = !this.user.isChecked;
      this.onCheck.emit(this.user);
    }
  }

}