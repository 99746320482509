import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as fromApp from "../../../ngrx/app.reducers";
import { Store } from '@ngrx/store';
import { LangsService } from 'src/app/core/services/langs.service';
import { JwtPayload } from '../../../../commonclasses';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'banner-home',
  templateUrl: 'banner-home.component.html',
  styleUrls: ['./banner-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerHomeComponent implements OnInit {
  @Input() phaseName: string = 'H1'; // H1, H2, APPRAISAL
  @Input() backgroundSrc: string = '/assets/img/head/bg-head-demo.png' // vedi /banner/
  @Input() customClass: string = 'orange-bg' // orange-bg, red-bg, green-bg
  @Input() isNarrow: boolean = false;
  @Input() meetingNumber: number = 0;
  @Input() endDate: Date = moment().toDate();
  @Input() hasButton: boolean = false;
  @Input() buttonLabel: string = '';
  @Input() isAdmin: boolean = false;
  @Input() selfAssessment: boolean = false;
  @Input() phaseEndDate: any;
  today: string = '';
  isPeopleAppraisal: boolean = false;
  isStaff: boolean = false;
  userName: string = '';
  combinedSelectes$: any;
  bannerSubtitle: string = '';

  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  mobileToday: string = '';
  @Input() runningYear: number = 2021

  constructor(
    private store: Store<fromApp.AppState>,
    private langsService: LangsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    const isPeopleAppraisal$: Observable<boolean> = this.store.select(fromApp.getIsPeopleAppraisal)
    const isStaff$: Observable<boolean> = this.store.select(fromApp.getIsStaff)
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);

    this.combinedSelectes$ = combineLatest([loggedUser$, isPeopleAppraisal$, isStaff$])
      .subscribe(
        ([loggedUser, isPeopleAppraisal, isStaff]) => {
          if (loggedUser && loggedUser.user) {
            this.userName = loggedUser.user && loggedUser.user.forename;
            let language = this.langsService.getUserLang(loggedUser.user);
            moment.locale(language);
            this.today = moment().format('dddd D MMMM YYYY');
            this.mobileToday = moment().format('D MMMM YYYY');
            this.isPeopleAppraisal = isPeopleAppraisal;
            this.isStaff = isStaff;
            if (this.isValidDate(this.endDate)) {
              const formattedEndDate = new Date(this.endDate);

              this.translate.get(
                [
                  'banner.' + this.phaseName + '.DESCR_1',
                  'banner.' + this.phaseName + '.DESCR_2',
                  'banner.' + this.phaseName + '.DESCR_SELF_1',
                  'banner.' + this.phaseName + '.DESCR_SELF_2'
                ])
                .subscribe(translations => {
                  const endDate = moment(this.endDate).format('DD/MM/YYYY');
                  if (this.selfAssessment) {
                    this.bannerSubtitle = translations['banner.' + this.phaseName + '.DESCR_SELF_2'];
                  } else {
                    this.bannerSubtitle = translations['banner.' + this.phaseName + '.DESCR_2'];
                  }
                })
            }
          }
        });
  }

  isValidDate(dateObject: any) {
    return dateObject && new Date(dateObject).toString() !== 'Invalid Date';
  }

  emitOnButtonClicked(data?: any) {
    this.onButtonClicked.emit(data);
  }

  ngOnDestroy() {
    if (this.combinedSelectes$) {
      this.combinedSelectes$.unsubscribe();
    }
  }
}