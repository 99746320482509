<div class="item-list-container"
  [ngClass]="{'active': activeUserId === (_user && _user.userId), 'expand': onExpand, 'collapse': !onExpand}"
  (mouseenter)="expand()" (mouseleave)="collapse()" (click)="emitOnUserClicked()">
  <avatar-img [user]="_user" size="medium"></avatar-img>
  <div class="more-info-container">
    <p>{{ userName | truncate: [22, '...'] }}</p>
    <item-list-team-status [type]="userPhaseStatus" [message]="('itemListTeamStatus.' + userPhaseStatus)">
    </item-list-team-status>
  </div>
  <div class="arrow"></div>
</div>