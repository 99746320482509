<!-- Barra di ricerca -->
<div class="search-filters-container">
  <ng-container *ngIf="searchEnabled">
    <div class="admin-search-container">
      <div class="search-wrapper">
        <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
          (onModelChanged)="emitSearchedTextChanged($event)" iconRight="assets/img/icons/search.svg"
          [placeholder]="searchByTextPlaceholder || ('generic.SEARCH_BY' | translate)" [isSearchBar]="true">
        </alloy-input>
      </div>
    </div>
  </ng-container>
  <!-- Recap filtri -->
  <div class="filters-recap-container">
    <div class="left-column">
      <ng-container *ngIf="!selectedFiltersNumber">
        <p class="placeholder">{{ noActiveFilterText || ('adminSearch.NO_FILTER_ACTIVE' | translate) }}</p>
      </ng-container>
      <ng-container *ngIf="!selectedFiltersNumber && searchedText && searchedText.length">
        <p class="placeholder" [innerHTML]="oneFilterText || ('adminSearch.ONE_FILTER' | translate)"></p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber === 1">
        <p class="placeholder" [innerHTML]="oneFilterText || ('adminSearch.ONE_FILTER' | translate)"></p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber > 1">
        <p class="placeholder"><b>{{selectedFiltersNumber}}</b>{{ moreFiltersText || (' ' + ('adminSearch.MORE_FILTERS'
          |
          translate)) }}</p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber >= 1">
        <p class="delete-filters" (click)="clearFilters(true)">{{ deleteFiltersText || ('adminSearch.DELETE_FILTERS' |
          translate) }}</p>
      </ng-container>
      <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
    </div>
    <div class="right-column" (click)="openFiltersSection()">
      <p class="manage-filters">{{ manageFiltersText || ('adminSearch.MANAGE_FILTERS' |
        translate) }}</p>
      <ng-container *ngIf="!isFilterOpened">
        <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true"></svg-icon>
      </ng-container>
      <ng-container *ngIf="isFilterOpened">
        <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true"></svg-icon>
      </ng-container>
    </div>
  </div>
  <!-- contenitore filtri -->
  <ng-container *ngIf="isFilterOpened && filtersObject">
    <div class="filters-container">
      <div class="filters-content">
        <div class="generic-container" *ngIf="showSubCompany || showCdc">
          <!-- Subcompany -->
          <input-container *ngIf="showSubCompany" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.subcompany"
            title="{{'monitoring.filters.SUBCOMPANY' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'subcompany')" [selectedItem]="selectedFilters.subcompany"
              [items]="filtersObject.subcompany" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
          <!-- CdcFinane -->
          <input-container *ngIf="showCdc" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.cdcfinance"
            title="{{'monitoring.filters.CDCFINANCE' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'cdcfinance')" [selectedItem]="selectedFilters.cdcfinance"
              [items]="filtersObject.cdcfinance" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showAreaRef || showDepartment">
          <!-- Area reference -->
          <input-container *ngIf="showAreaRef" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.areaReference"
            title="{{'monitoring.filters.AREAREFERENCE' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'areaReference')" [selectedItem]="selectedFilters.areaReference"
              [items]="filtersObject.areaReference" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>

          <!-- Department -->
          <input-container *ngIf="showDepartment" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.department"
            title="{{'monitoring.filters.DEPARTMENT' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'department')" [selectedItem]="selectedFilters.department"
              [items]="filtersObject.department" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showTeam || showSubTeam">
          <!-- team -->
          <input-container *ngIf="showTeam" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.team"
            title="{{'monitoring.filters.TEAM' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'team')" [selectedItem]="selectedFilters.team"
              [items]="filtersObject.team" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>

          <!-- subteam -->
          <input-container *ngIf="showSubTeam" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.subTeam"
            title="{{'monitoring.filters.SUBTEAM' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'subTeam')" [selectedItem]="selectedFilters.subTeam"
              [items]="filtersObject.subTeam" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showPosition || showSupervisor">
          <!-- position -->
          <input-container *ngIf="showPosition" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.position"
            title="{{'monitoring.filters.POSITION' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'position')" [selectedItem]="selectedFilters.position"
              [items]="filtersObject.position" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
          <!-- supervisor -->
          <input-container *ngIf="showSupervisor" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.supervisor"
            title="{{'monitoring.filters.SUPERVISOR' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'supervisor')" [selectedItem]="selectedFilters.supervisor"
              [items]="filtersObject.supervisor" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showContractual || showOffice">
          <!-- contractual framework -->
          <input-container *ngIf="showContractual" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.contractual"
            title="{{'monitoring.filters.CONTRACTUAL' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'contractual')" [selectedItem]="selectedFilters.contractual"
              [items]="filtersObject.contractual" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
          <!-- office-->
          <input-container *ngIf="showOffice" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.office"
            title="{{'monitoring.filters.OFFICE' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'office')" [selectedItem]="selectedFilters.office"
              [items]="filtersObject.office" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showSelfAssessmentStatus || showAppraisalStatus">
          <!-- Stato self assessment -->
          <input-container *ngIf="showSelfAssessmentStatus" [isWithoutInfoIcon]="true"
            [isValued]="selectedFilters.selfStatus" title="{{'monitoring.filters.SELF_STATUS' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'selfStatus')" [selectedItem]="selectedFilters.selfStatus"
              [items]="filtersObject.selfStatus" [searchable]="false" bindLabel="id">
            </alloy-select>
          </input-container>
          <!-- Stato appraisal -->
          <input-container *ngIf="showAppraisalStatus" [isWithoutInfoIcon]="true"
            [isValued]="selectedFilters.peopleStatus" title="{{'monitoring.filters.PEOPLE_STATUS' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'peopleStatus')" [selectedItem]="selectedFilters.peopleStatus"
              [items]="filtersObject.peopleStatus" [searchable]="false" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <!-- Stato calibration -->
        <div *ngIf="showCalibrationStatus" class="generic-container">
          <input-container [isWithoutInfoIcon]="true" [isValued]="selectedFilters.calibrationStatus"
            title="{{'monitoring.filters.CALIBRATION_STATUS' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'calibrationStatus')"
              [selectedItem]="selectedFilters.calibrationStatus" [items]="filtersObject.calibrationStatus"
              [searchable]="false" bindLabel="id">
            </alloy-select>
          </input-container>
          <div class="full-width"></div>
        </div>

        <div class="button-actions filters">
          <alloy-button [label]="applyFiltersText || ('generic.APPLY_FILTERS' | translate)" type="primary"
            (onButtonClicked)="applyFilters()" [disabled]="isLoadingUsers || isLoadingFilters">
          </alloy-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>