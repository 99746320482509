import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alloy-radio',
  templateUrl: 'alloy-radio.component.html',
  styleUrls: ['./alloy-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropRadioComponent implements OnInit {
  @Input() radioName: string = 'Prova';
  // @Input() layout: string = "row"
  @Input() items: any[] = [];
  @Input() withLabel: boolean = true;
  @Input() bindValue: any;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Output() onItemChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.bindValue = this.items && this.items[0];
  }

  setValue(item: any) {
    this.bindValue = item;
    this.cdr.detectChanges();
    this.emitOnItemChange();
  }


  emitOnItemChange() {
    this.onItemChange.emit(this.bindValue);
  }
}