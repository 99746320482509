import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alloy-check',
  templateUrl: 'alloy-check.component.html',
  styleUrls: ['./alloy-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropCheckComponent implements OnInit {
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() label?: string;
  @Output() onCheck: EventEmitter<any> = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit() { }


  emitOnCheck() {
    this.checked = !this.checked;
    this.onCheck.emit();
    this.cdr.detectChanges();
  }
}