<div class="page-content-wrapper">
  <banner-home *ngIf="runningPhaseDate && runningPhaseDate.length" [phaseName]="phaseName" [endDate]="runningPhaseDate"
     [runningYear]="runningYear.year" [phaseEndDate]="runningPhaseDate"></banner-home>

  <!--Vista Desktop -->
  <div class="box-collection-container" fxShow.gt-sm fxHide>
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.sm="row wrap" fxLayoutGap.sm="16px grid"
      fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" fxLayoutAlign="start center" id="boxTag">
      <!-- Lista feedback per peopleAppraisal senza profilo self assessment -->
      <ng-container *ngIf="!isSelfAssessment && peerFeedbackAllowed">
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToFeedbackRequests()"
          imgPath="../../../../assets/img/icons/homeIcons/feedback-img.png"
          title="{{'homeCards.selfAssessment.feedbackCollection.TITLE' | translate}}"
          description="homeCards.selfAssessment.feedbackCollection.DESCRIPTION_ALT" [isNew]="false"></box-home>
      </ng-container>
      <!--[disabledModalMessage]="notesDisabledModal"-->
      <!-- Appunti privati -->
      <ng-container>
        <box-home id="privateBoxTag" fxFlex="50" (onButtonClicked)="goToTakeNote()"
          imgPath="../../../../assets/img/icons/homeIcons/ico-notes-img.png"
          title="{{'homeCards.peopleAppraisal.notes.TITLE' | translate}}"
          description="homeCards.peopleAppraisal.notes.DESCRIPTION">
        </box-home>
      </ng-container>
      <!-- Dashboard [isDisabled]="true" [disabledModalMessage]="dashboardDisabledModal" -->
      <box-home id="dashboardBoxTag" fxFlex="50" (onButtonClicked)="goToDashboard()"
        imgPath="../../../../assets/img/icons/homeIcons/ico-dashboard-img.png"
        title="{{'homeCards.peopleAppraisal.dashboard.TITLE' | translate}}"
        description="homeCards.peopleAppraisal.dashboard.DESCRIPTION"></box-home>
    </div>
  </div>
  <!--Vista tablet / mobile -->
  <div class="box-collection-container" fxShow fxHide.gt-sm>
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.sm="row wrap" fxLayoutGap.sm="16px grid"
      fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" fxLayoutAlign="start center">
      <!-- Appunti privati -->
      <!--[disabledModalMessage]="notesDisabledModal"-->
      <box-home fxFlex.sm="50" (onButtonClicked)="goToTakeNote()"
        imgPath="../../../../assets/img/icons/homeIcons/ico-notes-img.png"
        title="{{'homeCards.peopleAppraisal.notes.TITLE' | translate}}"
        description="homeCards.peopleAppraisal.notes.DESCRIPTION">
      </box-home>
      <!-- Dashboard -->
      <box-home fxFlex.sm="50" [isDisabled]="true" [disabledModalMessage]="dashboardDisabledModal"
        imgPath="../../../../assets/img/icons/homeIcons/ico-dashboard-img.png"
        title="{{'homeCards.peopleAppraisal.dashboard.TITLE' | translate}}"
        description="homeCards.peopleAppraisal.dashboard.DESCRIPTION"></box-home>
    </div>
  </div>
</div>