<div class="evaluation-rating-container">

  <div class="avatar-evaluation-container" [ngClass]="{'more-columns': rating && ratingValue}">
    <avatar-img [user]="user" [isHr]="isHr" [size]=" smallAvatar ? 'small' : 'medium'"></avatar-img>
    <div class="evaluation-container">
      <p class="evaluation-title">{{ evaluationTitle }}</p>
      <p class="evaluation-descr">{{ evaluationText || ('generic.NO_COMMENT_INSERTED' | translate) }}</p>
      <ng-container *ngIf="canEdit">
        <a class="custom-link" translate="finalEvaluation.EDIT_OBSERVATION"
          [ngClass]="{'disabled-edit-link': isEditDisabled}" (click)="emitOnEdit()"></a>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="rating ">
    <div class="rating-container">
      <star-rating [forceRating]="rating" [grayColor]="isSelf" customContainerClass="no-padding"></star-rating>
      <ng-container *ngIf="ratingValue">
        <div class="rating-value" [ngClass]="{'self': isSelf}">{{ ratingValue }}</div>
      </ng-container>
    </div>
  </ng-container>
</div>