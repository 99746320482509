import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';

import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';

@Component({
  selector: 'admin-manage-scale',
  templateUrl: './manageScale.component.html',
  styleUrls: ['./manageScale.component.scss']
})
export class ManageScaleComponent implements OnInit, OnDestroy {
  processYear: number = 0;
  processYearCode: string = '';
  isLoadingData: boolean = false;
  translations: any;
  dictionaryId: any;
  title: string = '';
  description: string = '';
  pointDescription: any = {
    1: '',
    2: '',
    3: '',
    4: '',
    5: ''
  }
  getScaleData$: Subscription = new Subscription;
  textAreaActions: any[] = [];

  constructor(
    public redirectService: RedirectService,
    public modalService: ModalService,
    private adminService: AdminService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.processYear = params.processYear;
      this.processYearCode = params.processYearCode;

      if (params.dictionaryId) {
        this.dictionaryId = params.dictionaryId;

        this.textAreaActions = [
          {
            id: 'delete',
            label: "generic.DELETE",
            class: 'secondary'
          },
          {
            id: 'save',
            label: "generic.SAVE",
            class: 'primary'
          }
        ];
        this.getScaleData();
      }
    });
  }

  ngOnInit(): void { }

  getScaleData() {
    this.isLoadingData = true;

    if (this.getScaleData$) {
      this.getScaleData$.unsubscribe();
    }

    this.getScaleData$ = this.adminService.getDictionary(this.processYear, this.processYearCode, this.dictionaryId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "dic001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingData = false;
          this.redirectService.goBackBrowser();
        } else {
          this.title = data.response.title;
          this.description = data.response.description;
          for (let i = 0; i < data.response.scaleLevels.length; i++) {
            let scale = data.response.scaleLevels[i];
            this.pointDescription[scale.scalePoint] = scale.text;
          }
        }
        this.isLoadingData = false;
      }, (err: any) => {
        this.isLoadingData = false;
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "dic002",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        }
      })
  }


  titleChanged(text: string) {
    this.title = text;
  }

  descriptionChanged(text: string) {
    this.description = text;
  }

  pointDescriptionChanged(text: string, value: number) {
    this.pointDescription[value] = text;
  }


  manageTextAreaActions(id: string, pointDescription: string) {
    if (id == 'delete') {
      pointDescription = '';
    } else if (id == 'save') {
      console.log("salva?");
    }
  }

  // Annulla
  onCancel() {
    this.redirectService.goBackBrowser();
  }

  // Salva modifiche
  onConfirm() {
    this.modalService.open('confirm-create-update');
  }

  closeConfirmModal(confirm?: boolean) {
    this.modalService.close('confirm-create-update');
    if (confirm) {
      this.isLoadingData = true;

      let evaluations = [];
      let keys = Object.keys(this.pointDescription);
      for (let i = 0; i < keys.length; i++) {
        evaluations.push({
          evaluationPoint: parseInt(keys[i]),
          evaluationDescription: this.pointDescription[keys[i]]
        })
      }
      let dataForService: any = { title: this.title, description: this.description, evaluation: evaluations };
      if (this.dictionaryId) {
        dataForService.scaleTagId = this.dictionaryId;

        this.adminService.updateDictionary(this.processYear, this.processYearCode, dataForService)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              // Vedo se c'è la traduzione dell'errore
              const messageObj: ApplicationModalMessage = {
                modalId: "dic003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingData = false;
              this.redirectService.goBackBrowser();
            } else {
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
              this.redirectService.goBackBrowser();
            }
            this.isLoadingData = false;
          }, (err: any) => {
            this.isLoadingData = false;
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "dic004",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.redirectService.goBackBrowser();
            }
          })

      } else {
        this.adminService.createDictionary(this.processYear, this.processYearCode, dataForService)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              // Vedo se c'è la traduzione dell'errore
              const messageObj: ApplicationModalMessage = {
                modalId: "dic003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingData = false;
              this.redirectService.goBackBrowser();
            } else {
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
              this.redirectService.goBackBrowser();
            }
            this.isLoadingData = false;
          }, (err: any) => {
            this.isLoadingData = false;
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "dic004",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.redirectService.goBackBrowser();
            }
          })
      }
    }
  }

  isSaveDisabled() {
    if (this.title && this.title.length) {
      let keys = Object.keys(this.pointDescription)
      for (let i = 0; i < keys.length; i++) {
        if (!this.pointDescription[keys[i]] || !this.pointDescription[keys[i]].length) {
          return true;
        }
      }
      return false;
    }
    return true;
  }


  ngOnDestroy() {
    if (this.getScaleData$) {
      this.getScaleData$.unsubscribe();
    }
  }

}