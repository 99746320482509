import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'alloy-text-area',
  templateUrl: 'alloy-text-area.component.html',
  styleUrls: ['./alloy-text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropTextAreaComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() bindValue: string | undefined = '';
  @Input() readonly: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() maxlength: number = 0;
  @Input() isInnerHTML: boolean = false;
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }
}