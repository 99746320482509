<div class="page-content-wrapper">
  <!-- Toaster -->
  <div id="snackbar">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <ng-container *ngIf="tabList && tabList.length > 0 && currentContentTab && currentContentTab.id">
    <header-dossier [isAdminHeader]="true" [adminPageTitle]="'process.TITLE' | translate"
      [adminPageSubtitle]="'setupCompetences.PROCESS_LIST' | translate" [adminBack]="true"
      [adminActionLabel]="'process.EDIT' | translate" (onAdminAction)="goToEditProcess()" adminActionLabelType="success"
      [menuButtons]="tabList" [customTabList]="true" (onMenuButtonClicked)="onContentTabChanged($event)"
      [currentMenuButton]="currentContentTab"></header-dossier>
  </ng-container>

  <!-- Sidebar con il menù principale -->
  <sidebar [showAppHeader]="false" [hideCloseButton]="false" [sidenavTemplateRef]="anagContent" direction="right"
    [duration]="0.3" [maxWidth]="660">
  </sidebar>

  <ng-template #anagContent>
    <div class="anag-container" *ngIf="sidebarData">
      <p class="anag-title" translate="process.anag.SCHEDULE"></p>
      <!-- loader -->
      <ng-container *ngIf="isLoadingSidebarData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
      <!--info anagrafiche -->
      <ng-container *ngIf="!isLoadingSidebarData">
        <div class="anag-info-container">
          <p class="title">{{ sidebarData.surname + ' ' + sidebarData.forename }}</p>
          <div class="row-section">
            <!-- Nome e cognome -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.USERNAME"></p>
              <p class="field-content">{{ sidebarData.surname + ' ' + sidebarData.forename}}</p>
            </div>
            <div class="field-section">
              <!-- Aggancia, sgancia dalla sync -->
              <ng-container *ngIf="sidebarData.isInSync">
                <alloy-button [disabled]="false" [label]="'process.anag.actions.TURN_SYNC_OFF' | translate"
                  type="success" size="medium" (onButtonClicked)="toggleSync()">
                </alloy-button>
              </ng-container>
              <ng-container *ngIf="!sidebarData.isInSync">
                <alloy-button [disabled]="false" [label]="'process.anag.actions.TURN_SYNC_ON' | translate"
                  type="success" size="medium" (onButtonClicked)="toggleSync(true)">
                </alloy-button>
              </ng-container>
            </div>
          </div>
          <div class="row-section">
            <!-- ID -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.ID"></p>
              <p class="field-content">{{sidebarData.cid || '--'}}</p>
            </div>
            <!--- CF -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.CF"></p>
              <p class="field-content">{{sidebarData.codFisc || '--'}}</p>
            </div>
          </div>
          <div class="row-section">
            <!-- Email -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.EMAIL"></p>
              <p class="field-content">{{sidebarData.email || '--'}}</p>
            </div>
            <!--- Genere -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.GENDER"></p>
              <p class="field-content">{{sidebarData.chiaveSesso || '--' }}</p>
            </div>
          </div>

          <div class="divider"></div>

          <div class="row-section">
            <!-- Stato -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.STATUS"></p>
              <p class="field-content" [ngClass]="sidebarData.active ? 'active' : 'inactive'">{{
                (sidebarData.active ? 'process.anag.fields.ACTIVE' : 'process.anag.fields.INACTIVE')|
                translate}}</p>
            </div>
            <!--- Tipo di occupazione -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.EMPLOYMENT"></p>
              <p class="field-content">{{ sidebarData.mansione || '--' }}</p>
            </div>
          </div>
          <div class="row-section">
            <!-- Data di assunzione -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.HIRED_DATE"></p>
              <p class="field-content">{{ sidebarData.dataAssunzione }}</p>
            </div>
            <!--- Data termine contratto -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.FIRE_DATE"></p>
              <p class="field-content">{{ sidebarData.deleteDate }}</p>
            </div>
          </div>

          <div class="divider"></div>

          <div class="row-section">
            <!-- Subcompany -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.SUBCOMPANY"></p>
              <p class="field-content">{{ sidebarData.subcompany || '--' }}</p>
            </div>
            <!--- Office -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.OFFICE"></p>
              <p class="field-content">{{ sidebarData.office || '--' }}</p>
            </div>
          </div>
          <div class="row-section">
            <!-- Dipartimento -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.DEPARTMENT"></p>
              <p class="field-content">{{ sidebarData.department || '--' }}</p>
            </div>
            <!--- Team -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.TEAM"></p>
              <p class="field-content">{{ sidebarData.team || '--' }}</p>
            </div>
          </div>

          <div class="divider"></div>

          <div class="row-section">
            <!-- Dipartimento -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.BEHAVIOR"></p>
              <p class="field-content">{{ sidebarData.mansioneOrganizzativa || '--' }}</p>
            </div>
            <!--- Team -->
            <div class="field-section">
              <p class="field-title" translate="process.anag.fields.SKILLS"></p>
              <p class="field-content">{{ sidebarData.descrizioneRuoloOperativo || '--' }}</p>
            </div>
          </div>

          <div class="divider"></div>

          <div class="row-section">
            <!-- Valutatore -->
            <div class="field-section full-width">
              <p class="field-title" translate="process.anag.fields.EVALUATOR"></p>
              <ng-container *ngIf="sidebarData.performanceManager">
                <p class="field-content">{{ sidebarData.performanceManager && sidebarData.performanceManager.forename ?
                  (sidebarData.performanceManager.surname + ' ' +
                  sidebarData.performanceManager.forename) : '--' }}</p>
              </ng-container>
            </div>
            <div class="field-section">
              <alloy-button [disabled]="false" [label]="'process.anag.actions.EDIT_EVALUATOR' | translate"
                type="success" size="medium" (onButtonClicked)="onEditEvaluator()">
              </alloy-button>
            </div>
          </div>
        </div>
        <!-- Lista processi -->
        <div class="process-list-container">
          <p class="title" translate="process.anag.fields.PROCESS_LIST"></p>
          <div class="list-container">
            <ng-container *ngFor="let process of sidebarData.includedInProcessData">
              <p class="item">{{ process.year + ' ' + process.yearCode + ' - ' + process.title }}</p>
            </ng-container>
          </div>

          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
            [isValued]="sidebarData.comments && sidebarData.comments.trim().length" [isObbligatory]="false"
            title="{{'generic.NOTES_COMMENTS' | translate}}">
            <alloy-text-area [bindValue]="sidebarData.comments" (onModelChanged)="onSidebarCommentChanged($event)"
              placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <div class="main-content-wrapper" *ngIf="currentContentTab && currentContentTab.id">
    <!-- Tab anagrafica -->
    <ng-container *ngIf="currentContentTab.id == 'anag'">

      <div class="admin-table-container column-spacing">

        <p class="section-title">{{ ('calibration.USER_LIST_IN_PROCESS' | translate) }}</p>
        <!-- anagrafica -->
        <div class="gray-box-container">
          <ng-container *ngIf="!isLoadingCounters && counterData">
            <!---->
            <div class="gray-box">
              <div class="text-info">
                <p class="number">{{ counterData.filteredTotalUserCount }}</p>
                <p class="description" translate="process.anag.counter.PEOPLE_IN_ANAG"></p>
              </div>
              <div class="excels" (click)="downloadCurrentList()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                </svg-icon>
                <p translate="process.anag.counter.DOWNLOAD_CURRENT"></p>
              </div>
            </div>
            <!-- Inclusi -->
            <div class="gray-box">
              <div class="text-info">
                <p class="number">{{ counterData.filteredUsersInProcessCount }}</p>
                <p class="description" translate="process.anag.counter.PEOPLE_INCLUDED"></p>
              </div>
              <div class="excels" (click)="openIncludeModalImport()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                </svg-icon>
                <p translate="process.anag.counter.INCLUDE_PEOPLE"></p>
              </div>
            </div>
            <!-- Esclusi -->
            <div class="gray-box">
              <div class="text-info">
                <p class="number">{{ counterData.filteredUsersNotInProcessCount }}</p>
                <p class="description" translate="process.anag.counter.PEOPLE_EXCLUDED"></p>
              </div>
              <div class="excels" (click)="openExcludeModalImport()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                </svg-icon>
                <p translate="process.anag.counter.EXCLUDE_PEOPLE"></p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isLoadingCounters">
            <div class="loader-container">
              <div class="loader spinner-big"></div>
            </div>
          </ng-container>
        </div>

        <!-- Filtri -->
        <anag-filters (searchedTextChanged)="searchedTextChanged($event)" [isLoadingUsers]="isLoadingUsers"
          (updateSelectedFilters)="updateSelectedFilters($event)"
          (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)" (startSearch)="getUsersList($event)">
        </anag-filters>

        <!-- contenitore tabella -->
        <ng-container *ngIf="!isLoadingTranslations && !isLoadingUsers && usersList && usersList.length">

          <div class="table-content ">
            <table>
              <thead>
                <tr>
                  <th>
                    <alloy-check (onCheck)="selectAll()"></alloy-check>
                  </th>
                  <th translate="{{ ('adminTable.NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.CF' | translate)}}"></th>
                  <th translate="{{ ('adminTable.DEFINITION' | translate)}}"></th>
                  <th class="center" translate="adminTable.STATUS"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of usersList | 
                          slice:0:usersNumRecords | 
                          paginate: { id: 'userList',
                                      itemsPerPage: usersNumRecords,
                                      currentPage: usersCurrentPage,
                                      totalItems: usersCounter }">
                  <td>
                    <alloy-check id="user.userId" [checked]="user.isChecked" (click)="selectUser(user)"></alloy-check>
                  </td>
                  <td>{{ user.surname + ' ' + user.forename }}</td>
                  <td>{{ user.codFisc }}</td>
                  <td>Definizione TODO</td>
                  <td>
                    <div class="status-evaluation">
                      <ng-container *ngIf="!user.isInProcess">
                        <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                          [message]="'process.anag.status.EXCLUDED' | translate">
                        </item-list-team-status>
                      </ng-container>
                      <ng-container *ngIf="user.isInProcess">
                        <item-list-team-status customContainerClass="min-width-unset" styleItem="border"
                          type="completed" [message]="'process.anag.status.INCLUDED' | translate">
                        </item-list-team-status>
                      </ng-container>
                    </div>
                  </td>
                  <td class="action">
                    <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                      (clickOutside)="closeMenu(user)" src="/assets/img/icons/more-horizontal.svg">
                    </svg-icon>
                    <ng-container *ngIf="user.isMenuOpen">
                      <div class="dropdown-menu-table">
                        <ng-container *ngFor="let item of menuOptions">
                          <alloy-dropdown-item (click)="onMenuOptionsClicked(item, user)" class="full-width"
                            [item]="item">
                          </alloy-dropdown-item>
                        </ng-container>
                      </div>
                    </ng-container>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
          <div class="button-actions sticky" *ngIf="selectedUserList && selectedUserList.length > 1">
            <div class="selected-container">
              <p class="selected-count">{{ selectedUserList.length || 0}}</p>
              <p class="selected-count-label" translate="generic.SELECTEDS"></p>
            </div>
            <!-- Compara nella matrice -->
            <alloy-button [label]="'process.anag.actions.INCLUDE' | translate" type="success"
              [disabled]="!canIncludeMassive()" (onButtonClicked)="openModalMassive(true)"></alloy-button>
            <!-- Invia schede -->
            <alloy-button [label]="'process.anag.actions.EXCLUDE' | translate" type="secondary"
              [disabled]="!canExcludeMassive()" (onButtonClicked)="openModalMassive()">
            </alloy-button>
          </div>

        </ng-container>

        <ng-container *ngIf="!isLoadingUsers && (!usersList || !usersList.length)">
          <div class="admin-no-data-wrapper">
            <p translate="geneirc.NO_DATA_FOUND"></p>
          </div>
        </ng-container>
        <!-- loader -->
        <ng-container *ngIf="isLoadingUsers || isLoadingTranslations">
          <div class="loader-container">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>
      </div>


    </ng-container>





    <!-- Tab fasi -->
    <ng-container *ngIf="currentContentTab.id == 'phases'">
      <div class="admin-table-container column-spacing">

        <p class="section-title">{{ ('process.phases.TITLE' | translate) }}</p>
        <ng-container *ngIf="isLoadingPhasesData">
          <div class="spinner-container">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isLoadingPhasesData">

          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.PROCESS_PHASE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.START_DATE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.END_DATE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <!-- People appraisal -->
                <tr>
                  <td translate="process.phases.PEOPLE"></td>
                  <td>{{ phases.people.startDate ? (phases.people.startDate | date: 'dd.MM.YYYY') : '--' }}</td>
                  <td>{{ phases.people.endDate ? (phases.people.endDate | date: 'dd.MM.YYYY') : '--' }}</td>
                  <td>
                    <ng-container *ngIf="phases.people.status == 'INACTIVE'">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                        [message]="'process.phases.status.INACTIVE' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="phases.people.status == 'ACTIVE'">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="completed"
                        [message]="'process.phases.status.ACTIVE' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </td>
                  <td class="action">
                    <svg-icon class="more-icon" [applyClass]="true" (click)="openClosePhasesMenu('people')"
                      (clickOutside)="closePhasesMenu('people')" src="/assets/img/icons/more-horizontal.svg">
                    </svg-icon>
                    <ng-container *ngIf="phases.people.isMenuOpen">
                      <div class="dropdown-menu-table">
                        <ng-container *ngFor="let item of phasesMenuOption">
                          <alloy-dropdown-item (click)="onPhaseMenuOptionsClicked(item, 'people')" class="full-width"
                            [item]="item">
                          </alloy-dropdown-item>
                        </ng-container>
                      </div>
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td translate="process.phases.SELF"></td>
                  <td>{{ phases.self.startDate ? (phases.self.startDate | date: 'dd/MM/YYYY') : '--' }}</td>
                  <td>{{ phases.self.endDate ? (phases.self.endDate | date: 'dd/MM/YYYY') : '--' }}</td>
                  <td>
                    <ng-container *ngIf="phases.self.status == 'INACTIVE'">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                        [message]="'process.phases.status.INACTIVE' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="phases.self.status == 'ACTIVE'">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="completed"
                        [message]="'process.phases.status.ACTIVE' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </td>
                  <td class="action">
                    <svg-icon class="more-icon" [applyClass]="true" (click)="openClosePhasesMenu('self')"
                      (clickOutside)="closePhasesMenu('self')" src="/assets/img/icons/more-horizontal.svg">
                    </svg-icon>
                    <ng-container *ngIf="phases.self.isMenuOpen">
                      <div class="dropdown-menu-table">
                        <ng-container *ngFor="let item of phasesMenuOption">
                          <alloy-dropdown-item (click)="onPhaseMenuOptionsClicked(item, 'self')" class="full-width"
                            [item]="item">
                          </alloy-dropdown-item>
                        </ng-container>
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--div class="bottom-actions">
              <table-pagination id="dataList" (pageChanged)="modalPageChanged($event)" [noPadding]="true">
              </table-pagination>
              <div class="per-page-selector">
                <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeModalNumRecords($event)"
                  [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                  [tablePagination]="true">
                </alloy-select>
              </div>
            </div-->
        </ng-container>




      </div>
    </ng-container>

  </div>

  <!-- Loader tab -->
  <ng-container *ngIf="!currentContentTab || !currentContentTab.id">
    <div class="loader-container">
      <div class="loader spinner-big"></div>
    </div>
  </ng-container>
</div>

<!-- Modale import inclusione persone -->
<modal id="import-modal-include" (onClose)="closeModalImportInclude()" (onConfirm)="closeModalImportInclude(true)"
  [confirmLabel]="'generic.IMPORT' | translate" (onCancel)="closeModalImportInclude()"
  [cancelLabel]="'generic.CANCEL' | translate">
  <div class="sti-modal-container">
    <p class="title" [translate]="'process.anag.IMPORT_MODAL_TITLE'">
    </p>
    <div class="divisor-line"></div>

    <div class="modal-action-container">
      <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="success bordless" size="medium"
        (onButtonClicked)="downloadTrack()">
      </alloy-button>
    </div>

    <!-- Upload lista persone da includere -->
    <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
      [title]="'process.anag.IMPORT_MODAL_SUBTITLE' | translate">
      <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)" [acceptedMimeTypes]="'.xls .xlsx'">
      </alloy-upload>
    </input-container>

    <ng-container *ngIf="importFile">
      <div class="confirm-info">
        <p class="title">{{ 'process.anag.IMPORT_MODAL_TEXT' | translate }}</p>
        <p class="text" [innerHTML]="'process.anag.CONFIRM_IMPORT' | translate"></p>
      </div>
    </ng-container>
  </div>
</modal>

<!-- Modale import esclusione persone -->
<modal id="import-modal-exclude" (onClose)="closeExcludeModalImport()" (onConfirm)="closeExcludeModalImport(true)"
  [confirmLabel]="'generic.IMPORT' | translate" (onCancel)="closeExcludeModalImport()"
  [cancelLabel]="'generic.CANCEL' | translate">
  <div class="sti-modal-container">
    <p class="title" [translate]="'process.anag.EXCLUDE_IMPORT_MODAL_TITLE'">
    </p>
    <div class="divisor-line"></div>

    <div class="modal-action-container">
      <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="success bordless" size="medium"
        (onButtonClicked)="downloadTrack()">
      </alloy-button>
    </div>

    <!-- Upload lista persone da includere -->
    <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
      [title]="'process.anag.EXCLUDE_IMPORT_MODAL_SUBTITLE' | translate">
      <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)" [acceptedMimeTypes]="'.xls .xlsx'">
      </alloy-upload>
    </input-container>

    <ng-container *ngIf="importFile">
      <div class="confirm-info">
        <p class="title">{{ 'process.anag.EXCLUDE_IMPORT_MODAL_TEXT' | translate }}</p>
        <p class="text" [innerHTML]="'process.anag.EXCLUDE_CONFIRM_IMPORT' | translate"></p>
      </div>
    </ng-container>
  </div>
</modal>


<!-- Modale toggle sync utenti -->
<modal id="sync-toggle-modal" (onClose)="closeToggleSyncModal()" [confirmLabel]="'generic.CONFIRM' | translate"
  [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeToggleSyncModal(true)"
  (onCancel)="closeToggleSyncModal()">
  <ng-container *ngIf="syncModalData && syncModalData.title">
    <modal-text-content [modalId]="'as001'" [title]="syncModalData.title" [text]="syncModalData.text">
    </modal-text-content>
  </ng-container>
</modal>

<modal id="modal-single" (onClose)="closeSingleModal()" [confirmLabel]="'generic.CONFIRM' | translate"
  [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeSingleModal(true)" (onCancel)="closeSingleModal()">
  <ng-container *ngIf="singleModalData && singleModalData.title">
    <modal-text-content [modalId]="'si001'" [title]="singleModalData.title" [text]="singleModalData.text">
    </modal-text-content>
  </ng-container>
</modal>

<!-- Inclusione/esclusione massiva -->
<modal id="modal-massive" (onClose)="closeModalMassive()" [confirmLabel]="'generic.CONFIRM' | translate"
  [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeModalMassive(true)" (onCancel)="closeModalMassive()">
  <ng-container *ngIf="massiveModalData && massiveModalData.title">
    <modal-text-content [modalId]="'me001'" [title]="massiveModalData.title" [text]="massiveModalData.text">
    </modal-text-content>
  </ng-container>
</modal>

<modal id="change-evaluator" (onClose)="closeEditEvaluator()" (onCancel)="closeEditEvaluator"
  [cancelLabel]="'generic.CANCEL' | translate" [fullPage]="true">
  <div class="sti-modal-container">
    <p class="title" translate="process.anag.CHANGE_EVALUATOR_TITLE"></p>
    <p class="descr" translate="process.anag.CHANGE_EVALUATOR_DESCR"></p>

    <div class="admin-table-container">

      <!-- Barra di ricerca -->
      <div class="search-filters-container">
        <div class="admin-search-container bottom-filters">
          <div class="search-wrapper">
            <alloy-input (onIconClicked)="getModalData()" typeInput="text" [bindValue]="modalSearchedText"
              (onModelChanged)="modalSearchChanged($event)" iconRight="assets/img/icons/search.svg"
              [placeholder]="'setupCompetences.SEARCH_BY_NAME' | translate" [isSearchBar]="true">
            </alloy-input>
          </div>
        </div>
      </div>


      <ng-container *ngIf="isLoadingModalData">
        <div class="spinner-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isLoadingModalData">
        <ng-template #noData>
          <div class="no-result-container">
            <p translate="generic.NO_RESULTS"></p>
          </div>
        </ng-template>
        <ng-container *ngIf="modalData.list && modalData.list.length; else noData">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.TITLE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.CF' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of modalData.list 
                          | slice:0:modalData.numRecords 
                          | paginate: { id: 'dataList',
                                  itemsPerPage: modalData.numRecords,
                                  currentPage: modalData.page,
                                  totalItems: modalData.counter 
                              }">
                  <td>{{ data.surname + ' ' + data.forename }}</td>
                  <td>{{ data.codFisc }}</td>
                  <td>
                    <p class="table-action" (click)="chooseEvaluator(data)">{{ 'process.actions.CHOOSE_EVALUATOR' |
                      translate}}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="dataList" (pageChanged)="modalPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeModalNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</modal>