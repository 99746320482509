import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as PeopleAppraisalActions from './peopleAppraisal.actions';
import { from } from 'rxjs';
import { Subordinateservice } from '../services/subordinates.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { TranslateService } from '@ngx-translate/core';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

@Injectable()
export class PeopleAppraisalEffects {
  constructor(private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private subordinatesService: Subordinateservice,
    private translate: TranslateService) {
  }

  // Recuera i subordinati con anche lo stato delle fasi
  retrieveSuboardinatesWithPhaseStatuses$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(PeopleAppraisalActions.RetrieveSuboardinatesWithPhaseStatuses)
        , withLatestFrom(this.store.select(fromApp.getRunningYear))
        , switchMap(([action, runningYear]) => {
          this.store.dispatch(PeopleAppraisalActions.SetIsLoadingSubordinates({ payload: true }));

          return from(this.subordinatesService.getSubordinatesForManager(runningYear.year, runningYear.yearCode));
        })
        , map(
          (data: any) => {
            this.store.dispatch(PeopleAppraisalActions.SetIsLoadingSubordinates({ payload: false }));
            if (data.error) {
              throw (new Error(data.error));
            } else {
              return PeopleAppraisalActions.SetSuboardinatesWithPhaseStatuses({ payload: data.response });
            }
          }
        )
        , catchError((err, caught) => {
          this.store.dispatch(PeopleAppraisalActions.SetIsLoadingSubordinates({ payload: false }));
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "097",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      )
  )

  updateSelfAssessmentData$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(PeopleAppraisalActions.UpdateSelfAssessmentData),
          withLatestFrom(this.store.select(fromApp.getRunningYear), this.store.select(fromApp.getActiveUserId)),
          switchMap(([action, runningYear, activeUserId]) => {
            return from(this.subordinatesService.getSubordinatesForManager(runningYear.year, runningYear.yearCode, activeUserId));
          }),
          map(
            (data: any) => {
              if (data.error) {
                throw (new Error(data.error));
              } else {
                return PeopleAppraisalActions.SetUpdatedSelfAssessmentData({ payload: data.response });
              }
            }
          ),
          catchError((err, caught) => {
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "170",
                text: this.translate.instant("errors." + err.message),
                title: this.translate.instant("generic.WARNING")
              };
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            return caught;
          })
        );
    })
}
