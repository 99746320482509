import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'modal-text-content',
  templateUrl: 'modal-text-content.component.html',
  styleUrls: ['./modal-text-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTextContentComponent implements OnInit {
  @Input() modalId?: string = 'AAA'
  @Input() title?: string = 'Titolo input';
  @Input() subtitle?: string = '';
  @Input() text?: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean egestas lobortis hendrerit. Praesent luctus viverra viverra. Vestibulum nec dictum massa, sit amet cursus odio. Nunc luctus ut felis sit amet tincidunt. Sed sed porttitor nulla. Aenean id nisi tellus. Curabitur sodales purus id tortor condimentum, eu cursus enim tempus. Donec viverra lacus mi, non lacinia risus interdum semper.';
  @Input() badge?: BadgeObject;
  @Input() image?: string;
  @Input() noBackground?: boolean;
  @Input() withScrolledBody?: boolean;
  @Input() modalWithKalturaScript?: string;
  @Input() kalturaCustomId?: string = '';
  @Input() isRiepilogueDevelopAction?: boolean;
  showModalCode: boolean = !environment.production;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {

  }
}
