<ngx-guided-tour skipText="{{ 'tutorials.SKIP' | translate }}" nextText="{{ 'tutorials.NEXT' | translate }}"
  closeText="{{ 'tutorials.CLOSE' | translate }}" backText="{{ 'tutorials.BACK' | translate }}"
  doneText="{{ 'tutorials.DONE' | translate }}">
</ngx-guided-tour>

<!-- Sidebar con il menù principale -->
<sidebar [showAppHeader]="true" [hideCloseButton]="true" [sidenavTemplateRef]="navContent" direction="right"
  [navWidth]="325" [duration]="0.3" [hasImpersonateBanner]="showImpersonateBanner">
</sidebar>

<!-- Template con la sidebar principale (di destra) -->
<ng-template #navContent>
  <alloy-dropdown-item [ngClass]="{'display-on-mobile': item.id=='tutorial'}" (click)="onHeaderOptionClicked(item)"
    class="full-width" *ngFor="let item of basicMenuItems; trackBy menuId" [isMenuItem]="true" [item]="item">
  </alloy-dropdown-item>
</ng-template>

<ng-container *ngIf="showImpersonateBanner && loggedUser">
  <div class="impersonate-header">
    <p
      [innerHTML]="('header.IMPERSONATING' | translate) + '<b>'+ loggedUser.forename + ' '+ loggedUser.surname +'</b>'">
    </p>
  </div>
</ng-container>

<!-- Header -->
<app-header *ngIf="showHeader()" [isPeopleAppraisal]="isPeopleAppraisal" [isSelfAssessment]="isSelfAssessment"
  [isAdmin]="isAdmin" [tooltipModalMessage]="headerTooltip" (onUserLogoClicked)="onGoToMySection()"
  (onPeopleAppraisalLogoClicked)="goToMyTeam()" [isTeamPage]="isTeamPage"
  [showImpersonateBanner]="showImpersonateBanner">
</app-header>

<!-- App -->
<router-outlet *ngIf="!isFetchingLangs && !showApplicationLoader" [hidden]="showApplicationLoader"></router-outlet>

<!-- Loader dell'applicazione, del caricamento delle pagine e del recupero delle lingue disponibili, o ogniqualvolta il valore "showApplicationLoader " nello Store è attivo -->
<div *ngIf="isFetchingLangs || showApplicationLoader" class="page-loading-container">
  <div>
    <img alt="Alloy" src="/assets/img/icons/app-logo.png" />
  </div>
  <div>
    <p>Attendi</p>
  </div>
  <div>
    <h4>Ci siamo quasi</h4>
  </div>
  <div class="big-loader-spinner"></div>
</div>

<!-- Modale generica con un messaggio (ad esempio di errore piuttosto che di successo) -->
<modal id="modalMessage" class="message-modal-index" [isMessageModal]="true" (onClose)="closeModalMessage()"
  (onCancel)="closeModalMessage()" (onConfirm)="closeModalMessage()" [cancelLabel]="applicationModalButtonCloseText"
  [noConfirmButton]="noConfirmButton" [confirmLabel]="confirmLabel ? confirmLabel : 'Ok'">
  <modal-text-content [kalturaCustomId]="kalturaCustomId" [modalWithKalturaScript]="modalWithKalturaScript"
    [withScrolledBody]="withScrolledBody" [modalId]="applicationModalId" [title]="applicationModalMessageTitle"
    [text]="applicationModalMessageText" [subtitle]="applicationModalMessageSubTitle"
    [image]="applicationModalMessageImage" [noBackground]="applicationModalMessageNoBackground">
  </modal-text-content>
</modal>

<!-- Modali per i badge -->
<ng-container *ngIf="applicationModalBadge && applicationModalBadge.length">
  <ng-container *ngFor="let badge of applicationModalBadge">
    <modal id="{{'modalBadge' + badge.badgeId}}" (onClose)="closeModalBadge(badge.badgeId)"
      (onCancel)="closeModalBadge(badge.badgeId)" (onConfirm)="closeModalBadge(badge.badgeId)"
      [confirmLabel]="closeBadgeModalLabel">
      <modal-text-content [badge]="badge"></modal-text-content>
    </modal>
  </ng-container>
</ng-container>