import * as CommonClasses from "../../../commonclasses";
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { GetPerformanceCareTagsResponse, SenecaResponse } from "../../../commonclasses";

@Injectable()
export class Subordinateservice {
  applicationContext: string;
  constructor(private urlService: UrlService,
    private http: HttpClient) {
    this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
  }

  // Recupera la lista di tutti i sottoposti di un  peopleAppraisal con lo stato di ogni
  getSubordinatesForManager(perfYear: number, perfYearCode: string, userIdToRetrieve?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (userIdToRetrieve) {
      if (userIdToRetrieve && userIdToRetrieve.length && Array.isArray(userIdToRetrieve)) {
        userIdToRetrieve && userIdToRetrieve.forEach(id => {
          httpParams = httpParams.append('userIdToRetrieve', id);
        });
      } else if (userIdToRetrieve && userIdToRetrieve.length && !Array.isArray(userIdToRetrieve)) {
        httpParams = httpParams.append('userIdToRetrieve', userIdToRetrieve);
      }
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-subordinates-for-manager', {
      params: httpParams
    });
  }

  // Recupera la lista di tutti i sottoposti di un  peopleAppraisal con lo stato di ogni
  getUserRedirectForGuidedTourForManager(perfYear: number, perfYearCode: string, userIdToRetrieve?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-user-redirect-for-guided-tour-for-manager', {
      params: httpParams
    });
  }

  // Recupera la lista di tutti i sottoposti di un  peopleAppraisal con lo stato di ogni
  getUserRedirectForGuidedTourForSubordinate(perfYear: number, perfYearCode: string, userIdToRetrieve?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/alloyperformance-mediator/get-user-redirect-for-guided-tour-for-subordinate', {
      params: httpParams
    });
  }

  // Recupera i dati per il profilo utente
  listMyBadges(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationContext + 'rest-api/alloyperformance-mediator/list-my-obtained-badges/', {
      params: httpParams
    });
  }

  uploadAvatar(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationContext + 'rest-api/alloyperformance-mediator/upload-avatar', formData, options);
    return this.http.request(req);
  }

  processFirstAccessBadge() {
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationContext + 'rest-api/alloyperformance-mediator/process-first-access-badge/', {});
  }

}