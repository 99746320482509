import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';
import { SenecaResponse, ListMyGoalsResponse, GetMaxGoalWeightsForUsersByProfileResponse, GetPerformanceCareTagsResponse, PerfCareUserPhaseGoal, CreateUserGoalForUserResponse, UpdateMyGoalResponse, GetMyGoalByIdResponse, CanProposeGoalsToManagerResponse, ProposeMyGoalsToManagerResponse, GetUserFeedbackDialogueForUserResponse, PerfCareFeedbackDialogue, CreateUserFeedbackDialogueForUserResponse, UpdateUserFeedbackDialogueForUserResponse, DeleteMyGoalsResponse, GetMyManagerUserDataResponse, GetBehaviorCareTagsResponse, GetBehaviorCareTagByIdResponse, CancelUserFeedbackDialogueForUserResponse, SetMyCompetencesResponse, CloseMyCompetencesQuestionsResponse, HasLoggedUserDoneQualificationForCompetencesResponse, UpdateApproveGoalCommentProposedBySubordinateUserResponse, PerfCareDevelopmentAction, CreateDevelopmentActionForUserResponse, UpdateDevelopmentActionForUserResponse, CanCreateDevelopmentActionForUserResponse, CanShareDevelopmentActionToManagerResponse, PerfCareApplauseFeedback, ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse, PerfCareEvidenceFeedback, CreateEvidenceFeedbackForUserResponse, ListEvidenceFeedbackForUserResponse, UpdateEvidenceFeedbackForUserResponse, DeleteFileForEvidenceFeedbackForUserResponse, CountFeedbacksForUserResponse, ListPrivateNoteFeedbackForUserResponse, DeletePrivateNoteFeedbackForUserResponse, EnrichedSenecaResponseForBadges } from 'src/commonclasses';
import { PerfAlloyBehavior, PerfAlloyUserGoal } from "atfcore-commonclasses";

@Injectable()
export class SelfAssessmentService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;
  isSelfAssessment: boolean = false;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  // Recupera i goals del self assessment
  listUserGoals(year: number, goalTab?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', year.toString() || '');
    if (goalTab && goalTab.length && Array.isArray(goalTab)) {
      goalTab && goalTab.forEach(type => {
        httpParams = httpParams.append('goalTab', type);
      });
    } else if (goalTab && goalTab.length && !Array.isArray(goalTab)) {
      httpParams = httpParams.append('goalTab', goalTab);
    }
    return this.http.get<SenecaResponse<ListMyGoalsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-goals/', {
      params: httpParams
    });
  }

  // Recupera i pesi profilo
  getObjectiveWeightsByProfile(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<GetMaxGoalWeightsForUsersByProfileResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-goal-configs-by-user-profile/', {
      params: httpParams
    });
  }

  // Recupera le Care options per gli obiettivi
  getCareOptions(fromRecord: string, numRecords: string, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-care-tags/', {
      params: httpParams
    });
  }

  // Crea un obiettivo
  createGoal(perfYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-user-goal-for-user', {
      perfYear: perfYear,
      perfCareUserPhaseGoals: goal
    });
  }

  // Aggiorna un obiettivo
  updateGoal(perfYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<UpdateMyGoalResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-my-goal', {
      perfYear: perfYear,
      perfCareUserPhaseGoal: goal
    });
  }

  // Recupera l'obiettivo per il self assessment
  getGoalByIdForUser(perfYear: number, perfYearCode: string, goalId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('goalId', goalId);

    return this.http.get<SenecaResponse<GetMyGoalByIdResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-my-goal-by-id/', {
      params: httpParams
    });
  }

  // Può condividere un obiettivo al manager
  canShareGoal(perfYear: number) {
    return this.http.post<SenecaResponse<CanProposeGoalsToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-propose-goals-to-manager', {
      perfYear: perfYear
    });
  }

  // Condivi obiettivo al PeopleAppraisal
  shareGoalToPeopleAppraisal(perfYear: number) {
    return this.http.post<SenecaResponse<ProposeMyGoalsToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/propose-my-goals-to-manager', {
      perfYear: perfYear
    });

  }

  // Restituisce le giornate disponibili e non
  getSlotCards(attendees: string, startDate: string, endDate: string, availabilityViewInterval: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attendees', attendees);
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    httpParams = httpParams.append('availabilityViewInterval', availabilityViewInterval);

    return this.http.get<SenecaResponse<CheckExchangeAgendaForFreeSlotsResponse[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-users-schedules-slots-availability', {
      params: httpParams
    });
  }

  // Recupera il colloquio da suo interviewId
  getInterviewByIdForUser(perfYear: number, perfYearCode: string, feedbackDialogueId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackDialogueId', feedbackDialogueId);

    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-feedback-dialogue-for-user/', {
      params: httpParams
    });
  }

  // Crea il colloquio
  createInterviewForUser(perfYear: number, perfYearCode: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<CreateUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-user-feedback-dialogue-for-user', {
      perfYear: perfYear,
      feedbackDialogue: interview
    });
  }

  // Aggiorna il colloquio
  updateInterviewForUser(perfYear: number, perfYearCode: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<UpdateUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-user-feedback-dialogue-for-user', {
      perfYear: perfYear,
      feedbackDialogue: interview
    });
  }

  // Elimia gli obiettivi passati come parametri gli Id
  deleteGoals(perfYear: number, goalIds: string[]) {
    return this.http.post<SenecaResponse<DeleteMyGoalsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-my-goals', {
      perfYear: perfYear,
      goalIdsToDelete: goalIds
    });
  }

  getClosestInterview(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<GetMyManagerUserDataResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-closest-user-feedback-dialogue-for-user', {
      params: httpParams
    });
  }

  // Recupera le Behaviour options per gli obiettivi
  getBehaviourOptions(fromRecord: string, numRecords: string, allData?: boolean, parentTagId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    if (parentTagId && parentTagId.length) {
      httpParams = httpParams.append('parentTagId', parentTagId);
    }
    return this.http.get<SenecaResponse<GetBehaviorCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-behavior-care-tags/', {
      params: httpParams
    });
  }

  // Recupero info careTag dal suo id
  getCareTagById(tagId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('tagId', tagId);

    return this.http.get<SenecaResponse<GetBehaviorCareTagByIdResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-behavior-care-tag-by-id/', {
      params: httpParams
    });
  }

  // Crea sfida per il self assessment partendo dal careTag
  createUserGoalForUser(perfYear: number, perfYearCode: string, perfCareUserPhaseGoal: any) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-user-goal-for-user', {
      perfYear: perfYear,
      perfCareUserPhaseGoals: perfCareUserPhaseGoal
    });
  }

  // Annulla il colloquio
  deleteInterview(perfYear: number, perfYearCode: string, feedbackDialogueId: string) {
    return this.http.post<SenecaResponse<CancelUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/cancel-user-feedback-dialogue-for-user', {
      perfYear: perfYear,
      feedbackDialogueId: feedbackDialogueId
    });
  }

  // Recupero le azioni di sviluppo
  getDevelopmentActions(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('forUserId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-development-action-for-user/', {
      params: httpParams
    });
  }

  getCompetenceForQualifications(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-my-competences-for-qualifications/', {
      params: httpParams
    });
  }

  // Recupero il booleano per sapere se si possono creare azioni di sviluppo
  canCreateDevelopmentActions(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<CanCreateDevelopmentActionForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-create-development-action-for-user/', {
      params: httpParams
    });
  }

  // Crea un azione di sviluppo
  createDevelopAction(perfYear: number, developmentAction: PerfCareDevelopmentAction) {
    return this.http.post<SenecaResponse<CreateDevelopmentActionForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-development-action-for-user', {
      perfYear: perfYear,
      developmentAction: developmentAction
    });
  }

  // Aggiorna un azione di sviluppo
  updateDevelopAction(perfYear: number, developmentAction: PerfCareDevelopmentAction) {
    return this.http.post<SenecaResponse<UpdateDevelopmentActionForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-development-action-for-user', {
      perfYear: perfYear,
      developmentAction: developmentAction
    });
  }

  // Recupera l'obiettivo per il self assessment
  getDevelopActionByIdForSelfAssessment(perfYear: number, perfYearCode: string, developmentActionId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('developmentActionId', developmentActionId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-development-action-by-id-for-user/', {
      params: httpParams
    });
  }

  // Aggiunge il commento ad un azione di sviluppo
  addCommentToDevelopPlan(perfYear: number, perfYearCode: string, developmentActionId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-development-action-comment-for-user', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      comment: comment
    });
  }

  // Aggiorna il commento ad un azione di sviluppo
  updateCommentForDevelopPlan(perfYear: number, perfYearCode: string, developmentActionId: string, commentId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-development-action-comment-for-user', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      comment: comment,
      commentId: commentId
    });
  }

  deleteCommentToDevelopPlan(perfYear: number, perfYearCode: string, developmentActionId: string, commentId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-development-action-comment-for-user', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      commentId: commentId
    });
  }
  // Condividi le azioni di sviluppo
  shareDevelopActions(perfYear: number, perfYearCode: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-development-actions-to-manager', {
      perfYear: perfYear
    });
  }

  setMyCompetence(perfYear: number, perfYearCode: string, competencesToSet: { tagId: string, areaType: string }[]) {
    return this.http.post<SenecaResponse<SetMyCompetencesResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-my-competences', {
      perfYear: perfYear,
      competencesToSet: competencesToSet
    });
  }

  changeMyCompetenceArea(perfYear: number, perfYearCode: string, competencesToSet: { tagId: string, areaType: string }[]) {
    return this.http.post<SenecaResponse<CancelUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/change-my-care-competence-area', {
      perfYear: perfYear,
      competencesToSet: competencesToSet
    });
  }

  closeMyCompetenceQuestions(perfYear: number, perfYearCode: string) {
    return this.http.post<SenecaResponse<CloseMyCompetencesQuestionsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/close-my-competences-questions/', {
      perfYear: perfYear
    });
  }

  hasLoggedUserDoneQualificationForCompetences(perfYear: number, perfYearCode: string) {
    return this.http.post<SenecaResponse<HasLoggedUserDoneQualificationForCompetencesResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/has-logged-user-done-qualification-for-competences/', {
      perfYear: perfYear
    });
  }

  // Aggiunge link ai goal operation
  createLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, nameLink: string, link: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-link-for-goal-for-user', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      nameLink: nameLink,
      link: link
    });
  }

  deleteLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, linkId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-link-for-goal-for-user', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId
    });
  }

  updateLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, linkId: string, linkTitle: string, url: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-link-for-goal-for-user', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId,
      nameLink: linkTitle,
      link: url
    });
  }


  uploadFileForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfYear', perfYear.toString());

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-file-for-goal-for-user', formData, options);
    return this.http.request(req);
  }

  updateFileForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, fileTitle: string, uploadObj: any, uploadId: string) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfYear', perfYear.toString());
    formData.append('uploadId', uploadId)

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-file-for-goal', formData, options);
    return this.http.request(req);
  }

  deleteFileForGoal(perfYear: number, perfYearCode: string, userId: string, uploadId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-file-for-goal-for-user', {
      perfYear: perfYear,
      userId: userId,
      uploadId: uploadId
    });
  }

  // Recupera l'url per fare il download del file
  retrieveNewMaskedUrlForUploadForUser(uploadId: string, goalId: string, perfYear: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('goalId', goalId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-new-masked-url-for-upload-for-user/', {
      params: httpParams
    });
  }

  // Crea applauso smart feedback
  createClap(applauseFeedback: PerfCareApplauseFeedback[], userIdsForCreateFeedback: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-applause-feedback-for-user', {
      applauseFeedback: applauseFeedback,
      userIdsForCreateFeedback: userIdsForCreateFeedback
    });
  }
  // Elimina applauso smart feedback
  deleteClap(applauseFeedbackId: string, userId: string, perfYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-applause-feedback-for-user', {
      applauseFeedbackId: applauseFeedbackId,
      userId: userId,
      perfYear: perfYear
    });
  }

  // Aggiorna applauso smart feedback
  updateClap(applauseFeedback: PerfCareApplauseFeedback) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-applause-feedback-for-user', {
      applauseFeedback: applauseFeedback
    });
  }

  // Ritorna applauso dato l'id per smart feedback
  getClapById(perfYear: number, perfYearCode: string, feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackId', feedbackId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-applause-feedback-for-user/', {
      params: httpParams
    });
  }

  // Ritorna la lista di applausi di smart feedback
  getClapList(perfYear: number, userIds: string | string[], received?: boolean, sent?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (userIds && userIds.length && Array.isArray(userIds)) {
      userIds && userIds.forEach(type => {
        httpParams = httpParams.append('userIds', type);
      });
    } else if (userIds && userIds.length && !Array.isArray(userIds)) {
      httpParams = httpParams.append('userIds', userIds);
    }

    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-applause-feedback-for-user/', {
      params: httpParams
    });
  }

  listMyColleagueUsers(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }

  // Recupera il count degli utenti per applausi
  countSendClapUsers(perfYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-collegue-users/', {
      params: httpParams
    });
  }

  // Elimina l'azione di sviluppo
  deleteDevelopmentActionForUser(perfYear: number, perfYearCode: string, developmentActionId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-development-action-for-user', {
      perfYear: perfYear,
      developmentActionId: developmentActionId
    });
  }

  changeDevelopmentActionStatus(perfYear: number, perfYearCode: string, developmentActionId: string, newStatus: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/change-development-action-status-for-user', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      newStatus: newStatus
    });
  }

  // Se può condividere le azioni di sviluppo
  canShareDevelopmentActions(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<CanShareDevelopmentActionToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-share-development-action-to-manager/', {
      params: httpParams
    });
  }

  // Recupera la lista degli utenti per applausi
  listSendClapUsers(fromRecord: number, numRecords: number, perfYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords.toString() || '16');
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }

  uploadAvatar(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-avatar', formData, options);
    return this.http.request(req);
  }

  listMyBadges(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-obtained-badges/', {
      params: httpParams
    });
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociateWithEvidence() {
    return this.http.get<SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-valid-goals-to-associate-for-evidence-feedback-for-user/');
  }

  // Crea l'evidenza
  createEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<CreateEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-evidence-feedback-for-user', {
      evidenceFeedback: feedbackObject
    });
  }

  // Aggiorna l'evidenza
  updateEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<UpdateEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-evidence-feedback-for-user', {
      evidenceFeedback: feedbackObject
    });
  }

  // Lista delle evidenze
  getEvidences(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-evidence-feedback-for-user/', {
      params: httpParams
    });
  }

  // Recupera l'evidenza
  getEvidenceFeedback(perfYear: number, feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackId', feedbackId);
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-evidence-feedback-for-user/', {
      params: httpParams
    });
  }

  // Upload file in una evidenza
  uploadFileForEvidence(perfYear: number, perfYearCode: string, evidenceId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('evidenceId', evidenceId);
    formData.append('perfYear', perfYear.toString());

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-file-for-evidence-feedback-for-user', formData, options);
    return this.http.request(req);
  }

  // Download file nell'evidenza
  retrieveNewMaskedUrlForUploadForEvidence(uploadId: string, evidenceId: string, perfYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('evidenceId', evidenceId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-new-evicende-feedback-masked-url-upload-for-user/', {
      params: httpParams
    });
  }

  // Cancella file nell'evidenza
  deleteFileForEvidence(perfYear: number, perfYearCode: string, evidenceId: string, uploadId: string) {
    return this.http.post<SenecaResponse<DeleteFileForEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-file-for-evidence-feedback-for-user', {
      perfYear: perfYear,
      evidenceId: evidenceId,
      uploadId: uploadId
    });
  }

  // Recupera i counter delle tab del feedback
  getCounterFeedbackTabs(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<CountFeedbacksForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-feedbacks-for-user/', {
      params: httpParams
    });
  }

  getInterviewsForUser(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-user-feedback-dialogue-for-user/', {
      params: httpParams
    });
  }


  getPrivateNotesList(perfYear: number, perfYearCode: string, privateNoteFeedbackId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (privateNoteFeedbackId) {
      httpParams = httpParams.append('privateNoteFeedbackId', privateNoteFeedbackId);
    }
    return this.http.get<SenecaResponse<ListPrivateNoteFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-private-note-feedback-for-subordinate/', {
      params: httpParams
    });
  }

  createPrivateNote(privateNoteFeedback: any[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-private-note-feedback-for-subordinate', {
      privateFeedback: privateNoteFeedback
    });
  }

  deletePrivateNote(perfYear: number, perfYearCode: string, privateFeedbackNoteId: string) {
    return this.http.post<SenecaResponse<DeletePrivateNoteFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-private-note-feedback-for-subordinate', {
      privateFeedbackNoteId: privateFeedbackNoteId,
      perfYear: perfYear,
      perfYearCode: perfYearCode,
    });
  }

  // lista goal selezionabili su cui ricevere feedback
  listGoalForPeerFeedback() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-valid-goals-to-associate-for-peer-feedback-for-user/', {
    });
  }

  createPeerFeedback(userIdsForCreateFeedback: string | string[], peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-peer-feedback-for-user', {
      peerFeedback: peerFeedback,
      userIdsForCreateFeedback: userIdsForCreateFeedback
    });
  }

  listPeerFeedback(perfYear: number, received: boolean, sent: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }
    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-peer-feedback-for-user/', {
      params: httpParams
    });
  }

  // Dati box home page mid term review
  getMidTermReviewBoxData(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-mid-term-review-box-data-for-user/', {
      params: httpParams
    });
  }

  updatePeerFeedback(perfYear: number, peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-peer-feedback-for-user', {
      peerFeedback: peerFeedback,
      perfYear: perfYear
    });
  }

  getPeerFeedbackById(perfYear: number, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-peer-feedback-for-user', {
      params: httpParams
    });
  }
  getMidTermGoals(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-mid-term-review-revision-data-for-user/', {
      params: httpParams
    });
  }

  deletePeerFeedback(perfYear: number, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-peer-feedback-for-user', {
      params: httpParams
    });
  }

  // Recupera il count degli utenti per applausi
  countUserForPeerFeedback(perfYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-collegue-users-for-peer-feedback/', {
      params: httpParams
    });
  }

  // recupera la lista di personde a cui è possibile richiedere feedback
  listUserForPeerFeedback(fromRecord: number, numRecords: number, perfYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords.toString() || '16');
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-collegue-users-for-peer-feedback/', {
      params: httpParams
    });
  }

  setMidTermReviewRevision(perfYear: number, midTermReviewUserObservation: string, midTermReviewUserGoalAdvancement: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-mid-term-review-revision-data-for-user', {
      perfYear: perfYear,
      midTermReviewUserObservation: midTermReviewUserObservation,
      midTermReviewUserGoalAdvancement: midTermReviewUserGoalAdvancement,
    });
  }

  setSelfObservationForMyGoals(perfYear: number, goalId: string, autoObservation: string, autoObservationScore: string) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-self-observation-for-my-goal', {
      perfYear: perfYear,
      goalId: goalId,
      autoObservation: autoObservation,
      autoObservationScore: autoObservationScore
    });
  }


  getMyFinalEvaluation(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-my-final-evaluation', {
      params: httpParams
    });
  }

  setMyFinalEvaluation(perfYear: number, userFinalEvaluation: any) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-my-final-evaluation-self-observation', {
      perfYear: perfYear,
      userFinalEvaluation: userFinalEvaluation
    });
  }

  getFinalEvaluationDate(perfYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-final-evaluation-dates', {
      perfYear: perfYear
    });
  }

  getPerformanceAssessmentYears(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-years', {
      params: httpParams
    });
  }

  getPostCalibrationData(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-post-calibration-data-for-user', {
      params: httpParams
    });
  }

  isProcessClosed(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/is-process-closed-by-year', {
      params: httpParams
    })
  }


  createOrUpdatePerformanceAssessmentGoals(userId: string, goals: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-goals-for-subordinate', {
      userId: userId,
      goals: goals
    });
  }

  deletePerformanceAssessmentGoals(userId: string, goalIdsToDelete: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-performance-assessment-goals-for-subordinate', {
      userId: userId,
      goalIdsToDelete: goalIdsToDelete
    });
  }

  getUserGoalListToEvaluate(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-to-evaluate-for-subordinate', {
      params: httpParams
    })
  }

  getUserGoalList(perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-for-subordinate', {
      params: httpParams
    })
  }

  createOrUpdatePerformanceAssessmentGalEvaluationForManager(userId: string, evaluation: { goalId: string, evaluationText: string }[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-goal-evaluation-for-subordinate', {
      userId: userId,
      evaluation: evaluation
    });
  }

  getUserPhaseDataForSubordinate(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-phase-completion-data-for-subordinate', {
      params: httpParams
    })
  }


  getBehavioursToEvaluateForSubordinate(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<PerfAlloyBehavior[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-behaviors-to-evaluate-for-subordinate', {
      params: httpParams
    })
  }


  createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(userId: string, behaviorEvaluation?: { behaviorId: string, evaluationScore: string } | null, overallComment?: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-behavior-evaluation-for-subordinate', {
      userId: userId,
      behaviorEvaluation: behaviorEvaluation,
      overallComment: overallComment
    });
  }


  getCompetenceToEvaluateForSubordinate(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<PerfAlloyBehavior[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-competences-to-evaluate-for-subordinate', {
      params: httpParams
    })
  }


  createOrUpdatePerformanceAssessmentCompetenceEvaluationForSubordinate(userId: string, competenceEvaluation: { competenceId: string, evaluationScore: number }[] | null, overallComment?: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-competence-evaluation-for-subordinate', {
      userId: userId,
      competenceEvaluation: competenceEvaluation,
      overallComment: overallComment
    });
  }

  getPerformanceAssessmentFinalEvaluationDataForSubordinate(perfYear: string, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-final-evaluation-data-for-subordinate', {
      params: httpParams
    })
  }

  createOrUpdatePerformanceAssessmentFinalEvaluationCommentForSubordinate(userId: string, finalEvaluationComment: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-final-evaluation-comment-for-subordinate', {
      userId: userId,
      finalEvaluationComment: finalEvaluationComment
    });
  }

  sendFinalEvaluationFormForSubordinate() {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/send-final-evaluation-form-for-subordinate', {});
  }
}

export interface CheckExchangeAgendaForFreeSlotsResponse {
  start?: Date;
  end?: Date;
  startLabel?: string;
  startTimeLabel?: string;
  endTimeLabel?: string;
  persons: number;
  percentage: number;
  unavailablePersonList: Array<string>;
}