import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
  selector: 'app-admin-create-update-process',
  templateUrl: './createUpdateProcess.component.html',
  styleUrls: ['./createUpdateProcess.component.scss']
})
export class CreateUpdateProcessComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  processId: string = '';
  isLoadingProcess: boolean = true;

  processName: string = '';
  processNameCopy: string = '';

  yearOptions: any;
  selectedYear: any;
  selectedYearCopy: any;

  statusOptions: any;
  selectedStatus: any;
  selectedStatusCopy: any;

  notes: string = '';
  notesCopy: string = '';
  getProcessData$: Subscription = new Subscription;



  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.processId = params.processId;
              if (!this.processId) {
                this.isLoadingProcess = false;
              } else {
                this.getProcessData();
              }
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'process.status.ACTIVE',
        'process.status.STANDBY',
        'process.status.CLOSED'
      ])
      .subscribe(translations => {

        this.yearOptions = [];
        let year = 2022;
        for (let i = 1; i < 10; i++) {

          for (let j = 0; j < 2; j++) {
            this.yearOptions.push({
              id: year + i,
              title: (year + i).toString() + (j == 0 ? ' H1' : ' H2'),
              year: year + i,
              code: j == 0 ? 'H1' : 'H2'
            })
          }
        }

        this.statusOptions = [
          {
            id: 'RUNNING',
            title: translations['process.status.ACTIVE']
          },
          {
            id: 'STANDBY',
            title: translations['process.status.STANDBY']
          },
          {
            id: 'HISTORY',
            title: translations['process.status.CLOSED']
          }
        ];
        if (!this.processId) {
          this.selectedStatus = this.statusOptions[1];
        }
      })
  }

  nameChanged(text: string) {
    this.processName = text;
  }


  changeYear(yearItem: any) {
    this.selectedYear = yearItem;
  }

  changeStatus(statusItem: any) {
    this.selectedYear = statusItem;
  }

  noteChanged(text: string) {
    this.notes = text;
  }

  getProcessData() {
    this.isLoadingProcess = true;

    if (this.getProcessData$) {
      this.getProcessData$.unsubscribe();
    }

    this.getProcessData$ = this.adminService.getProcess(this.processId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else {
          this.processName = data.response.processTitle;
          let year = data.response.processYear;
          let code = data.response.processYearCode;
          for (let i = 0; i < this.yearOptions.length; i++) {
            if (this.yearOptions[i].year == year && this.yearOptions[i].code == code) {
              this.selectedYear = this.yearOptions[i];
              break;
            }
          }

          this.selectedStatus = this.statusOptions.find((status: any) => status.id == data.response.processStatus);
          this.notes = data.response.processNotes;

          // this.processNameCopy = data.response.processTitle;
          // this.selectedYearCopy = JSON.parse(JSON.stringify(this.selectedYear));
          // this.selectedStatusCopy = JSON.parse(JSON.stringify(this.selectedStatus));
        }
        this.isLoadingProcess = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingProcess = false;
      });
  }


  // Gestisce quando crei/aggiorni un'evidenza
  goToUpdateProcess(isBack?: boolean) {
    this.openUpdateModal();
  }

  openUpdateModal() {
    this.modalService.open('processUpdateModal');
  }

  closeUpdateModal() {
    this.modalService.close('processUpdateModal');
  }

  createUpdate() {
    if (this.processId) {
      this.modalService.open('update-process');
    } else {
      this.modalService.open('create-process');
    }
  }


  closeCreateProcess(confirm?: boolean) {
    this.modalService.close('create-process');
    if (confirm) {
      this.isLoadingProcess = true;
      this.adminService.createProcess(this.selectedYear.year, this.selectedYear.code, this.processName, this.notes, this.selectedStatus.id)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.redirectService.goBackBrowser();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  closeUpdateProcess(confirm?: boolean) {
    this.modalService.close('create-process');
    if (confirm) {
      this.isLoadingProcess = true;
      this.adminService.updateProcess(this.selectedYear.year, this.selectedYear.code, this.processId, this.processName, this.notes, this.selectedStatus.id)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.redirectService.goBackBrowser();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  onCancel() {
    this.redirectService.goBackBrowser();
  }


  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}