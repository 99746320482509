import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { DropdownItem } from '../../models/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from '../../services/redirect.service';

@Component({
  selector: 'header-dossier',
  templateUrl: 'header-dossier.component.html',
  styleUrls: ['./header-dossier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDossierComponent implements OnInit {
  @Input() interview?: any;
  @Input() user: any = null;
  @Input() counter?: number;
  @Input() counterLabel?: string;
  @Input() selectedYear?: any;
  @Input() yearItems: any[] = [
    // {
    //   id: 2022,
    //   name: 'Anno 2022'
    // },
    // {
    //   id: 2021,
    //   name: 'Anno 2021'
    // }
  ];
  @Input() attrAriaLabel: string = '';
  @Input() menuButtons: any[] = [];
  @Input() hasTabs: boolean = true;
  @Input() isAdminHeader: boolean = false;
  @Input() isDashboard: boolean = false;
  @Input() isChief: boolean = false;
  @Input() adminActionLabel: string = '';
  @Input() adminActionIcon: string = '';
  @Input() adminPageTitle: string = '';
  @Input() adminPageSubtitle: string = '';
  @Input() adminBack: boolean = false;
  @Input() adminActionDisabled: boolean = false;
  @Input() adminActionLabelType: string = 'primary';
  @Input() showLoader: boolean = false;
  // Lista tab custom
  @Input() customTabList: boolean = false;
  @Input() hideAction: boolean = false;
  @Input() currentMenuButton: any;

  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onNewInterview: EventEmitter<any> = new EventEmitter();
  @Output() onAnnullInterview: EventEmitter<any> = new EventEmitter();
  @Output() onMenuButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() onAdminAction: EventEmitter<any> = new EventEmitter();
  @Output() onSelectedYear: EventEmitter<any> = new EventEmitter();

  selectedMenuButton: any;
  noDataObject: DropdownItem = {
    id: '',
    name: '',
    icon: ''
  };
  mobileMenuOpened: boolean = false;
  mobileMenuItems: any[] = [];

  constructor(
    public translate: TranslateService,
    public redirectService: RedirectService
  ) { }

  ngOnInit() {
    this.translate.get(
      [
        'generic.NO_DATA_FOUND',
        'admin.filters.EQUAL',
        'admin.SCHEDULE',
        'headerDossier.CARD',
        'headerDossier.PAS',
        'headerDossier.MY_NOTES'
      ]).subscribe(translations => {
        this.noDataObject = {
          id: 'none',
          name: translations['generic.NO_DATA_FOUND'],
          icon: '/assets/img/icons/coffee.svg'
        }
        if (!this.customTabList && (!this.menuButtons || !this.menuButtons.length)) {

          if (window.location.href.includes('/selfAssessment/')) {
            this.menuButtons = [
              {
                id: 'pas',
                name: translations['headerDossier.PAS'] + this.selectedYear?.name
              },
              {
                id: 'notes',
                name: translations['headerDossier.MY_NOTES']
              }
            ];
          } else {
            this.menuButtons = [
              {
                id: 'pas',
                name: translations['headerDossier.CARD'] + this.selectedYear?.name
              },
              {
                id: 'notes',
                name: translations['headerDossier.MY_NOTES']
              }
            ];
          }

          if (window.location.href.includes('/myNotes/')) {
            this.selectedMenuButton = this.menuButtons[1]
          } else {
            this.selectedMenuButton = this.menuButtons[0];
          }
        } else {
          this.selectedMenuButton = this.currentMenuButton;
        }
      });
  }

  ngDoCheck() {
    if (window.location.href.includes('/personDetails/')) {
      if (window.location.href.includes('/myNotes/')) {
        this.selectedMenuButton = this.menuButtons[1]
      } else {
        this.selectedMenuButton = this.menuButtons[0];
      }
    }
  }

  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  emitOnSelectedYearItem(item: any) {
    this.selectedYear = item;
    this.onSelectedYear.emit(item);
  }

  emitOnOptionClicked(item: any) {
    this.mobileMenuOpened = !this.mobileMenuOpened;
    item.badgeNumber = 0;
    this.selectedMenuButton = item;
    this.mobileMenuItems = this.menuButtons.filter((x: any) => {
      return x.id != item.id;
    })
    this.onMenuButtonClicked.emit(item.id);
  }

  emitOnOrganizeinterview() {
    this.onNewInterview.emit();
  }

  emitOnAnnullInterview(feedbackDialogueId: string) {
    this.onAnnullInterview.emit(feedbackDialogueId);
  }

  // Quando premi nel mobile un pulsante del menu bottom
  menuButtonClicked(selectedItem: any) {
    if (window.screen.width <= 600 || document.documentElement.clientWidth <= 600) {
      this.mobileMenuOpened = !this.mobileMenuOpened;
    }
    selectedItem.badgeNumber = 0;
    this.selectedMenuButton = selectedItem;
    this.mobileMenuItems = this.menuButtons.filter((x: any) => {
      return x.id != selectedItem.id;
    })
    this.onMenuButtonClicked.emit(selectedItem.id);
  }

  emitOnAdminAction() {
    this.onAdminAction.emit();
  }
}