<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="isPeopleAppraisal ? ('process.phases.SIDENAV_TITLE_PEOPLE' | translate) : ('process.phases.SIDENAV_TITLE_SELF' | translate)"
            [description]="'process.phases.SIDENAV_DESCR' | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingPhaseData">
                <div class="form-container">
                    <!-- data inizio -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="startDate" [isObbligatory]="true"
                        title="{{'process.phases.START_DATE' | translate}}">
                        <alloy-datepicker [minDate]="today" [value]="startDate" (dateChanged)="startDateChanged($event)"
                            placeholder="{{'process.phases.INSERT_START_DATE' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- data fine -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="endDate" [isObbligatory]="true"
                        title="{{'process.phases.END_DATE' | translate}}">
                        <alloy-datepicker [minDate]="startDate" [value]="endDate" (dateChanged)="endDateChanged($event)"
                            placeholder="{{'process.phases.INSERT_END_DATE' | translate}}">
                        </alloy-datepicker>
                    </input-container>

                    <div class="action-container">
                        <alloy-button [label]="'generic.CANCEL' | translate" type="success bordless"
                            (onButtonClicked)="onCancel()"></alloy-button>
                        <alloy-button (onButtonClicked)="onConfirm()" [disabled]="isLoadingPhaseData"
                            type="success default" size="large" [label]="'process.phases.actions.EDIT' | translate">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingPhaseData">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="confirm-edit" (onClose)="closeConfirm()" [confirmLabel]="'generic.CONFIRM' | translate"
    [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeConfirm(true)" (onCancel)="closeConfirm()">
    <modal-text-content [modalId]="'ed001'" [title]="'process.phases.modals.CONFIRM_TITLE' | translate"
        [text]="'process.phases.modals.CONFIRM_DESCR' | translate">
    </modal-text-content>
</modal>