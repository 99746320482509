<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="(processId ? 'process.create.SIDENAV_TITLE_UPDATE' : 'process.create.SIDENAV_TITLE_CREATE') | translate"
            [description]="(processId ? 'process.create.SIDENAV_DESCR_UPDATE' : 'process.create.SIDENAV_DESCR_CREATE') | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingProcess">
                <div class="form-container">
                    <!-- Nome del processo -->
                    <input-container [isObbligatory]="true" [isValued]="processName && processName.trim().length"
                        [isObbligatory]="true" title="{{'process.create.NAME' | translate}}">
                        <alloy-input [bindValue]="processName" (onModelChanged)="nameChanged($event)"
                            placeholder="{{'process.create.NAME_PLACEHOLDER' | translate}}">
                        </alloy-input>
                    </input-container>
                    <!-- Anno di riferimento -->
                    <input-container [isObbligatory]="true" [isValued]="selectedYear && selectedYear.id"
                        [isObbligatory]="true" title="{{'process.create.YEAR' | translate}}">
                        <alloy-select [placeholder]="'process.create.YEAR_PLACEHOLDER' | translate"
                            (onSelectedItem)="changeYear($event)" [selectedItem]="selectedYear" [items]="yearOptions"
                            bindLabel="title"></alloy-select>
                    </input-container>
                    <!-- Stato del processo -->
                    <input-container [isObbligatory]="true" [readonly]="!processId" [isValued]="selectedStatus"
                        [isObbligatory]="true" title="{{'process.create.STATUS' | translate}}">
                        <alloy-select (onSelectedItem)="changeStatus($event)" [selectedItem]="selectedStatus"
                            [items]="statusOptions" bindLabel="title"></alloy-select>
                    </input-container>
                    <!-- Note -->
                    <input-container [isObbligatory]="false" [isValued]="notes && notes.trim().length"
                        title="{{'process.create.NOTE' | translate}}">
                        <alloy-text-area [bindValue]="notes" (onModelChanged)="noteChanged($event)"
                            placeholder="{{'process.create.NOTE_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                    </input-container>


                    <div class="action-container">
                        <alloy-button [label]="'generic.CANCEL' | translate" type="success bordless"
                            (onButtonClicked)="onCancel()"></alloy-button>
                        <alloy-button (onButtonClicked)="createUpdate()" [disabled]="isLoadingProcess"
                            type="success default" size="large"
                            [label]="(processId && processId.length) ? ('process.create.UPDATE' | translate) : ('process.create.CREATE' | translate)">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingProcess">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="create-process" (onClose)="closeCreateProcess()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeCreateProcess(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeCreateProcess()">
    <modal-text-content [title]="'process.create.CREATE' | translate"
        [text]="'process.create.CREATE_MODAL_DESCR' | translate"></modal-text-content>
</modal>

<modal id="update-process" (onClose)="closeUpdateProcess()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeUpdateProcess(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeUpdateProcess()">
    <modal-text-content [title]="'process.create.UPDATE' | translate"
        [text]="'process.create.UPDATE_MODAL_DESCR' | translate"></modal-text-content>
</modal>