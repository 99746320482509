import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
@Component({
    selector: 'app-admin-verify-send-schedule',
    templateUrl: './verifySendSchedule.component.html',
    styleUrls: ['./verifySendSchedule.component.scss']
})
export class VerifySendScheduleComponent implements OnInit, OnDestroy {
    processYear: number = 0;
    processYearCode: string = '';
    userId: string = '';

    getUserData$: Subscription = new Subscription();
    isLoadingUserData: boolean = false;
    evaluatedGoalList: any;
    goalEvaluationModalData: { goal: any; evaluation: any; } | any = {};
    peopleAppraisalData: any;
    selfAssessmentData: any;
    behaviorData!: { managerValue: any; managerRating: number; managerNotes: any; subordinateValue: any; subordinateRating: number; subordinateNotes: any; };
    competenceData!: { managerValue: any; managerRating: number; managerNotes: any; subordinateValue: any; subordinateRating: number; subordinateNotes: any; };
    behaviorCompetenceComment: any;
    developmentObjectives: any[] = [];
    managerFinalEvaluationComment: any;
    subordinateFinalEvaluationComment: any;
    managerPas: any;
    subordinatePas: any;
    hrComment: string = '';

    starsModel: any = {
        5: [
            {
                id: 0,
                isClicked: false
            },
            {
                id: 1,
                isClicked: false
            },
            {
                id: 2,
                isClicked: false
            },
            {
                id: 3,
                isClicked: false
            },
            {
                id: 4,
                isClicked: false
            }
        ],
        3: [
            {
                id: 0,
                isClicked: false
            },
            {
                id: 1,
                isClicked: false
            },
            {
                id: 2,
                isClicked: false
            }
        ]
    }
    behaviorDetailsList: any;
    competenceDetailsList: any;
    isCompetenceDetails: boolean = false;
    modalDetailList: any;
    saveSchedule$: Subscription = new Subscription;

    constructor(
        private adminService: AdminService,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private store: Store<fromApp.AppState>,
        public redirectService: RedirectService,
        private modalService: ModalService
    ) {
        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
        // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Monitoraggio - Verifica e invia scheda");

        this.route.params.subscribe((params: Params) => {
            this.processYear = params.processYear;
            this.processYearCode = params.processYearCode;
            this.userId = params.userId;
            this.getUserData();
        })
    }


    ngOnInit(): void {
    }

    getUserData() {
        this.isLoadingUserData = true;
        if (this.getUserData$) {
            this.getUserData$.unsubscribe();
        }
        this.getUserData$ = this.adminService.getFinalEvaluationFormDataForAdmin(this.processYear, this.processYearCode, this.userId)
            .subscribe((data: SenecaResponse<any>) => {
                if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "109",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                } else {
                    // dati utenti
                    this.peopleAppraisalData = data.response.managerUser;
                    this.selfAssessmentData = data.response.subordinateUser;
                    // dati valutazione obiettivi
                    this.evaluatedGoalList = data.response.evaluatedGoals;
                    // valutazione comportamenti
                    (Math.round(data.response.behavior.averageManagerBehaviorEvaluation * 100) / 100).toFixed(2);
                    this.behaviorData = {
                        managerValue: (Math.round(data.response?.behavior?.averageManagerBehaviorEvaluation * 100) / 100).toFixed(2) || 0,
                        managerRating: Math.round(data.response?.behavior?.averageManagerBehaviorEvaluation) || 0,
                        managerNotes: data.response?.behavior?.managerBehaviorEvaluation || this.translate.instant('generic.NO_COMMENT_INSERTED'),
                        subordinateValue: (Math.round(data.response?.behavior?.averageSubordinateBehaviorEvaluation * 100) / 100).toFixed(2) || 0,
                        subordinateRating: Math.round(data.response?.behavior?.averageSubordinateBehaviorEvaluation) || 0,
                        subordinateNotes: data.response?.behavior?.subordinateBehaviorEvaluation || this.translate.instant('generic.NO_COMMENT_INSERTED')
                    }

                    if (data.response?.behavior?.behaviorTagsEvaluations?.length) {
                        this.behaviorDetailsList = [];
                        for (let i = 0; i < data.response.behavior.behaviorTagsEvaluations.length; i++) {
                            this.behaviorDetailsList.push({
                                ...data.response.behavior.behaviorTagsEvaluations[i],
                                rating: this.getBehaviourRating(data.response.behavior.behaviorTagsEvaluations[i].managerEvaluation, 5),
                                selfRating: this.getBehaviourRating(data.response.behavior.behaviorTagsEvaluations[i].subordinateEvaluation, 5)
                            })
                        }
                    }
                    // valutazione competenze tecniche
                    this.competenceData = {
                        managerValue: (Math.round(data.response?.competence?.averageManagerCompetenceEvaluation * 100) / 100).toFixed(2) || 0,
                        managerRating: Math.round(data.response?.competence?.averageManagerCompetenceEvaluation) || 0,
                        managerNotes: data.response?.competence?.managerCompetenceEvaluation || this.translate.instant('generic.NO_COMMENT_INSERTED'),
                        subordinateValue: (Math.round(data.response?.competence?.averageSubordinateCompetenceEvaluation * 100) / 100).toFixed(2) || 0,
                        subordinateRating: Math.round(data.response?.competence?.averageSubordinateCompetenceEvaluation) || 0,
                        subordinateNotes: data.response?.competence?.subordinateCompetenceEvaluation || this.translate.instant('generic.NO_COMMENT_INSERTED')
                    }
                    if (data.response?.competence?.competenceTagsEvaluations?.length) {
                        this.competenceDetailsList = [];
                        for (let i = 0; i < data.response.competence.competenceTagsEvaluations.length; i++) {
                            this.competenceDetailsList.push({
                                ...data.response.competence.competenceTagsEvaluations[i],
                                rating: this.getBehaviourRating(data.response.competence.competenceTagsEvaluations[i].managerEvaluation, 5),
                                selfRating: this.getBehaviourRating(data.response.competence.competenceTagsEvaluations[i].subordinateEvaluation, 5)
                            })
                        }
                    }
                    // obiettivi di sviluppo
                    this.developmentObjectives = [];
                    if (data.response.proposedGoals && data.response.proposedGoals.managerProposedGoals && data.response.proposedGoals.managerProposedGoals.length) {
                        for (let i = 0; i < data.response.proposedGoals.managerProposedGoals.length; i++) {
                            this.developmentObjectives.push(data.response.proposedGoals.managerProposedGoals[i]);
                        }
                    }
                    if (data.response.proposedGoals && data.response.proposedGoals.subordinateProposedGoals && data.response.proposedGoals.subordinateProposedGoals.length) {
                        for (let i = 0; i < data.response.proposedGoals.subordinateProposedGoals.length; i++) {
                            this.developmentObjectives.push(data.response.proposedGoals.subordinateProposedGoals[i]);
                        }
                    }
                    // commento finale
                    this.managerFinalEvaluationComment = data.response.managerFinalEvaluationComment || this.translate.instant('generic.NO_COMMENT_INSERTED');
                    this.subordinateFinalEvaluationComment = data.response.subordinateFinalEvaluationComment || this.translate.instant('generic.NO_COMMENT_INSERTED');

                    // Profilo pas manager
                    let managerProfile = data.response?.managerPasProfileData?.pasProfile.split('_')[0];
                    this.managerPas = {};
                    this.managerPas.title = this.translate.instant('performerEvaluators.' + managerProfile + '.title');
                    this.managerPas.description = this.translate.instant('performerEvaluators.' + managerProfile + '.description');
                    this.managerPas.value = (Math.round(data.response?.managerPasProfileData?.pasAvgEvaluation * 100) / 100).toFixed(2) || 0;
                    this.managerPas.rating = Math.round(data.response?.managerPasProfileData?.pasAvgEvaluation) || 0;
                    // Profilo pas subordinato
                    let subordinateProfile = data.response?.subordinatePasProfileData?.pasProfile.split('_')[0];
                    this.subordinatePas = {};
                    this.subordinatePas.title = this.translate.instant('performerEvaluators.' + subordinateProfile + '.title');
                    this.subordinatePas.description = this.translate.instant('performerEvaluators.' + subordinateProfile + '.description');
                    this.subordinatePas.value = (Math.round(data.response?.subordinatePasProfileData?.pasAvgEvaluation * 100) / 100).toFixed(2) || 0;
                    this.subordinatePas.rating = Math.round(data.response?.subordinatePasProfileData?.pasAvgEvaluation) || 0;
                    // Commento finale hr
                    this.hrComment = data.response?.hrPostCalibrationComment || '';
                }
                this.isLoadingUserData = false;
            }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                    modalId: "109",
                    text: this.translate.instant("errors." + ((err && err.message) || err)),
                    title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingUserData = false;
            });
    }

    // Modale valutazione obiettivo
    readGoalEvaluation(goal: any, type: string) {
        this.goalEvaluationModalData = {
            goal: goal,
            evaluation: this.translate.instant('verifySend.NO_EVALUATION'),
            user: null
        }
        if (type == 'manager') {
            this.goalEvaluationModalData.evaluation = goal.managerEvaluation;
            this.goalEvaluationModalData.user = this.peopleAppraisalData;

        } else if (type == 'subordinate') {
            this.goalEvaluationModalData.evaluation = goal.subordinateEvaluation;
            this.goalEvaluationModalData.user = this.selfAssessmentData;
        }
        this.modalService.open('goal-evaluation');
    }

    closeReadGoalEvaluation() {
        this.modalService.close('goal-evaluation');
        this.goalEvaluationModalData = {};
    }

    // inizializza il rating dei comportamenti 
    getBehaviourRating(rating: number, ratingScale: number) {
        let starRating = JSON.parse(JSON.stringify(this.starsModel[ratingScale]));
        for (let i = 0; i < rating; i++) {
            starRating[i].isClicked = true;
        }
        return starRating;
    }

    // dettaglio comportamenti e competenze
    openBehaviorCompetenceDetailsModal(isCompetence: boolean) {
        this.isCompetenceDetails = isCompetence;
        if (isCompetence) {
            this.modalDetailList = this.competenceDetailsList;
        } else {
            this.modalDetailList = this.behaviorDetailsList;
        }
        this.modalService.open('behavior-competence-details-modal');
    }

    closeBehaviorCompetenceDetailsModal() {
        this.modalService.close('behavior-competence-details-modal');
    }

    // dettaglio comportamenti e competenze
    openBehaviorCompetenceCommentsModal(userType: string, commentType: string) {
        this.behaviorCompetenceComment = {};
        if (userType == 'manager') {
            this.behaviorCompetenceComment.user = this.peopleAppraisalData.forename;
            if (commentType == 'behavior') {
                this.behaviorCompetenceComment.text = this.behaviorData.managerNotes;
            } else {
                this.behaviorCompetenceComment.text = this.competenceData.managerNotes;
            }
        } else {
            this.behaviorCompetenceComment.user = this.selfAssessmentData.forename;
            if (commentType == 'behavior') {
                this.behaviorCompetenceComment.text = this.behaviorData.subordinateNotes;
            } else {
                this.behaviorCompetenceComment.text = this.competenceData.subordinateNotes;
            }
        }
        this.modalService.open('behavior-competence-comments-modal');
    }

    closeBehaviorCompetenceCommentsModal() {
        this.modalService.close('behavior-competence-comments-modal');
    }

    hrCommentChanged(text: string) {
        this.hrComment = text;
    }

    // salva scheda
    openSaveScheduleModal() {
        this.modalService.open('save-schedule-modal');
    }

    openSendScheduleModal() {
        this.modalService.open('send-schedule-modal');
    }

    closeSaveScheduleModal(confirm?: boolean) {
        this.modalService.close('save-schedule-modal');
        if (confirm) {
            if (this.saveSchedule$) {
                this.saveSchedule$.unsubscribe();
            }
            this.saveSchedule$ = this.adminService.savePostCalibrationFormForAdmin([this.userId], this.hrComment, false)
                .subscribe((data: any) => {
                    if (data && data.error) {
                        const messageObj: ApplicationModalMessage = {
                            modalId: "save001",
                            text: this.translate.instant("errors." + data.error),
                            title: this.translate.instant("generic.WARNING")
                        }
                        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                        this.redirectService.goBackBrowser();
                    } else {
                        // toaster 
                        let x: any = document.getElementById("snackbar");
                        if (x) {
                            x.className = "show";
                            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
                        }
                        this.redirectService.goBackBrowser();
                    }
                }, (err?: any) => {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "save002",
                        text: this.translate.instant("errors." + err?.message),
                        title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    this.redirectService.goBackBrowser();
                });
        }
    }

    closeSendScheduleModal(confirm?: boolean) {
        this.modalService.close('send-schedule-modal');
        if (confirm) {
            if (confirm) {
                if (this.saveSchedule$) {
                    this.saveSchedule$.unsubscribe();
                }
                this.saveSchedule$ = this.adminService.savePostCalibrationFormForAdmin([this.userId], this.hrComment, true)
                    .subscribe((data: any) => {
                        if (data && data.error) {
                            const messageObj: ApplicationModalMessage = {
                                modalId: "save001",
                                text: this.translate.instant("errors." + data.error),
                                title: this.translate.instant("generic.WARNING")
                            }
                            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                            this.redirectService.goBackBrowser();
                        } else {
                            // toaster 
                            let x: any = document.getElementById("snackbar");
                            if (x) {
                                x.className = "show";
                                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
                            }
                            this.redirectService.goBackBrowser();
                        }
                    }, (err?: any) => {
                        const messageObj: ApplicationModalMessage = {
                            modalId: "save002",
                            text: this.translate.instant("errors." + err?.message),
                            title: this.translate.instant("generic.WARNING")
                        }
                        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                        this.redirectService.goBackBrowser();
                    });
            }
        }
    }

    ngOnDestroy() {
        if (this.getUserData$) {
            this.getUserData$.unsubscribe();
        }
        if (this.saveSchedule$) {
            this.saveSchedule$.unsubscribe();
        }
    }
}
